import d3 = require('d3');
import dimple = require('dimple');

import { BaseChart } from './basechart';
import { IDataRecord } from './basechart';
import { Axis } from './basechart';
import { IKeyValueProps } from './basechart';
import { IGraphData } from './models/graphdata';
import { IGraphModel } from './models/graphmodel';
import { Utils } from './utils';

export class PieChart extends BaseChart {

  protected pieAxis: any = null;
  protected hoveredElement: any = null;
  protected totalAmountValues = 0.0;
  protected dimensions = {x: 475, y: 400};

  public constructor(element: Element) {
    super(element);
  }

  public drawScene(graphModel: IGraphModel, data: IGraphData) {

    super.drawScene(graphModel, data);
    const instance = this;

    const category = this.fieldNames[0];
    const value = this.fieldNames[1];

    // calculate the total amount of all values so we can display the
    // percentage in the popup
    instance.totalAmountValues = 0;
    this.seriesData.forEach((d) => {
      instance.totalAmountValues += parseFloat(d[value]);
    });

    this.pieAxis = this.chart.addMeasureAxis('p', value);
    let series = this.chart.addSeries(category, dimple.plot.pie);

    series.addOrderRule(category, false);

    if (this.props['pie-chart-inner-radius']) {
      series.innerRadius = this.props['pie-chart-inner-radius'];
    }

    // Handle the hover event - overriding the default behaviour
    series.addEventHandler('mouseover', (e) => { instance.showPopup(e); });
    // Handle the leave event - overriding the default behaviour
    series.addEventHandler('mouseleave', (e) => { instance.removePopup(e); });

    this.chart.draw();
    return this.chart;
  }

  protected loadProperties(props: IKeyValueProps[]) {
    this.props['aspect-ratio'] = '1.1875';
    this.props['legend-align'] = 'left';
    this.props['margin-bottom'] = '0';
    this.props['margin-left'] = '0';
    this.props['margin-right'] = '0';
    this.props['margin-top'] = '20%';
    this.props['legend-x'] = '20';
    super.loadProperties(props);
  }

  protected showPopup(e) {
    /* Event to handle mouse enter
    *  TODO: this method still has too many magic hardcoded settings
    *  which should be removed or made configurable
    */

    this.hoveredElement = e.selectedShape;
    this.hoveredElement.classed('hovered', true);

    const cx = d3.mouse(this.svg.node())[0];
    const cy = d3.mouse(this.svg.node())[1];

    let chartX = this.chart.svg.node().getBBox().x;
    let chartY = this.chart.svg.node().getBBox().y;
    let chartWidth = this.chart.svg.node().getBBox().width;
    let chartHeight = this.chart.svg.node().getBBox().height;

    // Set the (initial) size and position of the popup
    let width = 150;
    const height = 45;

    const leftMargin = 20;
    let x = cx + leftMargin;
    let y = cy + height < chartY + chartHeight ?
       cy : cy - height;

    // Create a group for the popup objects
    this.popup = this.svg.append('g');

    // Add a rectangle surrounding the text
    const rect = this.popup
    .append('rect')
    .attr('x', x + 5)
    .attr('y', y - 5)
    .attr('width', width)
    .attr('height', height)
    .attr('rx', 5)
    .attr('ry', 5)
    .style('z-index', '1')
    .style('fill', 'white')
    .style('stroke', 'black')
    .style('stroke-width', 2);

    // Add multiple lines of text
    const text = this.popup
    .append('text')
    .attr('class', 'popupText');

    const pctFormatter = d3.format('.0%');
    const valueFormatter = d3.format(',');
    let pct = (e.pValue / this.totalAmountValues);

    text
    .append('tspan').attr('x', x + 10).attr('y', y + 15)
    .text(e.seriesValue[0])
    .style('font-family', 'sans-serif').style('font-size', 10).style('font-weight', 'bold')
    .append('tspan').attr('x', x + 10).attr('y', y + 30)
    .text(`${valueFormatter(e.pValue)} (${pctFormatter(pct)})`)
    .style('font-family', 'sans-serif').style('font-size', 10).style('font-weight', 'normal');

    // maak box breedte passend aan text
    const textBBox = text.node().getBBox();
    width = textBBox.width + 10;
    rect.attr('width', width);

    // make sure the popup fits in chart
    x = cx + leftMargin + width < chartX + chartWidth ?
       cx + leftMargin : cx - leftMargin - width;

    rect.attr('x', x);
    rect.attr('y', y);

    d3.selectAll('.popupText tspan')
    .attr('x', x + 5);
  }

  // Event to handle mouse exit
  protected removePopup(e) {
    // Remove the popup
    if (this.popup !== null) {
      this.popup.remove();
    }
    if (this.hoveredElement) {
      this.hoveredElement.classed('hovered', false);
    }
  }

}
