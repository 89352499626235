import { Utils } from './utils';

export class IndicatorInfo {
  private indicatorData;
  private indicatorId: number;

  public constructor(titleSelectorId: string, bodySelectorId: string, indicatorId: number) {
    const infoNode = document.getElementById(bodySelectorId);
    const titleNode = document.getElementById(titleSelectorId);

    this.indicatorId = indicatorId;

    const indicatorPromise = Utils.Instance.getIndicator(indicatorId);

    Promise.resolve(indicatorPromise).then(data => {
      infoNode.innerHTML = '';
      this.indicatorData = data;

      let title = this.indicatorData.naam;
      let description = this.indicatorData.omschrijving;
      let source = this.indicatorData.bron;

      // add source to chart
      const sourceNode = document.createElement('small');
      sourceNode.innerText = `Bron: ${source}`;
      infoNode.insertBefore(sourceNode, infoNode.firstChild);

      // add description to chart
      const descriptionNode = document.createElement('div');
      descriptionNode.innerText = description;
      infoNode.insertBefore(descriptionNode, infoNode.firstChild);

      // add title
      title = title.replace(/\|/g, ' ');
      titleNode.innerHTML = title;
    });
  }
}
