import { BehaviorSubject } from 'rxjs/BehaviorSubject';
let d3 = require('d3');
import { Utils } from './utils';

export class WijkContent {

  protected curSelectedWijkId: string = null;
  protected kompassenData: any = null;

  public constructor() {
    this.loadWijkKompassenData();
  }

  public initWijkContent() {

    Utils.Instance.inMemoryData$.subscribe((data) => {
      let newSelectedWijkId: string = data['selectedWijkId'];
      if (newSelectedWijkId) {
        if (newSelectedWijkId !== this.curSelectedWijkId) {
          this.curSelectedWijkId = newSelectedWijkId;
          this.showSelectedWijk(newSelectedWijkId);
        }
      }
    });
  }

  protected loadWijkKompassenData() {
    Utils.Instance.getKompassenData().then((kompassenData) => {
      this.kompassenData = kompassenData;
      if (this.curSelectedWijkId) {
        this.showSelectedWijk(this.curSelectedWijkId);
      }
    });
  }

  protected showSelectedWijk(wijkId: string) {
    /*
     * Insert all available 'wijk' info
     */

    let kompasData = null;
    if (this.kompassenData ) {
      for (let item of this.kompassenData.items) {
        if (String(item.wijk.id) === wijkId) {
          kompasData = item;
        }
      }
    }

    let title = document.getElementById('wijk_omschrijving_title');
    if (title) {
      if (kompasData) {
        title.innerHTML = kompasData.title;
        title.parentElement.classList.remove('hidden');
      } else {
        title.innerHTML = '';
        title.parentElement.classList.add('hidden');
      }
    }

    let omschr = document.getElementsByClassName('wijk_omschrijving');
    for (let i = 0; i< omschr.length; i++) {
      if (kompasData ) {
        omschr[i].innerHTML = kompasData.omschrijving;
      } else {
        omschr[i].innerHTML = '';
      }
    }

    let stand = document.getElementsByClassName('wijk_stand');
    for (let i = 0; i< stand.length; i++) {
      if (kompasData) {
        stand[i].innerHTML = kompasData.stand;
      } else {
        stand[i].innerHTML = '';
      }
    }

    let ontw = document.getElementById('wijk_ontwikkeling');
    if (ontw) {
      ontw.innerHTML = kompasData ? kompasData.ontwikkeling : '';
    }

    let ontwst = document.getElementById('wijk_ontwikkeling_tov_stad');
    if (ontwst) {
      ontwst.innerHTML = kompasData ? kompasData.ontwikkeling_tov_stad : '';
    }
  }
}
