<template>
  <!-- PAGE CONTENT: KOMPAS -->
  <div class="block block-kompas" id="block-kompas">

    <kruimelpad-navigatie v-if="selectedWijk" id="vergelijk-kruimelpadnav" :wijk="selectedWijk"
      :variant="selectedVariant">
      <div class="breadcrumbs"></div>
    </kruimelpad-navigatie>

    <!-- KOMPAS FILTER VIEW-MODE (KOMPAS VIEW MODE) -->
    <div id="kompas-mode" class="block block-filters-viewmode" v-if="showVisual == 'kompas'">
      <button type="button" class="button link-button has-icon block-kompas-viewmode-trigger"
        :class="{ selected: selectedVariant === 1 }" v-on:click="setSelectedVariant(1)" title="Toon Kompas"
        data-view="Kompas">
        <i class="fa fa-compass"></i>
        Toon Kompas
      </button>
      <button type="button" class="button link-button has-icon block-kompas-viewmode-trigger"
        :class="{ selected: selectedVariant === 2 }" v-on:click="setSelectedVariant(2)" title="Toon Ontwikkelkompas"
        data-view="Ontwikkelkompas">
        <i class="fa fa-chart-line"></i>
        Toon Ontwikkelkompas
      </button>
    </div>

    <!-- KOMPAS CHART + SIDEBAR -->
    <div class="kompas-wrapper" :class="fullscreen ? 'fullscreen' : ''">
      <!-- KOMPAS SIDEBAR -->
      <div id="kompas-foldout-wrapper" class="kompas-foldout-wrapper">
        <!-- Kompas Fold-out -->
        <kompas-legenda :active="showLegend"></kompas-legenda>

        <!-- Kompas Sidebar Buttons -->
        <ul id="kompas-sidebar-buttons" class="kompas-sidebar-buttons">
          <!-- 'Fullscreen'-button (?) -->
          <li>
            <button type="button" class="button icon-only transparent block-kompas-foldout-trigger"
              :class="fullscreen ? 'active' : ''" title="Toon Kompas" v-on:click="toggleFullscreenMode()">
              <i class="fa" :class="fullscreen ? 'fa-compress-arrows-alt' : 'fa-expand-arrows-alt'"></i>
            </button>
          </li>
          <!-- 'Kompas Foldout'-buttons -->
          <li>
            <button type="button" class="button icon-only transparent block-kompas-foldout-trigger"
              :class="showLegend ? 'active' : ''" title="Toelichting Kompas" v-on:click="showLegend = !showLegend">
              <i class="fa fa-info-circle"></i>
            </button>
          </li>
          <li>
            <button type="button" class="button icon-only transparent block-kompas-foldout-trigger"
              title="Deze pagina delen"
              v-on:click="showVisual = (showVisual === 'sharing' ? 'kompas' : 'sharing'); showLegend = false">
              <i class="fa fa-share-alt"></i>
            </button>
          </li>
        </ul>
      </div>

      <kompas-sharing v-if="showVisual === 'sharing'"></kompas-sharing>

      <kompas-wheel v-if="showVisual == 'kompas'" :wijk="selectedWijk" :variant="selectedVariant"></kompas-wheel>
    </div>

    <!-- KOMPAS INFORMATION (LEGEND + DESCRIPTION) -->
    <div class="kompas-information" v-show="!fullscreen">
      <indicator-info filter="kompas" v-bind:show-score="true" v-bind:show-meetwaarde="true"></indicator-info>
    </div>
  </div>
</template>

<script>
import IndicatorInfo from './IndicatorInfo.vue';
import KompasLegenda from './KompasLegenda.vue';
import KompasSharing from './KompasSharing.vue';
import KompasWheel from './KompasWheel.vue';
import KruimelpadNavigatie from './KruimelpadNavigatie.vue';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KompasContainer',

  components: {
    KruimelpadNavigatie,
    KompasLegenda,
    KompasWheel,
    IndicatorInfo,
    KompasSharing,
  },
  data() {
    return {
      label: '',
      showVisual: 'kompas',
      showLegend: false,
      fullscreen: false,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['selectedWijk', 'selectedVariant']),
  },
  methods: {
    ...mapMutations(['setSelectedVariant']),
    toggleFullscreenMode() {
      if (this.showVisual === 'kompas') {
        this.fullscreen = !this.fullscreen;
        window.dispatchEvent(new Event('resize'));
      } else {
        this.showVisual = 'kompas';
      }
    },
  },
};
</script>
<style lang="scss">
.hide {
  display: hidden;
}

.kompas-wrapper.fullscreen .kompas-wheel {
  margin: 20px !important;
}
</style>
