import Vue from 'vue';
import Vuex from 'vuex';
import { Utils } from '../ts/utils';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    selectedWijk: null,
    selectedStadsdeel: null,
    selectedIndicatorId: null,
    selectedVariant: 1,
    indicatorStore: {},
    // kompasIndicatorStore: {},
    // comparisonIndicatorStore: {},
  },

  mutations: {
    setSelectedWijk(state, payload) {
      const changeState = state;
      changeState.selectedWijk = payload;
    },
    setSelectedStadsdeel(state, payload) {
      const changeState = state;
      changeState.selectedStadsdeel = payload;
    },
    setSelectedIndicatorId(state, payload) {
      const changeState = state;
      changeState.selectedIndicatorId = parseInt(payload);
    },
    setSelectedVariant(state, payload) {
      const changeState = state;
      changeState.selectedVariant = payload;
    },
    setIndicatorStore(state, payload) {
      const changeState = state;
      changeState.indicatorStore = payload;
    },
  },

  actions: {
    initialiseStore(context) {
      // this method is called from the Vue initialization
    },

    selectWijkAndStadsdeel({ commit }, wijkId) {
      // loop through all stadsdelen (with nested wijken) array and try to match
      // a wijk. If found, set the stadsdeel + the wijk in the store
      Utils.instance.getStadsdelen().then(stadsdelen => {
        for (const stadsdeel of stadsdelen) {
          for (const wijk of stadsdeel.wijk_set) {
            if (wijk.id === wijkId) {
              commit('setSelectedStadsdeel', stadsdeel);
              commit('setSelectedWijk', wijk);
              Utils.Instance.setMemoryData('selectedWijkId', wijk.id);
              return;
            }
          }
        }
      });
    },
  },

  getters: {
    selectedWijk(state) {
      return state.selectedWijk;
    },
    selectedStadsdeel(state) {
      return state.selectedStadsdeel;
    },
    selectedIndicatorId(state) {
      return state.selectedIndicatorId;
    },
    selectedVariant(state) {
      return state.selectedVariant;
    },
    indicatorStore(state) {
      return state.indicatorStore;
    },
    // kompasIndicatorStore(state) {
    //   return state.kompasIndicatorStore;
    // },
    // comparisonIndicatorStore(state) {
    //   return state.comparisonIndicatorStore;
    // },
  },
});

export default store;
