<template>
  <div class="bar-container"></div>
</template>

<script>
const britecharts = require('britecharts');
// const colors = require('britecharts/src/charts/helpers/color');
const _ = require('lodash');

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Utils } from '../../ts/utils';

import 'britecharts/dist/css/britecharts.min.css';

export default {
  name: 'IndicatorBarchart',

  data() {
    return {
      container: '',
      barChart: '',
      wijken: {},
      wijkMeetwaarden: {},
      kompasLegend: [],
      indicator: Utils.Instance.rootIndicator,
      throttledRedraw: _.throttle(this.redrawChart, 200),
    };
  },

  methods: {
    ...mapMutations(['setSelectedWijk']),
    ...mapActions(['selectWijkAndStadsdeel']),

    getWijkNaam(wijkId) {
      wijkId = parseInt(wijkId);

      for (const wijk of this.wijken) {
        if (wijkId === wijk.id) {
          return wijk.naam;
        }
      }
    },
    getWijk(wijkNaam) {
      for (const wijk of this.wijken) {
        if (wijkNaam === wijk.naam) {
          return wijk;
        }
      }
    },
    colorizeBarchart() {
      let bars = this.container
        .select('.chart-group')
        .selectAll('.bar')
        .style('fill', bar => {
          let level = '';
          let fill = '';

          for (let record of this.kompasLegend.stoplicht) {
            if (bar.value >= record.min && bar.value <= record.max) {
              level = record.level;
              break;
            }
          }

          if (bar.name === "Gemiddelde gemeente") {
            return "#cecece";
          }
          if (this.selectedWijk && (bar.name === this.selectedWijk.naam)) {
            return '#621671';
          }

          if (
            this.indicatorStore &&
            this.indicatorStore.kleurenschema
              .toLowerCase()
              .indexOf('blauw') >= 0
          ) {
            fill = Utils.bluesColors[level - 1];
          } else {
            fill = Utils.redGreenColors[level - 1];
          }

          return fill;
        });
    },
    redrawChart() {
      const newContainerWidth = this.container.node()
        ? this.container.node().getBoundingClientRect().width
        : false;

      // Set the new width on the chart
      this.barChart.width(newContainerWidth);

      // Render the chart again
      this.container.call(this.barChart);
    },
    resetChart() {
      // Wijk or Indicator changed: reset the container
      this.container.datum(this.getWijkmeetwaarden()).call(this.barChart);
      this.colorizeBarchart();
    },

    resetData() {
      Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
        this.wijkMeetwaarden = data;
        this.container.datum(this.getWijkmeetwaarden()).call(this.barChart);
        this.colorizeBarchart();
        this.redrawChart();
      })
    },
    getWijkmeetwaarden() {
      if (this.wijkMeetwaarden && this.wijken && this.wijken.length) {
        let mwpi = {};
        for (let key in this.wijkMeetwaarden) {
          mwpi[this.getWijkNaam(key)] = this.wijkMeetwaarden[key][this.selectedIndicatorId][0];
        }

        // Create items array
        const items = Object.keys(mwpi).map(function (key) {
          return { name: key, value: mwpi[key] };
        });

        // Sort the array based on the second element
        items.sort(function (first, second) {
          return first.value - second.value;
        });

        return items;
      }
    },
  },

  mounted() {
    this.container = d3.select('.bar-container');
    this.barChart = britecharts.bar();
    this.kompasLegend = Utils.getKompasLegend();

    // Configure chart
    this.barChart
      .margin({
        left: 250,
        top: 0,
      })
      .isAnimated(false)
      .isHorizontal(true)
      .height(1024)
      .width(750);

    Utils.instance.getWijken().then(data => {
      this.wijken = data;
    });

    Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
      this.wijkMeetwaarden = data;
      this.container.datum(this.getWijkmeetwaarden()).call(this.barChart);
      this.colorizeBarchart();
      this.redrawChart();

      // lodash throttle on redraw after resize
      window.addEventListener('resize', this.throttledRedraw);

      // Make bars clickable and let them select a 'wijk'
      const bars = this.container.selectAll('.bar');
      bars.on('click', (d) => {
        this.selectWijkAndStadsdeel(this.getWijk(d.name).id);
      });
    });
  },

  computed: {
    ...mapGetters(['indicatorStore', 'selectedWijk', 'selectedIndicatorId', 'selectedVariant']),
  },

  watch: {
    selectedWijk() {
      this.resetChart();
    },
    selectedIndicatorId() {
      this.resetChart();
    },
    selectedVariant() {
      this.resetData();
    }
  },
};
</script>
