<template>
  <!-- note that dummy class is in scss -->
  <div class="kompas-wheel dummy" ref="wheel"></div>
</template>

<script>
import { Kompas } from '../../ts/kompas';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KompasWheel',
  props: {
    variant: Number,
    wijk: null,
  },

  data() {
    return {
      title: '',
      kompas: null,
    };
  },

  mounted() {
    this.initKompas();
  },

  computed: {
    ...mapGetters(['selectedWijk', 'selectedIndicatorId']),
  },

  methods: {
    ...mapMutations(['setSelectedIndicatorId']),

    initKompas() {
      this.kompas = new Kompas(
        this.$refs.wheel,
        this.wijk,
        this.variant,
        this.setSelectedIndicatorId,
        this.kompasReady
      );
      this.kompas.selectIndicatorId(this.selectedIndicatorId, false);
    },
    kompasReady() {
      this.kompas.selectIndicatorId(this.selectedIndicatorId, false);
    },
  },

  watch: {
    wijk() {
      this.initKompas();
    },
    variant() {
      this.initKompas();
    },
    selectedIndicatorId() {
      this.kompas.selectIndicatorId(this.selectedIndicatorId, false);
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Karla', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Karla') format('opentype');
  font-weight: 300;
}

.slice {
  path {
    stroke: #fff;
    fill-opacity: 1;
    stroke-width: 1.5;

    &.klikRing {
      &:hover {
        fill-opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  &.selected path {
    stroke: #000;
  }

  text {
    font-family: 'Karla', sans-serif; // same as britecharts' barchart
    -webkit-font-smoothing: antialiased;
    pointer-events: none;
    user-select: none;
    font-size: 18px;
    font-weight: 400;
    text-anchor: middle;
  }

  &.ring-4 text {
    font-size: 17px;
  }

  &.ring-5 text {
    font-size: 14px;
  }
}
</style>
