<template>
  <div class="block block-description">
    <h2 id="selected-metric-name">{{ (indicatorTitle || 'Geen criterium geselecteerd') | cleanup }}</h2>
    <indicator-score v-if="showScore" :wijk-id="selectedWijk.id" :indicator-id="selectedIndicatorId"
      :selectedVariant="selectedVariant"></indicator-score>
    <selected-meetwaarde v-if="showMeetwaarde" :wijk-id="selectedWijk.id" :indicator-id="selectedIndicatorId"
      :selectedVariant="selectedVariant"></selected-meetwaarde>
    <p>
      <strong>{{ indicatorOmschrijving }}</strong>
    </p>
    <div>
      <p v-if="filter === 'kompas' && indicatorText" id="selected-metric-description">
        {{ indicatorText }}
      </p>
      <div v-if="filter === 'kompas'" class="selected-metric-description-wrapper">
        <p v-if="indicatorChildren"><strong>Onderliggende indicatoren</strong></p>
        <template v-for="(child, index) in indicatorChildren">
          <button v-if="child.id != 991" :key="child.data.naam" type="button" class="button link-button"
            v-on:click="setSelectedChild(child.data.id)" :class="activeChild === index ? 'active' : ''">
            {{ child.data.naam }}
          </button>
          <div :key="child.data.id" class="description">
            <!-- getChildDescription(child.data.id) -->
          </div>
        </template>
      </div>
      <small v-if="filter === 'kompas'">Bron: {{ indicatorBron }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Utils } from '../../ts/utils';
import IndicatorScore from './IndicatorScore.vue';
import SelectedMeetwaarde from './SelectedMeetwaarde.vue';

export default {
  name: 'IndicatorInfo',

  data() {
    return {
      wijkIndicatorTekstData: '',
      wijkIndicatorData: {},
      indicatorenTree: {},
      indicatorTitle: '',
      indicatorOmschrijving: '',
      indicatorBron: '',
      indicatorText: '',
      indicatorChildren: [],
      activeChild: -1,
    };
  },
  props: {
    showScore: {
      type: Boolean,
      default: true,
    },
    showMeetwaarde: {
      type: Boolean,
      default: true,
    },
    // either 'kompas' or 'comparison'
    filter: {
      type: String,
      default: 'kompas',
    },
  },
  components: {
    IndicatorScore,
    SelectedMeetwaarde,
  },
  mounted() {

    Utils.Instance.getWijkIndicatorTekstData().then(data => {
      this.wijkIndicatorTekstData = data;
      this.getWijkIndicatorInfo();
    });
    Utils.instance.getIndicatoren().then(data => {
      // create a tree structure using d3.stratify
      this.indicatorenTree = d3
        .stratify()
        .id(d => d.id)
        .parentId(d => d.parent_id)(data);
      this.init();
    });
  },
  methods: {
    ...mapMutations(['setSelectedIndicatorId', 'setIndicatorStore']),
    init() {
      this.getWijkIndicatorInfo();
      this.getText();
      this.getTitle();
      this.getChildren();
    },
    getWijkIndicatorInfo() {
      if (this.wijkIndicatorTekstData && this.selectedWijk) {
        for (let record of this.wijkIndicatorTekstData.items) {
          if (record.wijk && record.wijk.id === this.selectedWijk.id && record.indicator) {
            this.wijkIndicatorData[record.indicator.id] = record;
          }
        }
      }
    },
    getTitle() {
      if (this.indicatorId) {
        Utils.Instance.getIndicator(this.indicatorId).then(indicator => {
          this.indicatorTitle = indicator.naam;
          this.indicatorOmschrijving = indicator.omschrijving;
          this.indicatorBron = indicator.bron;
        });
      }
    },
    getChildren() {
      if (this.indicatorenTree && this.indicatorenTree.each) {
        this.indicatorenTree.each(node => {
          if (parseInt(node.data.id) === this.selectedIndicatorId) {
            this.indicatorChildren = node.children;
          }
        });
      }
    },
    getText() {
      let text = '';
      if (this.wijkIndicatorData && this.indicatorId) {
        let data = '';

        const indicatorIdString = this.indicatorId.toString();
        if (indicatorIdString.length === 2) {
          data = this.wijkIndicatorData[this.indicatorId];
          if (data) {
            text = this.selectedVariant === 1 ? data.stand : data.ontwikkeling;
          }
          this.indicatorText = text;
          return;
        }
        // check the current indicator for indicator text, or the parent if nothing's found
        for (let i = indicatorIdString.length; i >= 3; i--) {
          data = this.wijkIndicatorData[parseInt(indicatorIdString.substring(0, i))];
          if (data) {
            text = this.selectedVariant === 1 ? data.stand : data.ontwikkeling;
            break;
          }
        }
      }
      this.indicatorText = text;
    },
    /* Unused at the moment. For now we  use the child's id as a selector instead of opening
       up the child's description. */
    getChildDescription(id) {
      Utils.Instance.getIndicator(id).then(indicator => {
        return indicator.omschrijving;
      });
    },
    setSelectedChild(id) {
      this.setSelectedIndicatorId(id);
    },
  },
  computed: {
    ...mapGetters([
      'selectedWijk',
      'indicatorStore',
      'selectedVariant',
      'selectedIndicatorId',
    ]),

    indicatorId() {
      if (this.indicatorStore.indicator) {
        return this.indicatorStore.indicator.id;
      } else {
        return this.selectedIndicatorId;
      }
    },
  },
  filters: {
    cleanup(value) {
      return value.replace(/[|]/g, ' ');  //.replace(/[-]/, '');
    },
  },
  watch: {
    indicatorId() {
      this.init();
    },
    selectedWijk() {
      this.init();
    },
    selectedVariant() {
      this.getText();
    }
  },
};
</script>
