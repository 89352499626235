// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
let d3 = require('d3');
import { Utils } from './utils';

export class WijkIndicatorTekst {
  protected curSelectedWijkId: string = null;
  protected wijkIndicatorData: any = null;

  public constructor() {
    this.loadWijkIndicatorTekstData();

    let ontwikkelAnchor: HTMLElement = document.querySelectorAll(
      "[data-click-target='kompas-main-color-wheel-ontwikkel']"
    )[0] as HTMLElement;
    let standAnchor: HTMLElement = document.querySelectorAll(
      "[data-click-target='kompas-main-color-wheel-stand']"
    )[0] as HTMLElement;

    if (ontwikkelAnchor) {
      ontwikkelAnchor.onclick = () => {
        let standTekst = document.getElementsByClassName('wijk_indicator_stand')[0];
        standTekst.classList.add('hidden');
        let ontwikkelTekst = document.getElementsByClassName('wijk_indicator_ontwikkeling')[0];
        ontwikkelTekst.classList.remove('hidden');
      };
    }
    if (standAnchor) {
      standAnchor.onclick = () => {
        let standTekst = document.getElementsByClassName('wijk_indicator_stand')[0];
        standTekst.classList.remove('hidden');
        let ontwikkelTekst = document.getElementsByClassName('wijk_indicator_ontwikkeling')[0];
        ontwikkelTekst.classList.add('hidden');
      };
    }
  }

  public initWijkIndicatorTekst() {
    Utils.Instance.inMemoryData$.subscribe(data => {
      let newSelectedWijkId: string = data['selectedWijkId'];
      if (newSelectedWijkId) {
        if (newSelectedWijkId !== this.curSelectedWijkId) {
          this.curSelectedWijkId = newSelectedWijkId;
          this.showSelectedWijkIndicatorTeksten(newSelectedWijkId);
        }
      }
    });
  }

  protected loadWijkIndicatorTekstData() {
    Utils.Instance.getWijkIndicatorTekstData().then(wijkIndicatorData => {
      this.wijkIndicatorData = wijkIndicatorData;
      if (this.curSelectedWijkId) {
        this.showSelectedWijkIndicatorTeksten(this.curSelectedWijkId);
      }
    });
  }

  protected showSelectedWijkIndicatorTeksten(wijkId: string) {
    /*
     * Insert the selected wijk indicator teksten (stand + ontwikkeling)
     */

    let standHtml = '';
    let ontwikkelHtml = '';

    if (this.wijkIndicatorData) {
      for (let record of this.wijkIndicatorData.items) {
        if (record && record.wijk && String(record.wijk.id) === wijkId) {
          let wijkIndicatorData = record;
          let wijkId = wijkIndicatorData.wijk.id;
          let indicatorId = wijkIndicatorData.indicator.id;

          const indicatorPromise = Utils.Instance.getIndicator(Number(indicatorId));
          Promise.resolve(indicatorPromise).then(data => {
            let title = data.naam;
            title = title.replace(/\|/g, ' ');

            standHtml += `<h2 class="title mt-3">Stand van de indicator: ${title}</h2>`;
            standHtml += `<div>${wijkIndicatorData.stand}</div>`;

            ontwikkelHtml += `<h2 class="title mt-3">Ontwikkeling van de indicator: ${title}</h2>`;
            ontwikkelHtml += `<div>${wijkIndicatorData.ontwikkeling}</div>`;

            let el = document.getElementsByClassName('wijk_indicator_stand');
            for (let i = 0; i < el.length; i++) {
              if (standHtml) {
                el[i].innerHTML = standHtml;
              } else {
                el[i].innerHTML = '';
              }
            }

            el = document.getElementsByClassName('wijk_indicator_ontwikkeling');
            for (let i = 0; i < el.length; i++) {
              if (ontwikkelHtml) {
                el[i].innerHTML = ontwikkelHtml;
              } else {
                el[i].innerHTML = '';
              }
            }
          });
        }
      }
    }
  }
}
