import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');
import { Utils } from './utils';
import { Gauge } from './gauge';
import { Legend } from './legend';

export class LegendGauge {

  public constructor(gaugeElement: Element, legendElement: Element) {
    let wijkenMeetwaarden = {};

    let gauge: Gauge = null;
    gauge = new Gauge(gaugeElement, {
      size: 300,
      clipWidth: 300,
      clipHeight: 200,
      ringWidth: 60,
      maxValue: 200,
      transitionMs: 4000,
      majorTicks: 7,
    });
    gauge.render(0);

    let legend: Legend = null;
    legend = new Legend(legendElement);

    let wijkenMeetwaardenPromise = Utils.Instance.getWijkenMeetwaarden();
    wijkenMeetwaardenPromise.then((wijkenMeetwaarden) => {
      Utils.Instance.inMemoryData$.subscribe((data) => {
        const wijkId = (data as any).selectedWijkId;
        const indicatorId = (data as any).selectedIndicator;
        if (wijkId && indicatorId) {
          const indicatorPromise = Utils.Instance.getIndicator(indicatorId);
          indicatorPromise.then((indicator) => {
            const scaleNumber = indicator.categorieCnt;
            let colorScheme = [...scaleChromatic.schemeRdYlGn[scaleNumber]];
            if ((indicator as any).kleurenschema.toLowerCase().indexOf('blauw') >= 0) {
              colorScheme = [...scaleChromatic.schemeBlues[scaleNumber]];
            }
            let config = {
              arcColorFn: colorScheme,
              majorTicks: scaleNumber
            };
            let value = wijkenMeetwaarden[wijkId][indicatorId];
            gauge.update(value, config);
            legend.update(indicator);
          });
        }
      });
    });
  }
}
