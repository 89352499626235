import d3 = require('d3');
import dimple = require('dimple');

import { BaseChart } from './basechart';
import { IDataRecord } from './basechart';
import { Axis } from './basechart';
import { IKeyValueProps } from './basechart';
import { IGraphData } from './models/graphdata';
import { IGraphModel } from './models/graphmodel';
import { Utils } from './utils';

export class HorizontalBarChart extends BaseChart {

  protected hoveredElement: any = null;

  public constructor(element: Element) {
    super(element);
  }

  public drawScene(graphModel: IGraphModel, data: IGraphData) {

    super.drawScene(graphModel, data);
    const instance = this;

    // get the data

    const seriesData = [];
    const yLabels = [];  // used for sorting
    const zLabels = [];  // used for sorting

    const colX = this.fieldNames[1];
    const colY = this.fieldNames[0];

    // colZ is an optional 3rd dimension in the data. If this 3rd
    // column is present the barchart will show as a stacked
    // barchart
    let colZ = null;
    if (this.fieldNames.length > 2) {
      colZ = this.fieldNames[2];
    }

    // process the data records
    this.seriesData.forEach((d) => {
      yLabels.push(d[colY]);
      if (colZ) {
        zLabels.push(d[colZ]);
      }
    });

    this.xAxis = this.chart.addMeasureAxis('x', colX);
    this.yAxis = this.chart.addCategoryAxis('y', colY);

    this.yAxis.addOrderRule(yLabels, true);

    const series = this.chart.addSeries(colZ, dimple.plot.bar);
    if (colZ) {
      // sorteer de stacked bars op volgorde van zLabels
      series.addOrderRule(zLabels);
    }

    this.setupAxis(Axis.x, colX);
    this.setupAxis(Axis.y, colY);

    // Handle the hover event - overriding the default behaviour
    series.addEventHandler('mouseover', (e) => { instance.showPopup(e); });
    // Handle the leave event - overriding the default behaviour
    series.addEventHandler('mouseleave', (e) => { instance.removePopup(e); });

    this.chart.draw();
    this.fixAxisStyling();
    return this.chart;
  }

  protected loadProperties(props: IKeyValueProps[]) {
    // override the default x axis formatting (year only)
    // note that this default setting can still be overridden in the
    // loadProperties method.
    this.props['y-axis-format'] = null; // no formatting
    this.props['margin-top'] = '2%';  // tiny margin for the popup
    super.loadProperties(props);
  }

  protected setupLegend() {
    // we don't want a legend for a simple barchart
    // only for a stacked barchart
    if (this.fieldNames.length > 2) {
      super.setupLegend();
    }
  }

  protected showPopup(e) {
    /* Event to handle mouse enter
    *  TODO: this method still has too many magic hardcoded settings
    *  which should be removed or made configurable
    */

    this.hoveredElement = e.selectedShape;
    this.hoveredElement.classed('hovered', true);

    // let cx = d3.event.clientX;
    const cx = d3.mouse(d3.event.currentTarget)[0];

    // Get the properties of the selected shape
    // let cx = parseFloat(e.selectedShape.attr('x'));
    const cy = parseFloat(e.selectedShape.attr('y'));

    // Set the (initial) size and position of the popup
    let width = 150;
    const height = 45;

    let x = 0;
    let y = cy + height + 10 > this.chart.y + this.chart.height ?
      cy - height : cy;

    // Create a group for the popup objects
    this.popup = this.svg.append('g');

    // Add a rectangle surrounding the text
    const rect = this.popup
    .append('rect')
    .attr('x', x + 5)
    .attr('y', y - 5)
    .attr('width', width)
    .attr('height', height)
    .attr('rx', 5)
    .attr('ry', 5)
    .style('z-index', '1')
    .style('fill', 'white')
    .style('stroke', 'black')
    .style('stroke-width', 2);

    // Add multiple lines of text
    const text = this.popup
    .append('text')
    .attr('class', 'popupText');

    const data = this.hoveredElement.data();

    y += 15;

    text
    .append('tspan').attr('x', x + 10).attr('y', y)
    .text(e.yValue.toLocaleString())
    .style('font-family', 'sans-serif').style('font-size', 10);

    // do we have 3rd dimension? (stacked bar)
    if (this.fieldNames.length > 2) {
      y += 15;
      rect.attr('height', height + 15);
      text.append('tspan').attr('x', x + 10).attr('y', y)
      .text(data[0]['aggField'][0])
      .style('font-family', 'sans-serif').style('font-size', 10);
    }

    text.append('tspan').attr('x', x + 10).attr('y', y + 15)
    .text(data[0].xValue.toLocaleString())
    .style('font-family', 'sans-serif').style('font-size', 10).style('font-weight', 'bold');

    x = cx - 20;  // amount of pixels left of mouse pointer

    // maak box breedte passend aan text
    const textBBox = text.node().getBBox();
    width = textBBox.width + 10;
    rect.attr('width', width);

    // will the popup fit left of the pointer?
    x = cx - width - 20;
    if (x < 0) {
      x = cx + 20;
    }

    // rect.attr('x', x - width - 5);
    rect.attr('x', x);

    d3.selectAll('.popupText tspan')
    // .attr('x', x - width - 0);
    .attr('x', x + 5);

  }

  // Event to handle mouse exit
  protected removePopup(e) {
    // Remove the popup
    if (this.popup !== null) {
      this.popup.remove();
    }
    if (this.hoveredElement) {
      this.hoveredElement.classed('hovered', false);
    }
  }

}
