// import { library, dom } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'

// library.add(fas, fab)

// Kicks off the process of finding <i> tags and replacing with <svg>
// dom.watch()

import d3 = require('d3');
import jQuery = require('jquery');
import L = require('leaflet');
import PieWidget from '../js/pieWidget.class.js';
import Waffle = require('../js/waffle.class.js');

require('../js/leaflet-gesture-handling.js');
// require('malihu-custom-scrollbar-plugin');

import DeviceDetector = require('device-detector/dist/DeviceDetector.js');
import Selectr = require('mobius1-selectr/dist/selectr.min.js');

declare global {
  interface Window {
    // tslint:disable-line
    d3: any;
    L: any;
    jQuery: any;
    $: any;
    Waffle: any;
    PieWidget: any;
    DeviceDetector: any;
    Selectr: any;
  }
}

window.d3 = window.d3 || d3;
window.L = window.L || L;
window.jQuery = window.jQuery || jQuery;
window.$ = window.$ || window.jQuery;
window.Waffle = window.Waffle || Waffle;
window.PieWidget = window.PieWidget || PieWidget;
window.DeviceDetector = window.DeviceDetector || DeviceDetector;
window.Selectr = window.Selectr || Selectr;

// inject the d3-liquid-fill-gauge d3 plugin. it will use the global
// d3 instance which is ok
import 'd3-liquid-fill-gauge';

const NL_NL = {
  currency: ['€\u00a0', ''],
  decimal: ',',
  grouping: [3],
  thousands: '.',
};
d3.formatDefaultLocale(NL_NL);

require('../js/jquery.mCustomScrollbar.concat.min.js');

require('../wildsea/Scripts/main');
// require('../wildsea/Scripts/kompas');

/*
 * dispatch a custom 'BasisMonitorBootstrapped' event so our custom scripts
 * in the django frontend can listen for it after our global context has been
 * set up (d3 and plugins, bootstrap, jquery etc)
 */

window.onload = () => {
  const event = new CustomEvent('BasisMonitorBootstrapped');
  const body = (document as any).getElementsByTagName('body')[0];
  body.dispatchEvent(event);
};

export class BasisMonitorApp {
  public ready(fn) {
    if ((document as any).attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }
}
