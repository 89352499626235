import {Utils} from './utils';

let jQuery = require('jquery');

export class WijkSelector {

  _selector;
  _wijken;
  _selected;

  public constructor(domSelector: string, wijken: any) {
    this._selector = domSelector;
    this._wijken = wijken;
  }

  public render() {

    Utils.Instance.inMemoryData$.subscribe((data) => {
      let selected = document.querySelectorAll(`[data-wijkid='${data['selectedWijkId']}']`);
      if (selected) {
        let current = selected[0] as HTMLAnchorElement;
        this._selectItem(current);
        this._selected = selected[0];
      }
    });

    // Create the container and unordered list elements

    let div = document.getElementById(this._selector) as HTMLDivElement;
    let ul = document.createElement('ul') as HTMLUListElement;

    ul.setAttribute('class', 'nav nav-pills');
    div.innerHTML = '';
    div.appendChild(ul);

    // Create the list items

    for (let key in this._wijken) {

      let li = document.createElement('li') as HTMLLIElement;

      li.setAttribute('class', 'nav-item nav-item-wijk small '+ 'stadsdeel-' + this._wijken[key].stadsdeel.id);
      ul.appendChild(li);

      let a = document.createElement('a') as HTMLAnchorElement;

      a.setAttribute('data-wijkid', this._wijken[key]['id']);
      a.setAttribute('class', 'nav-link p-1 mr-1 mb-1  bg-light-gray');
      a.setAttribute('href', '#');

      a.innerText = this._wijken[key]['naam'];

      a.addEventListener('click', (e: Event) => {
        e.preventDefault();

        this._selectItem(a);

        let selectedWijkId = a.getAttribute('data-wijkid');

        Utils.Instance.setMemoryData('selectedWijkId', selectedWijkId);

        // Collapse accordion
        jQuery('#collapse-kompas').collapse('show');
      });

      li.appendChild(a);
    }

  }

  private _selectItem(current: HTMLAnchorElement) {
    if (this._selected) {
      this._selected.classList.remove('active');
    }
    if (current) {
      current.classList.add('active');
      this._selected = current;
    }
  }
}
