import L from "leaflet";
import "proj4";
import "proj4leaflet";

const mapSettings = {
  zoom: 7.5,
  minZoom: 2,
  maxZoom: 14,
  zoomControl: false,
  attributionControl: false,
  bounds: [
    [53.11, 6.39],
    [53.32, 6.97]
  ],
  fitBoundsOptions: { paddingBottomRight: [330, 0] }, // rekening houden met rechter navigatie balk
  RD: new L.Proj.CRS(
    "EPSG:28992",
    "+proj=sterea +lat_0=52.15616055555555" +
      "+lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 " +
      "+ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658," +
      "-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs",
    {
      resolutions: [
        3440.64,
        1720.32,
        860.16,
        430.08,
        215.04,
        107.52,
        53.76,
        26.88,
        13.44,
        6.72,
        3.36,
        1.68,
        0.84,
        0.42,
        0.21
      ],
      bounds: L.bounds(
        [-285401.92, 22598.08],
        [595401.9199999999, 903401.9199999999]
      ),
      origin: [-285401.92, 22598.08]
    }
  )
};

export default mapSettings;
