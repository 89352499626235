<template>
  <!-- Kompas Fold-out -->
  <div class="kompas-foldout" :class="{ active: active }">
    <table
      cellspacing="3"
      v-for="(legendtype, idx) in this.legend"
      v-bind:key="idx"
    >

      <tr v-for="step in legendtype" v-bind:key="step.min">
        <td></td>
        <td>
          <span :class="`level-${idx === 'blauw' ? 'profile-' : ''}${step.level}`"></span>
        </td>
        <td>{{ step.label }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { Utils } from "../../ts/utils";
export default {
  name: "KompasLegenda",
  props: {
    active: Boolean
  },
  data() {
    return {
      legend: null
    };
  },
  mounted() {
    this.legend = Utils.getKompasLegend();
  }
};
</script>
