import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');
import { Utils } from './utils';

const width = 900;
const height = width;
const radius = width / 2;
const middleCircle = 40; //de radius van de binnencirkel
const ringProps = 0.8; //de verhouding tussen de binnen- en buitenringen

interface IIndicatorChangedCallbackType {
  (indicatorId: number): void;
}

interface IReadyCallbackType {
  (): void;
}

export class Kompas {
  private container = null;
  private svg = null;
  private root = null;
  private slices = null;
  private clickedID = Utils.Instance.rootIndicator;
  private clickedDepth = 0; //de depth van het aangeklikte segment

  private callbackIndicatorChanged: IIndicatorChangedCallbackType = x => {
    return;
  };

  x = d3
    .scaleLinear()
    .range([0, 2 * Math.PI])
    .domain([0, 1]);

  //de binnencirkels hebben een andere schaling dan de buitenste buitenringen
  //ook de range is anders dan de buitenste ringen
  protected yBinnen = d3
    .scaleLinear()
    .range(this.clickedDepth ? [0, middleCircle] : [middleCircle, ringProps * radius])
    .domain([1 / 6, 0.5]);

  //zie opmerking hierboven
  protected yBuiten = d3
    .scaleLinear()
    .range(this.clickedDepth ? [middleCircle, radius] : [ringProps * radius, radius])
    .domain([0.5, 1]);

  protected partition = d3.partition();

  protected arc = d3
    .arc()
    .startAngle(d => {
      return Math.max(0, Math.min(2 * Math.PI, this.x(d.x0)));
    })
    .endAngle(d => {
      return Math.max(0, Math.min(2 * Math.PI, this.x(d.x1)));
    })
    .innerRadius(d => {
      //de centrale cirkel heeft een id van rootIndicator bij het begin
      if (d.data.data.id == this.clickedID) {
        return 0;
      }
      return Math.max(0, d.depth < 3 ? this.yBinnen(d.y0) : this.yBuiten(d.y0));
    })
    .outerRadius(d => {
      if (d.data.data.id == this.clickedID) {
        return middleCircle;
      }
      return Math.max(0, d.depth < 3 ? this.yBinnen(d.y1) : this.yBuiten(d.y1));
    });

  protected getColor = d => {
    let zScore = Utils.Instance.meetwaardeToZscore(d.waarde);

    if (zScore == null) {
      return '#E9ECEF'; // lightgray for undefined values
    }

    const scaleNumber = d.data.data.categorieCnt;

    // let colorScheme = [...scaleChromatic.schemeRdYlGn[scaleNumber]];
    let colorScheme = Utils.redGreenColors;

    if (d.data.data.kleurenschema.toLowerCase().indexOf('blauw') >= 0) {
      // colorScheme = [...scaleChromatic.schemeBlues[scaleNumber]];
      colorScheme = Utils.bluesColors;
    }

    const domain: [number, number] = [-3, 3];
    const kleur: any = d3
      .scaleQuantize<string>()
      .domain(domain)
      .range(colorScheme)(zScore);
    return kleur;
  };

  protected titelOpmaak(titel) {
    return titel.replace('-|', '').replace(/\|/g, ' ');
  }

  protected fixEqualRadials(node) {
    if (node.depth == 0) {
      node.value = 100.0;
    } else {
      node.value = node.parent.value / node.parent.children.length;
    }
  }

  // initialValueSetter sets a value on the outer nodes (with no children) only
  protected initialValueSetter(node, meetwaardenArray) {
    node.value = node.children ? 0 : 1;
    node.waarde = 0;

    if (!node.children) {
      // this node is in the outer ring, so set the initial value by
      // getting the meetwaarde for this node
      for (const mw of meetwaardenArray) {
        if (mw.indicator === node.data.data.id) {
          node.waarde += mw.waarde;
          // we can probably break out of the loop here? only one score
          // per outer node? To even improve lookup we could remove this node
          // from the meetwaardenArray I think
          break;
        }
      }
    }
  }

  // basic function to get children from a data node
  protected getChildren(d) {
    return d.children;
  }

  // the summation function calculates the new value by adding up the values from
  // the child nodes. Note that this function should be called bottom-up
  protected summ(node) {
    let sum = node.value;
    const children = node.children;
    let i = children && children.length;
    while (--i >= 0) {
      sum += children[i].value;
    }
    node.value = sum;
  }

  // the summation function calculates the new value by adding up the values from
  // the child nodes. Note that this function should be called bottom-up
  protected summMeetwaarde(node) {
    let sum = node.waarde;
    const children = node.children;
    let i = children && children.length;
    let amountRelevantChildren = 0;
    while (--i >= 0) {
      if (node.data.data.kleurenschema === children[i].data.data.kleurenschema) {
        sum += children[i].waarde;
        // also ignore meetwaarden with 0 value.. geen meetingen?
        if (children[i].waarde !== 0) {
          amountRelevantChildren++;
        }
      }
    }

    const amountNodes = amountRelevantChildren ? amountRelevantChildren : 1;
    const value = sum / amountNodes;
    node.waarde = value;
  }

  public selectIndicatorId(indicatorId, emit = true) {
    const instance = this;

    this.svg.selectAll('.slice').each(function(d, i) {
      let node = d3.select(this).classed('selected', () => {
        return d.data.data.id === indicatorId;
      });
    });

    this.svg.selectAll('.selected').each(function(d) {
      /* move selected element the end of the parents nodes so it will
       * appear on top (z-index doesn't work in SVG)
       * */

      if (`${d.data.data.id}`.indexOf(`${instance.clickedID}`) == 0) {
        this.parentNode.appendChild(this);
      }

      // console.log('clickedDepth = ' + instance.clickedDepth);
      // console.log('data.id = ' + d.data.data.id.toString());
      // console.log('clickedID = ' + instance.clickedID.toString());
      // console.log('indexOf = ' + d.data.data.id.toString().indexOf(instance.clickedID.toString()));
      // console.log('===========================');

      /* Zoom out if selected slice is invisible */
      if (
        (instance.clickedDepth && d.data.data.id.toString().indexOf(instance.clickedID.toString()) == -1) ||
        d.data.data.id === instance.clickedID
      ) {
        instance.zoomToSlice(d, this);
      }

      // Silly assumption: treat length 4 as being level 2
      if (indicatorId.toString().length === 4) {
        instance.zoomToSlice(d, this);
      }
    });

    // let the caller know the indicator has changed
    if (emit) {
      this.callbackIndicatorChanged(indicatorId);
    }
  }

  protected doubleClicked = function(d, element) {
    if (d.depth == 2) {
      // d3.select("#indicatorTitel").text(this.titelOpmaak(d.data.data.naam));
      this.selectIndicatorId(d.data.data.id);
      this.zoomToSlice(d, element);
    }
  };

  protected zoomToSlice = (d, element) => {
    // move selected element to end of SVG so it's on top (like z-index)
    element.parentNode.appendChild(element);

    //volgende twee waarden togglen tussen inzoom en uitzoom,
    //er kan alleen maar geklikt worden op depth==2
    this.clickedDepth = !this.clickedDepth ? d.depth : 0;
    this.clickedID = !this.clickedDepth ? Utils.Instance.rootIndicator : d.data.data.id;

    // set zoomed class on root node so we can add styling rules
    this.svg.classed('zoomed', this.clickedDepth !== 0);

    //de range en domein van de afzonderlijke schalen moeten hier steeds opnieuw worden bepaald
    var binnenRange = this.clickedDepth ? [0, middleCircle] : [middleCircle, ringProps * radius];
    var buitenRange = this.clickedDepth ? [middleCircle, radius] : [ringProps * radius, radius];
    var xDomein = this.clickedDepth ? [d.x0, d.x1] : [0, 1];

    // een klein verschil in duur geeft een mooi effect
    var duur = this.clickedDepth ? [850, 1000] : [1000, 850];

    this.svg
      .selectAll('.binnenRing')
      .transition()
      .duration(duur[0])
      .tween('scale', () => {
        var xd = d3.interpolate(this.x.domain(), xDomein),
          yd = d3.interpolate(this.yBinnen.domain(), [1 / 6, 0.5]),
          yr = d3.interpolate(this.yBinnen.range(), binnenRange);
        return t => {
          this.x.domain(xd(t));
          this.yBinnen.domain(yd(t)).range(yr(t));
        };
      })
      .attrTween('d', d => {
        return () => {
          return this.arc(d);
        };
      });

    this.svg
      .selectAll('.labelBinnen')
      .transition()
      .duration(duur[0])
      .tween('scale', () => {
        var xd = d3.interpolate(this.x.domain(), xDomein),
          yd = d3.interpolate(this.yBinnen.domain(), [1 / 6, 0.5]),
          yr = d3.interpolate(this.yBinnen.range(), binnenRange);
        return t => {
          this.x.domain(xd(t));
          this.yBinnen.domain(yd(t)).range(yr(t));
        };
      })
      .attrTween('transform', d => {
        return () => {
          return 'translate(' + this.arc.centroid(d) + ') rotate(-135,0,0)';
        };
      })
      .attr('opacity', d => {
        if (!this.clickedDepth && d.depth > 0) {
          return 1;
        }
        return 0;
      });

    this.svg
      .selectAll('.buitenRing')
      .transition()
      .duration(duur[1])
      .tween('scale', () => {
        var xd = d3.interpolate(this.x.domain(), xDomein),
          yd = d3.interpolate(this.yBuiten.domain(), [0.5, 1]),
          yr = d3.interpolate(this.yBuiten.range(), buitenRange);
        return t => {
          this.x.domain(xd(t));
          this.yBuiten.domain(yd(t)).range(yr(t));
        };
      })
      .attrTween('d', d => {
        return () => {
          return this.arc(d);
        };
      });

    this.svg
      .selectAll('.labelBuiten')
      .transition()
      .duration(duur[1])
      .tween('scale', () => {
        var xd = d3.interpolate(this.x.domain(), xDomein),
          yd = d3.interpolate(this.yBuiten.domain(), [0.5, 1]),
          yr = d3.interpolate(this.yBuiten.range(), buitenRange);
        return t => {
          this.x.domain(xd(t));
          this.yBuiten.domain(yd(t)).range(yr(t));
        };
      })
      .attrTween('transform', d => {
        return () => {
          return 'translate(' + this.arc.centroid(d) + ') rotate(0,0,0)';
        };
      })
      .attr('opacity', d => {
        var isChild = d.data.data.id.toString();
        if (this.clickedDepth && isChild.indexOf(this.clickedID.toString()) == 0) {
          return 1;
        }
        return 0;
      });

    this.svg
      .transition()
      .duration(1100)
      .attr(
        'transform',
        'translate(' + width / 2 + ',' + width / 2 + '),rotate(' + (!this.clickedDepth ? 135 : 0) + ',0,0)'
      );
  };

  public constructor(
    domSelector: string,
    wijk: any,
    variant: number,
    callbackIndicatorChanged: IIndicatorChangedCallbackType,
    callbackKompasReady: IReadyCallbackType
  ) {
    this.callbackIndicatorChanged = callbackIndicatorChanged;
    const instance = this;
    // remove and create svg element
    d3.select(domSelector)
      .select('svg.wheel')
      .remove();
    this.container = d3
      .select(domSelector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .classed('wheel', true)
      // .style("visibility", "hidden")
      .call(Utils.responsivefy, 800);

    //plaatsen van de SVG
    this.svg = this.container
      .append('g')
      // translate the g container to center of svg and rotate
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + '),rotate(135,0,0)');

    const indicatorenPromise = Utils.Instance.getIndicatoren();
    const meetwaardenPromise = Utils.Instance.getMeetwaarden({
      variant,
      wijk: wijk.id,
    });

    Promise.all([indicatorenPromise, meetwaardenPromise]).then(allData => {
      const indicatoren = allData[0];
      const meetwaarden = allData[1];

      // middels d3.stratify maken we een hierarchy (boom structuur)
      const indicatorenTree = d3
        .stratify()
        .id(d => (d as any).id)
        .parentId(d => (d as any).parent_id)(indicatoren);

      /* HUUB: sum() is standaard maar levert een ander 'uitgebalanceerde'
       * graph op. in plaats daarvan een custom before + after method */
      this.root = d3
        .hierarchy(indicatorenTree, this.getChildren)
        // .sum(function(d) { return d.children ? 0 : 1; })
        .eachBefore(node => {
          return this.initialValueSetter(node, meetwaarden);
        })
        // .eachAfter(this.summ)  // is called from outside to in
        .eachAfter(this.summMeetwaarde) // is called from outside to in
        .eachBefore(this.fixEqualRadials) // is called from inside out
        .sort((a, b) => {
          let orderA = a.data.data.sort_order || a.data.data.id;
          let orderB = b.data.data.sort_order || b.data.data.id;
          // return a.data.data.naam.localeCompare(b.data.data.naam);
          return orderA - orderB;
        });

      this.slices = this.svg.selectAll('path').data(this.partition(this.root).descendants());

      const newSlice = this.slices
        .enter()
        .append('g')
        .attr('class', d => `slice ring-${d.depth}`)
        .on('click', function(d) {
          if (d.depth === 2) {
            return instance.doubleClicked(d, this);
          } else {
            return instance.selectIndicatorId(d.data.data.id);
          }
        });

      newSlice
        .append('path')
        .attr('class', d => (d.depth < 3 ? (d.depth == 2 ? 'binnenRing klikRing' : 'binnenRing') : 'buitenRing'))
        .attr('d', this.arc)
        .style('fill', d => {
          return this.getColor(d);
        });

      newSlice.append('title').text(d => this.titelOpmaak(d.data.data.naam));

      const text = newSlice
        .append('text')
        .attr('class', d => (d.depth < 3 ? 'labelBinnen' : 'labelBuiten'))
        .attr('startOffset', '50%')
        .attr('opacity', d => (d.depth > 2 || d.depth < 1 ? 0 : 1))
        // witte achtergrond op donkere achtergrond
        // .style('fill', (d) => {
        //   return Utils.brightness(d3.rgb(instance.getColor(d))) < 125 ? '#eee' : '#000';
        // })
        //labels moeten horizontaal, dus hier de labels -135
        .attr('transform', d => {
          return 'translate(' + this.arc.centroid(d) + ') rotate(-135,0,0)';
        });

      text
        .selectAll('tspan.text')
        .data(d => d.data.data.naam_split_lines.split('\n'))
        .enter()
        .append('tspan')
        .text(d => d)
        .attr('x', 0)
        .attr('y', (_, i) => -3 + i * 20);

      callbackKompasReady();
    });
  }
}
