import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');
import dimple = require('dimple');

import { BaseChart } from './basechart';
import { IDataRecord } from './basechart';
import { Axis } from './basechart';
import { IKeyValueProps } from './basechart';
import { IGraphData } from './models/graphdata';
import { IGraphModel } from './models/graphmodel';
import { Utils } from './utils';

export class IndicatorBarChart {

  protected popup: any = null;
  protected hoveredElement: any = null;
  protected svg: any = null;
  protected chart: any = null;

  protected xAxis: any = null;
  protected yAxis: any = null;

  protected aspect: number = null;
  protected minWidthSvgDrawing = 700;

  // the dimensions and margins of the graph
  protected margin = {top: 10, right: 15, bottom: 200, left: 30};
  protected dimensions = {x: 1024, y: 768};

  private wijken;

  private indicatorData;
  private indicatorId: number;
  private wijkId: number;
  private meetwaardeLookup;

  public constructor(element: Element) {
    // super(element);
  }

  public getWijkInfo(id: number) {
    let result = {};
    for (const wijk of this.wijken) {
      if ((String(wijk.id)) === String(id)) {
        result = wijk;
        break;
      }
    }
    return result;
  }

  public getColor(wijkId) {

    // find out how many color steps we have for this indicator
    const scaleNumber = this.indicatorData.categorieCnt;
    let colorScheme = [...scaleChromatic.schemeRdYlGn[scaleNumber]];
    if ((this.indicatorData as any).kleurenschema.toLowerCase().indexOf("blauw") >= 0) {
      colorScheme = [...scaleChromatic.schemeBlues[scaleNumber]];
    }

    const value = this.meetwaardeLookup[wijkId][this.indicatorId];

    let zScore = Utils.Instance.meetwaardeToZscore(value);

    if (zScore == null) {
      return "#fff";  // white for undefined values
    }

    const domain: [number, number] = [-3, 3];
    const kleur: any = d3.scaleQuantize<string>().domain(domain).range(colorScheme)(zScore);
    return kleur;
  }

  public updateData(indicatorId: number, wijkId: number) {
    for (const wijk of this.wijken) {
      this.chart.assignColor(wijk.naam, this.getColor(wijk.id));
    }
  }

  public responsivefy() {

    if (!this.svg.node()) {
      return;
    }

    // get container + svg aspect ratio
    const container = d3.select(this.svg.node().parentNode);
    const width = parseInt(this.svg.style('width'), 10);
    const height = parseInt(this.svg.style('height'), 10);
    this.aspect = width / height;

    // call resize so that this.svg resizes on inital page load
    this.resizeChart();

    // to register multiple listeners for same event type,
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you call invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on
    d3.select(window).on('resize.' + container.attr('id'), () => {
      this.resizeChart();
      // this.redrawLegend();
      // this.setupClickableLegend(this.serieName);
    });
  }

  protected resizeChart() {

    const container = d3.select(this.svg.node().parentNode);
    const targetWidth = container.node() && container.node().getBoundingClientRect().width;

    if (targetWidth && !isNaN(targetWidth)) {

      if (targetWidth < this.minWidthSvgDrawing) {
        const w = this.minWidthSvgDrawing;
        const h = Math.round(w / this.aspect);
        this.svg.attr('viewBox', `0 0 ${w} ${h}`)
        .attr('perserveAspectRatio', 'xMidYMid');

        this.svg.attr('width', w);
        this.svg.attr('height', h);

        if (this.chart) {
          this.chart.draw(0, false);
          this.fixAxisStyling();
        }
        this.svg.attr('width', targetWidth);
        this.svg.attr('height', Math.round(targetWidth / this.aspect));

      } else {
        this.svg.attr('viewBox', null)
        .attr('perserveAspectRatio', null);

        this.svg.attr('width', targetWidth);
        this.svg.attr('height', Math.round(targetWidth / this.aspect));

        if (this.chart) {
          this.chart.draw(0, false);
          this.fixAxisStyling();
        }
      }
    }
  }


  public initIndicatorBarChart(
    selectorId: string, indicatorId: number, wijkId: number, variant: number = 1) {

    const instance = this;
    const barchart = document.getElementById(selectorId);

    this.wijkId = wijkId;
    this.indicatorId = indicatorId;

    const indicatorPromise = Utils.Instance.getIndicator(indicatorId);
    const meetwaardenPromise = Utils.Instance.getWijkenMeetwaarden();
    const wijkenPromise = Utils.Instance.getWijken();

    Promise.all([meetwaardenPromise, indicatorPromise, wijkenPromise]).then((allData) => {

      this.meetwaardeLookup = allData[0];
      this.indicatorData = allData[1];
      this.wijken = allData[2];

      let graphData = new Array<{}>();

      // let minValue = 200;

      for (let wijk of this.wijken) {
        graphData.push({wijknaam: wijk.naam, waarde: this.meetwaardeLookup[wijk.id][indicatorId]});
        // if (this.meetwaardeLookup[wijk.id][indicatorId] < minValue) {
        //   minValue = this.meetwaardeLookup[wijk.id][indicatorId];
        // }
      }
      // minValue = Math.max((Math.round(minValue / 10) * 10)  - 10, 0);
      // minValue = Math.min(minValue, 100);

      // remove and recreate svg
      d3.select(`#${selectorId} svg`).remove();
      barchart.innerHTML = "";
      instance.svg = dimple.newSvg(barchart, instance.dimensions.x, instance.dimensions.y);

      instance.chart = new dimple.chart(instance.svg, graphData.slice());
      instance.chart.setMargins(
        instance.margin.left,
        instance.margin.top,
        instance.margin.right,
        instance.margin.bottom,
      );

      instance.chart.defaultColors = [
        new dimple.color("lightgray", "gray"),
      ];

      instance.xAxis = instance.chart.addCategoryAxis("x", ["wijknaam"]);
      instance.yAxis = instance.chart.addMeasureAxis("y", "waarde");

      // instance.yAxis.overrideMin = minValue;
      instance.yAxis.overrideMin = 0;
      instance.yAxis.overrideMax = 200;

      instance.yAxis.title = "";
      instance.xAxis.title = "";

      const series = instance.chart.addSeries("wijknaam", dimple.plot.bar);

      // Handle the hover event - overriding the default behaviour
      series.addEventHandler("mouseover", (e) => {
        instance.showPopup(e);
      });
      // Handle the leave event - overriding the default behaviour
      series.addEventHandler("mouseleave", (e) => {
        instance.removePopup(e);
      });

      if (barchart.getAttribute("class").indexOf("prevent-click") === -1) {
        series.addEventHandler("click", (e) => {
          for (const wijk of this.wijken) {
            if (wijk.naam === e.xValue) {
              Utils.Instance.setMemoryData("selectedWijkId", String(wijk.id));
            }
          }
        });
      }

      this.updateData(indicatorId, wijkId);

      // colorize the currently active wijk (add stroke)
      instance.chart.assignColor(
        (this.getWijkInfo(wijkId) as any).naam,
        this.getColor(wijkId),
        "black");

      this.responsivefy();

      window.setTimeout(() => {
        this.resizeChart();
      }, 0);

      //instance.chart.draw(0, false);
      //instance.fixAxisStyling();

    });
  }

  public fixAxisStyling() {

    const instance = this;

    // y as title margin a little bit to the left.
    // store current translation (rotation) and add a translate
    // let transform = yAxis.titleShape.attr('transform');
    // yAxis.titleShape.attr('transform', `${transform} translate(0, -10)`);

    // x as schuine text
    if (this.xAxis) {
      // instance.xAxis.shapes.selectAll("text")
      // .style('text-anchor', 'start');
      // .attr("transform", function(d) {
      //   return "translate(6, -5) rotate(40, 0, 0)";
      // });

      // set "active" class op geselecteerde wijk
      instance.xAxis.shapes.selectAll("text").each(function(d, i) {
        d3.select(this)
        .classed(
          "active",
          (d3.select(this).text() === (instance.getWijkInfo(instance.wijkId) as any).naam)
        );
      });
    }
  }


  protected showPopup(e) {
    /* Event to handle mouse enter
     *  TODO: this method still has too many magic hardcoded settings
     *  which should be removed or made configurable
     */

    this.hoveredElement = e.selectedShape;
    this.hoveredElement.classed('hovered', true);

    // let cx = d3.event.clientX;
    const cx = d3.mouse(d3.event.currentTarget)[0];

    // Get the properties of the selected shape
    // let cx = parseFloat(e.selectedShape.attr('x'));
    const cy = parseFloat(e.selectedShape.attr('y'));

    // Set the (initial) size and position of the popup
    let width = 150;
    const height = 45;

    let x = 0;
    let y = cy + height + 10 > this.chart.y + this.chart.height ?
      cy - height : cy;

    // Create a group for the popup objects
    this.popup = this.svg.append('g');

    // Add a rectangle surrounding the text
    const rect = this.popup
    .append('rect')
    .attr('x', x + 5)
    .attr('y', y + 30)
    .attr('width', width)
    .attr('height', height)
    .attr('rx', 5)
    .attr('ry', 5)
    .style('z-index', '1')
    .style('fill', 'white')
    .style('stroke', 'black')
    .style('stroke-width', 2);

    // Add multiple lines of text
    const text = this.popup
    .append('text')
    .attr('class', 'popupText');

    const data = this.hoveredElement.data();

    y += 45;

    text
    .append('tspan').attr('x', x + 10).attr('y', y)
    .text(e.xValue.toLocaleString())
    .style('font-family', 'sans-serif').style('font-size', 10);

    text.append('tspan').attr('x', x + 10).attr('y', y + 15)
    .text(data[0].yValue.toLocaleString())
    .style('font-family', 'sans-serif').style('font-size', 10).style('font-weight', 'bold');

    x = cx - 20;  // amount of pixels left of mouse pointer

    // maak box breedte passend aan text
    const textBBox = text.node().getBBox();
    width = textBBox.width + 10;
    rect.attr('width', width);

    // will the popup fit left of the pointer?
    x = cx - width - 20;
    if (x < 0) {
      x = cx + 20;
    }

    // rect.attr('x', x - width - 5);
    rect.attr('x', x);

    d3.selectAll('.popupText tspan')
    // .attr('x', x - width - 0);
    .attr('x', x + 5);

  }


  public showPopup2(e) {
    // Event to handle mouse enter

    // Get the properties of the selected shape
    const cx = parseFloat(e.selectedShape.attr("x"));
    const cy = parseFloat(e.selectedShape.attr("y"));

    // Set the size and position of the popup
    const width = 150;
    const height = 50;

    const x = cx + width + 10 < this.chart.x + this.chart.width ?
      cx + 10 : cx - width - 20;

    const y = cy + height + 10 > this.chart.y + this.chart.height ?
      cy - height : cy;

    // Create a group for the popup objects
    this.popup = this.svg.append('g');

    // Add a rectangle surrounding the text
    this.popup
      .append('rect')
      .attr('x', x + 5)
      .attr('y', y - 5)
      .attr('width', width)
      .attr('height', height)
      .attr('rx', 5)
      .attr('ry', 5)
      .style('fill', 'white')
      .style('stroke', 'black')
      .style('stroke-width', 2);

    // Add multiple lines of text
    this.popup
      .append('text').attr('x', x + 10).attr('y', y + 10)
      .append('tspan').attr('x', x + 10).attr('y', y + 15)
      .text('Wijk: ' + e.xValue)
      .style('font-family', 'sans-serif').style('font-size', 10)
      .append('tspan').attr('x', x + 10).attr('y', y + 30)
      .text('Index: ' + parseFloat(e.yValue).toFixed(2))
      .style('font-family', 'sans-serif').style('font-size', 10);
  }

  // Event to handle mouse exit
  protected removePopup(e) {
    // Remove the popup
    if (this.popup !== null) {
      this.popup.remove();
    }

    if (this.hoveredElement) {
      this.hoveredElement.classed('hovered', false);
    }


  }

}
