import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');

import { Utils } from './utils';

export class ColorWheel {
  private indicatoren;
  private width = 800; // width makes a difference in how much text fits in the cells
  // the responsivefy will take care of the fitting the graph in the container
  private height = this.width;
  private radius = Math.min(this.width, this.height) / 2;
  private padding = 10;
  private duration = 1000; // duration in msecs for animations
  private container = null;
  private svg = null;
  private nodes = [];
  private root = null;
  private rootNodeDomainOffset = 0.1; // for smaller size of the root circle
  private x = d3.scaleLinear().range([0, 2 * Math.PI]);
  // private y = d3.scalePow().exponent(1.0).domain([0, 1]).range([0, this.radius]);
  private y = d3
    .scaleLinear()
    .domain([this.rootNodeDomainOffset, 1])
    .range([0, this.radius]);

  private selectedNode = null;
  private visibleRootNode = null;
  private curSelectedRing = 0; // what is the currently selected ring?
  private visibleNumberOfRing = 3; // how many rings do we want to show?
  private minCircleY = 0.0;
  private maxCircleY = 1.0;

  public constructor(domSelector: string, wijkId: number, variant: number) {
    const instance = this;

    // remove and create svg element
    d3.select(domSelector + ' svg.wheel').remove();
    this.container = d3
      .select(domSelector)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .classed('wheel', true)
      .style('visibility', 'hidden')
      .call(Utils.responsivefy, 800);

    this.svg = this.container
      .append('g')
      // translate the g container to center of svg:
      .attr('transform', 'translate(' + this.width / 2 + ',' + this.height / 2 + ')');

    this.createZoomWidget(domSelector);

    const indicatorenPromise = Utils.Instance.getIndicatoren();
    const meetwaardenPromise = Utils.Instance.getMeetwaarden({ variant, wijk: wijkId });

    Promise.all([indicatorenPromise, meetwaardenPromise]).then(allData => {
      const indicatoren = allData[0];
      const meetwaarden = allData[1];

      // middels d3.stratify maken we een hierarchy (boom structuur)
      const indicatorenTree = d3
        .stratify()
        .id(d => (d as any).id)
        .parentId(d => (d as any).parent_id)(indicatoren);

      /* HUUB: sum() is standaard maar levert een ander 'uitgebalanceerde'
       * graph op. in plaats daarvan een custom before + after method */
      this.root = d3
        .hierarchy(indicatorenTree, this.getChildren)
        // .sum(function(d) { return d.children ? 0 : 1; })
        .eachBefore(node => {
          return this.initialValueSetter(node, meetwaarden);
        })
        // .eachAfter(this.summ)  // is called from outside to in
        .eachAfter(this.summMeetwaarde) // is called from outside to in
        .eachBefore(this.fixEqualRadials) // is called from inside out
        .sort((a, b) => {
          let orderA = a.data.data.sort_order || a.data.data.id;
          let orderB = b.data.data.sort_order || b.data.data.id;
          // return a.data.data.naam.localeCompare(b.data.data.naam);
          return orderA - orderB;
        });

      // this.root.sum(function(d) { return d.value ? 1 : 0; });

      this.nodes = d3
        .partition()(this.root)
        .descendants();
      this.selectedNode = this.root;
      this.zoomToElement(this.root);
      this.createSVG(this.nodes);
      this.updateVisibleLevelClasses();
    });

    // subscribe to selectedIndicator changes (e.g. from breadcrumbs)
    Utils.Instance.inMemoryData$.subscribe(data => {
      const indicator = (data as any).selectedIndicator;
      if (instance.selectedNode && indicator == instance.selectedNode.data.data.id) {
        return; // nothing has changed
      }
      // find matching node in tree
      if (instance.root) {
        instance.root.descendants().forEach((node, idx) => {
          if (node.data.data.id == indicator) {
            instance.selectIndicator(node);
          }
        });
      }
    });

    // subscribe to zoom changes
    Utils.Instance.inMemoryData$.subscribe(data => {
      const zoomToNodeId = (data as any).zoomToNodeId;
      // find matching node in tree
      if (instance.root) {
        instance.root.descendants().forEach((node, idx) => {
          if (node.data.data.id == zoomToNodeId) {
            if (this.visibleRootNode != node) {
              instance.zoomToElement(node);
            }
          }
        });
      }
    });
  }

  protected fixEqualRadials(node) {
    if (node.depth == 0) {
      node.value = 100.0;
    } else {
      node.value = node.parent.value / node.parent.children.length;
    }
  }

  protected selectRing(ring: number) {
    const instance = this;

    // d3.selectAll('.zoomout-icon').remove();

    this.curSelectedRing = ring;

    let maxY = 0;
    for (const node of this.nodes) {
      if (node.depth === ring) {
        this.minCircleY = node.y0;
      } else if (node.depth === ring + this.visibleNumberOfRing) {
        maxY = node.y1;
        break;
      } else {
        maxY = Math.max(node.y1, maxY);
      }
    }

    this.maxCircleY = maxY;
    this.y.domain([Math.max(this.minCircleY, this.rootNodeDomainOffset), this.maxCircleY]);

    // hide the outer rings
    this.svg.selectAll('.node').each(function(d, i) {
      d3.select(this)
        .classed(
          'hidden',
          d.depth > instance.curSelectedRing + instance.visibleNumberOfRing || d.depth < instance.curSelectedRing
        )
        .classed('zoomout', false);
    });

    this.svg.selectAll('.node').each(function(d, i) {
      if (d === instance.visibleRootNode && d.depth > 0) {
        d3.select(this).classed('zoomout', true);
        // .append('text')
        // .attr('class', 'zoomout-icon')
        // .text('\uf010')
        // .attr('transform', (d1) => {
        //   return 'translate(-15, 15)';
        // });
      }
    });
  }

  protected createZoomWidget(domSelector: string) {
    const instance = this;

    // cleanup old zoom widget
    d3.select(`${domSelector} .zoomwidget`).remove();

    const widget = d3
      .select(domSelector + ' .color-wheel-nav')
      .append('div')
      .attr('class', 'zoomwidget');

    const zoomIn = widget
      .append('a')
      .attr('class', 'zoomIn inactive')
      .attr('title', 'Zoom in')
      .attr('role', 'button')
      .attr('aria-label', 'Zoom in')
      .text('+');
    // .html('<i class='fas fa-plus'></i>');

    const zoomOut = widget
      .append('a')
      .attr('class', 'zoomOut inactive')
      .attr('title', 'Zoom out')
      .attr('role', 'button')
      .attr('aria-label', 'Zoom out')
      .text('-');
    // .html('<i class='fas fa-minus'></i>');

    zoomOut.on('click', function() {
      instance.zoomToElement(instance.visibleRootNode);
    });
    zoomIn.on('click', function() {
      instance.zoomToElement(instance.selectedNode);
    });
  }

  protected createSVG(nodes) {
    const instance = this;

    let paths = this.svg
      .selectAll('path')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node');

    paths.on('click', d => this.selectIndicator(d)).on('dblclick', d => this.zoomToElement(d));

    this.svg
      .selectAll('.node')
      .append('path')
      .attr('d', this.getArc())
      .attr('fill-rule', 'evenodd')
      .style('fill', d => {
        return instance.getColor(d);
      });

    // hide the outer rings
    this.svg.selectAll('.node').each(function(d, i) {
      d3.select(this).classed('hidden', instance.curSelectedRing + instance.visibleNumberOfRing < d.depth);
    });

    const textNodes = this.svg.selectAll('.node');
    textNodes.each(function(d) {
      const textNode = d3
        .select(this)
        .append('text')
        .style('fill-opacity', 1)
        .style('fill', d1 => {
          return Utils.brightness(d3.rgb(instance.getColor(d1))) < 125 ? '#eee' : '#000';
        })
        .attr('transform', d1 => {
          const rotation = instance.computeTextRotation(d1);
          return `rotate(${rotation})translate(${instance.yVal((d1 as any).y0) + instance.padding})\
          rotate(${Math.round(rotation) > 90 && Math.round(rotation) < 270 ? -180 : 0})`;
        })
        .attr('text-anchor', d1 => {
          const rotation = instance.computeTextRotation(d1);
          return Math.round(rotation) > 90 && Math.round(rotation) < 270 ? 'end' : 'start';
        });

      const arr = instance.splitLine((d as any).data.data.naam);
      for (let i = 0; i < arr.length; i++) {
        textNode
          .append('tspan')
          .attr('x', '0')
          .attr('y', () => {
            const start = 0.7;
            const lineHeight = 0.85;
            const top = arr.length * lineHeight * 0.5;
            return `${start - top + i * lineHeight}em`;
          })
          .text(d1 => {
            if ((d1 as any).parent) {
              // middenstip krijgt geen tekst
              return arr[i];
            }
          });
      }
    });
  }

  protected zoom(d) {
    const path = this.svg.selectAll('path');
    const instance = this;

    // animate the transition
    this.svg
      .transition()
      .duration(this.duration)
      .tween('scale', () => {
        const xd = d3.interpolate(this.x.domain(), [d.x0, d.x1]);
        // const yd = d3.interpolate(this.y.domain(), [d.y0 ? d.y1 : d.y0, this.maxCircleY]);
        const yd = d3.interpolate(this.y.domain(), [d.y0 ? d.y1 : this.rootNodeDomainOffset, this.maxCircleY]);
        const yr = d3.interpolate(this.y.range(), [d.y0 ? 40 : 0, this.radius]);
        return t => {
          // set the new x + y calculations for time t (between 0 and 1)
          this.x.domain(xd(t));
          this.y.domain(yd(t)).range(yr(t));
        };
      })
      .selectAll('path')
      .attrTween('d', d1 => {
        return () => {
          return this.getArc()(d1);
        };
      })
      .on('end', function(e, i) {
        d3.select(this).style('visibility', instance.isParentOf(d, e) ? null : 'hidden');
      });

    this.svg
      .selectAll('text')
      // .filter(function() {
      //   return !this.classList.contains('zoomout-icon')
      // })
      .transition()
      .duration(this.duration)
      .attrTween('text-anchor', d1 => {
        return () => {
          const rotation = this.computeTextRotation(d1);
          return Math.round(rotation) > 90 && Math.round(rotation) < 270 ? 'end' : 'start';
        };
      })
      .attrTween('transform', d1 => {
        return () => {
          const rotation = instance.computeTextRotation(d1);
          return `rotate(${rotation})translate(${instance.yVal(d1.y0) + instance.padding})\
          rotate(${Math.round(rotation) > 90 && Math.round(rotation) < 270 ? -180 : 0})`;
        };
      })
      .style('fill-opacity', e => (instance.isParentOf(d, e) ? 1 : 1e-6))
      .on('end', function(e, i) {
        d3.select(this).style('visibility', instance.isParentOf(d, e) ? null : 'hidden');
      });
  }

  protected splitLine(line: string) {
    return line.split('|');
  }

  protected getArc() {
    return (
      d3
        .arc()
        // HUUB: de 0.25 PI is extra vanwege de 1/8e draai van het kompas
        // .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, this.xVal((d as any).x0))))
        // .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, this.xVal((d as any).x1))))
        .startAngle(d => {
          if (this.visibleRootNode.children && this.visibleRootNode.children.length == 4) {
            return Math.max(0.25 * Math.PI, Math.min(2.25 * Math.PI, this.xVal((d as any).x0)));
          }
          return Math.max(0, Math.min(2 * Math.PI, this.xVal((d as any).x0)));
        })
        .endAngle(d => {
          if (this.visibleRootNode.children && this.visibleRootNode.children.length == 4) {
            return Math.max(0.25 * Math.PI, Math.min(2.25 * Math.PI, this.xVal((d as any).x1)));
          }
          return Math.max(0, Math.min(2 * Math.PI, this.xVal((d as any).x1)));
        })
        .innerRadius(d => Math.max(0, this.yVal((d as any).y0)))
        .outerRadius(d => Math.max(0, this.yVal((d as any).y1)))
    );
  }

  // initialValueSetter sets a value on the outer nodes (with no children) only
  protected initialValueSetter(node, meetwaardenArray) {
    node.value = node.children ? 0 : 1;
    node.waarde = 0;

    if (!node.children) {
      // this node is in the outer ring, so set the initial value by
      // getting the meetwaarde for this node
      for (const mw of meetwaardenArray) {
        if (mw.indicator === node.data.data.id) {
          // console.log(mw);
          node.waarde += mw.waarde;
          // we can probably break out of the loop here? only one score
          // per outer node? To even improve lookup we could remove this node
          // from the meetwaardenArray I think
          break;
        }
      }
    }
  }

  // basic function to get children from a data node
  protected getChildren(d) {
    return d.children;
  }

  // the summation function calculates the new value by adding up the values from
  // the child nodes. Note that this function should be called bottom-up
  protected summ(node) {
    let sum = node.value;
    const children = node.children;
    let i = children && children.length;
    while (--i >= 0) {
      sum += children[i].value;
    }
    node.value = sum;
  }

  // the summation function calculates the new value by adding up the values from
  // the child nodes. Note that this function should be called bottom-up
  protected summMeetwaarde(node) {
    let sum = node.waarde;
    const children = node.children;
    let i = children && children.length;
    let amountRelevantChildren = 0;
    while (--i >= 0) {
      if (node.data.data.kleurenschema === children[i].data.data.kleurenschema) {
        sum += children[i].waarde;
        // also ignore meetwaarden with 0 value.. geen meetingen?
        if (children[i].waarde !== 0) {
          amountRelevantChildren++;
        }
      }
    }

    const amountNodes = amountRelevantChildren ? amountRelevantChildren : 1;
    const value = sum / amountNodes;
    node.waarde = value;
  }

  // de x berekening is voor het aantal graden
  protected xVal(val: number) {
    let angle = this.x(val);
    if (this.visibleRootNode.children && this.visibleRootNode.children.length == 4) {
      angle += 0.25 * Math.PI; // draai het wiel 45 graden, dat leest schijnbaar prettiger
    }
    return angle;
  }

  // de y berekening is voor de afstand van midden van de cirkel naar buiten
  protected yVal(val: number) {
    return this.y(val);
  }

  protected selectIndicator(d) {
    this.selectedNode = d;
    let instance = this;

    this.updateZoomControls();

    this.svg.selectAll('.node').each(function(d1, i) {
      let node = d3.select(this).classed('selected', () => {
        return d1 === d;
      });

      /* Check if this node was selected. If so, append it to the end
       * of the parents nodes so it will appear on top and the border
       * (stroke) will not be hidden behind other elements
       * (z-index doesn't work in SVG)
       * */
      if (node.classed('selected')) {
        this.parentNode.appendChild(this);
      }
    });

    // check if root element was clicked. if so, use it's parent
    // if (d.depth === 0 || d.depth !== this.curSelectedRing) {
    Utils.Instance.setMemoryData('selectedIndicator', d.data.id);
  }

  protected updateZoomControls() {
    d3.selectAll('.zoomIn').classed('inactive', () => {
      return this.selectedNode.children == null && this.selectedNode.depth == this.curSelectedRing;
    });

    d3.selectAll('.zoomOut').classed('inactive', () => {
      return this.curSelectedRing == 0;
    });
  }

  protected updateVisibleLevelClasses() {
    // set the visibleLevel_x class on each node so we can style them
    // with css (smaller font sizes for outer rings)
    const instance = this;
    this.svg.selectAll('.node').each(function(d1, i) {
      let node = d3
        .select(this)
        .classed('visibleLevel_0', false)
        .classed('visibleLevel_1', false)
        .classed('visibleLevel_2', false)
        .classed('visibleLevel_3', false);
      const visibleLevel = d1.depth - instance.visibleRootNode.depth;
      if (0 <= visibleLevel && visibleLevel <= 3) {
        node.classed(`visibleLevel_${visibleLevel}`, true);
      }
    });
  }

  protected zoomToElement(node) {
    // check if root element was clicked. if so, use it's parent
    if (node.depth === this.curSelectedRing && node.depth > 0) {
      node = node.parent;
    }

    // if (this.visibleRootNode == node) {
    //   return; // already zoomed in
    // }

    // De diepste ingezoomd geeft wat gekigheid. Geen tijd / zin om dat nu
    // verder uit te zoeken. Dus gewoon beperken qua inzoomen. Als diepste
    // niveau is ingezoomd, dan zoomen we naar z'n parent
    if (node.depth == this.root.height) {
      node = node.parent;
    }

    this.visibleRootNode = node;

    this.selectRing(node.depth);

    // Om de juiste verdeling van de zichtbare vlakken te maken moeten we de
    // values van de zichbare buitenste ring op 1 zetten. De rest allemaal
    // op 0. Daarna de sommaties voor de binnenste vlakken weer berekenen en
    // uiteindelijk de partitionering weer doen (om de x0, x1 etc te
    // berekenen)
    this.root.eachAfter(d => {
      let val = d.depth == this.curSelectedRing + this.visibleNumberOfRing ? 1 : 0;
      d.value = val;
    });

    this.root
      // .eachAfter(this.summ)
      .eachBefore(this.fixEqualRadials);
    d3.partition()(this.root);

    this.zoom(node);
    this.updateZoomControls();
    this.updateVisibleLevelClasses();

    Utils.Instance.setMemoryData('zoomToNodeId', node.data.data.id);
  }

  protected isParentOf(p, c) {
    if (p === c) {
      return true;
    }
    if (p.children) {
      return p.children.some(d => {
        return this.isParentOf(d, c);
      });
    }
    return false;
  }

  protected computeTextRotation(d) {
    const rotation = ((this.xVal(((d as any).x0 + (d as any).x1) / 2) - Math.PI / 2) / Math.PI) * 180;
    return rotation;
  }

  protected getColor = d => {
    let zScore = Utils.Instance.meetwaardeToZscore(d.waarde);

    if (zScore == null) {
      return '#E9ECEF'; // lightgray for undefined values
    }

    const scaleNumber = d.data.data.categorieCnt;

    let colorScheme = [...scaleChromatic.schemeRdYlGn[scaleNumber]];
    // aangepaste kleuren op verzoek
    // let colorScheme = ['#EC633F', '#EEB069', '#F0E87C', '#B5F387', '#69B04D'].reverse();

    if (d.data.data.kleurenschema.toLowerCase().indexOf('blauw') >= 0) {
      colorScheme = [...scaleChromatic.schemeBlues[scaleNumber]];
    }

    const domain: [number, number] = [-3, 3];
    const kleur: any = d3
      .scaleQuantize<string>()
      .domain(domain)
      .range(colorScheme)(zScore);
    return kleur;
  };
}
