<template>
  <!-- MAIN - CONTENT (navigation + content) -->
  <div class="block block-pagecontent">
    <section>
      <!-- PAGE CONTENT: TAB MENU -->
      <div class="block block-tabs sticky">
        <ul>
          <li>
            <a v-scroll-to="'#block-map'" href="#" class="button" :class="active == '' || active == 'map' ? 'active' : ''"
              v-on:click="active = 'map'">
              De kaart
            </a>
          </li>
          <li v-if="selectedWijk">
            <a v-scroll-to="'#block-general-information'" href="#" class="button"
              :class="active == 'general-information' ? 'active' : ''" v-on:click="active = 'general-information'">
              Algemene informatie
            </a>
          </li>
          <li v-if="selectedWijk">
            <a v-scroll-to="'#block-kompas'" href="#" class="button" :class="active == 'kompas' ? 'active' : ''"
              v-on:click="active = 'kompas'">
              Het kompas
            </a>
          </li>
          <li v-if="selectedWijk">
            <a v-scroll-to="'#block-comparison-filters-header'" href="#" class="button"
              :class="active == 'comparison-filters' ? 'active' : ''" v-on:click="active = 'comparison-filters'">
              Vergelijken
            </a>
          </li>
          <li>
            <a v-scroll-to="'#block-text'" href="#" class="button" :class="active == 'text' ? 'active' : ''"
              v-on:click="active = 'text'">
              Hoe werkt het?
            </a>
          </li>
        </ul>
        <wijk-title></wijk-title>
      </div>

      <!-- PAGE CONTENT: MAP -->
      <div class="block block-map" id="block-map">
        <wijken-kaart></wijken-kaart>
        <kaart-navigatie v-on:ready="navigatieReady = true"></kaart-navigatie>
      </div>

      <!-- PAGE CONTENT: GENERAL INFORMATION -->
      <wijk-info v-if="selectedWijk"></wijk-info>

      <kompas-container v-if="selectedWijk"></kompas-container>

      <h1 class="margin-top" id="block-comparison-filters-header">Wijken vergelijken</h1>

      <kruimelpad-navigatie v-if="selectedWijk" id="kompas-kruimelpadnav" :wijk="selectedWijk" :variant="selectedVariant">
        <div class="breadcrumbs"></div>
      </kruimelpad-navigatie>

      <div id="block-comparison">

        <!-- PAGE CONTENT: NEIGHBORHOOD COMPARISON BY METRIC - SINGLE COLUMN COMPARISON -->
        <kompas-vergelijker v-if="navigatieReady && $resize && $mq.below(860)" widget="single"></kompas-vergelijker>

        <!-- PAGE CONTENT: NEIGHBORHOOD COMPARISON BY METRIC - MULTI COLUMN COMPARISON -->
        <kompas-vergelijker v-if="navigatieReady && $resize && $mq.above(860)" widget="multi"></kompas-vergelijker>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Utils } from "../../ts/utils";
import IndicatorInfo from "./IndicatorInfo";
import KaartNavigatie from "./KaartNavigatie.vue";
import KompasContainer from "./KompasContainer.vue";
import KompasVergelijker from "./KompasVergelijker.vue";
import KruimelpadNavigatie from "./KruimelpadNavigatie.vue";
import WijkInfo from './WijkInfo.vue';
import WijkTitle from "./WijkTitle.vue";
import WijkenKaart from "./WijkenKaart.vue";

export default {
  name: "KompasApp",

  components: {
    IndicatorInfo,
    KaartNavigatie,
    KompasContainer,
    KompasVergelijker,
    KruimelpadNavigatie,
    WijkenKaart,
    WijkTitle,
    WijkInfo
  },

  data() {
    return {
      label: "",
      stadsdelen: [],
      active: "",
      navigatieReady: false,
    };
  },

  mounted() {

    const urlParams = new URLSearchParams(window.location.search);
    const indicatorId = urlParams.get('indicator');
    const wijkId = urlParams.get('wijk');

    if (localStorage.getItem("navSelectedStadsdeel")) {
      const data = JSON.parse(localStorage.getItem("navSelectedStadsdeel"));
      this.setSelectedStadsdeel(data.detail.stadsdeelId);
      // localStorage.removeItem("navSelectedStadsdeel");
    }

    if (wijkId) {
      // console.log(`pre-select wijk ${wijkId}`)
      this.selectWijkAndStadsdeel(parseInt(wijkId));
    }

    if (indicatorId) {
      // console.log(`pre-select indicator ${indicatorId}`);
      this.setSelectedIndicatorId(parseInt(indicatorId));
    } else {
      // by default the root indicator is selected
      this.setSelectedIndicatorId(Utils.Instance.rootIndicator);
    }

    window.addEventListener('stadsdeelSelected', this.handleStadsdeelSelected);
    window.addEventListener('wijkSelected', this.handleWijkSelected);
  },

  methods: {
    ...mapMutations(["setSelectedIndicatorId", "setSelectedStadsdeel", "setSelectedWijk"]),
    ...mapActions(['selectWijkAndStadsdeel']),

    handleStadsdeelSelected(event) {
      // console.log(`event detail: ${JSON.stringify(event['detail'])}`);
      const stadsdeelId = event['detail']['stadsdeelId'];
      const stadsdeelName = event['detail']['stadsdeelName'];
      this.setSelectedStadsdeel({ id: stadsdeelId, naam: stadsdeelName });
      this.setSelectedWijk();
    },

    handleWijkSelected(event) {
      // console.log(`event detail: ${JSON.stringify(event['detail'])}`);
      const wijkId = event['detail']['wijkId'];
      this.selectWijkAndStadsdeel(wijkId);
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["selectedWijk", "selectedVariant"])
  },
};
</script>
<style lang="scss" scoped>
.block.block-tabs.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  padding-top: 30px;
  margin-top: 0;
  z-index: 1000;
  background-color: #fff;

  // width: calc(100% + 100px);
  h1.margin-top {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1280px) {
  .block.block-tabs.sticky {
    top: 55px;
    margin-left: -25px;
    width: calc(100% + 50px);
  }
}

@media (max-width: 800px) {
  .block.block-tabs.sticky {
    top: 50px;
    margin-left: -10px;
    width: calc(100% + 20px);
  }
}
</style>
