<template>
  <div class="social-sharing">
    <social-sharing :url="getSharingURL()" title="Basismonitor Groningen" description="Het Kompas van Groningen"
      quote="Heeeel veeeel data over stad, wijken, buurten en dorpen" hashtags="basismonitor,kompas"
      twitter-user="basisvoorbeleid" inline-template>
      <div class="social-sharing-block">
        <network network="email">
          <font-awesome-icon icon="fa-regular fa-envelope" size="2xl" />
        </network>
        <network network="facebook">
          <font-awesome-icon icon="fa-brands fa-facebook" size="2xl" />
        </network>
        <network network="linkedin">
          <font-awesome-icon icon="fa-brands fa-linkedin" size="2xl" />
        </network>
        <network network="twitter">
          <font-awesome-icon icon="fa-brands fa-x-twitter" size="2xl" />
        </network>
        <network network="whatsapp">
          <font-awesome-icon icon="fa-brands fa-whatsapp" size="2xl" />
        </network>
      </div>
    </social-sharing>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KompasSharing",

  computed: {
    ...mapGetters(["selectedWijk", "selectedIndicatorId"])
  },

  methods: {
    getSharingURL() {
      return `${this.getCurrentURL()}?indicator=${this.selectedIndicatorId}&wijk=${this.selectedWijk.id}`;
    },
    getCurrentURL() {
      return window.location.href.split('?')[0];
    }
  }
}
</script>

<style lang="scss">
.social-sharing-block {
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  margin: 0 2rem 0 5rem;
  padding: 2rem;

  span {
    cursor: pointer;
  }
}
</style>
