import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Utils} from './utils';

import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');
import L = require('leaflet');
import jQuery = require('jquery');

require('proj4leaflet');

// er is een gegeojson bestand dat als projectie OGC:CRS84 heeft
// deze projectie is niet bekend bij proj4 maar de alias 4326 wel
import * as proj4x from 'proj4';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
let proj4 = (proj4x as any).default;
proj4.defs['OGC:CRS84'] = proj4.defs['EPSG:4326'];

// merge MapOptions interface
// (https://www.typescriptlang.org/docs/handbook/declaration-merging.html)
// so we have an extra optional property gestureHandling in the MapOptions
// interface
// declare module 'leaflet' {
//   export interface MapOptions {
//     gestureHandling?: boolean;
//   }
// }

export class WijkKaart {

  protected kaart: any = null;
  protected domSelector: string = null;
  protected _colorScheme: string = null;

  protected info = new InfoPanel();
  protected activeLayer = null;
  protected curSelectedWijkId: string = null;
  protected wijkGrensGeoJson: any = null;
  protected wijkBebouwingGeoJson: any = null;
  public currentIndicator: number = null;
  protected currentIndicatorPromise = null;
  protected indicatorData = null;

  protected wijkenMeetwaarden = {};

  public featureLayerInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public constructor(domSelector: string, indicator: number = null,
                     variant = 1, colorScheme: string = null,
                     listenToWijkChanges = true, geojson = null) {

    this.domSelector = domSelector;
    this._colorScheme = colorScheme;

    let RD = Utils.getRDProjection();

    let domElement = d3.select(domSelector);

    this.kaart = L.map(domElement.attr('id'), {
      center: Utils.centerOfWorld,
      maxBounds: Utils.gemeenteBounds,
      zoomSnap: 0.1,
      zoomDelta: 0.5,
      minZoom: 5.0,
      maxZoom: 9.0,
      crs: RD,
      // gestureHandling: true,
    });

    // check if there is a hidden legend container
    let legendContainer = domElement.selectAll(".bm_legend");
    if (legendContainer.size()) {
      const legend = new L.Control({position: 'bottomright'});
      legend.onAdd = (map) => {
        let legendDiv = L.DomUtil.create('div', 'info legend');
        let node = <Element>legendContainer.node();
        node.classList.remove('hidden');
        legendDiv.appendChild(node);
        //legendDiv.appendChild(legendContainer.nodes()[0]);
        return legendDiv;
      }

      legend.addTo(this.kaart);
    }

    // const bgURL = '//geodata.nationaalgeoregister.nl/wmts/' +
    //   '?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0' +
    //   '&LAYER=bgtstandaard&TILEMATRIXSET=EPSG:28992' +
    //   '&TILEMATRIX=EPSG:28992:{z}&TILEROW={y}&TILECOL={x}&FORMAT=image/png';

    // let openbasiskaart = L.tileLayer(bgURL);
    // openbasiskaart.addTo(this.kaart);

    this.kaart.on('resize', () => {
      window.setTimeout(() => {
        this.kaart.fitBounds(Utils.gemeenteBounds, {animate: false});
      }, 0);
    });

    // fix when opened for first time
    window.setTimeout(() => {
      this.kaart.fitBounds(Utils.gemeenteBounds, {animate: false});
      window.dispatchEvent(new CustomEvent('resize'));
    }, 0);

    let body = document.getElementsByTagName('BODY')[0];
    let mediaURL = Utils.Instance.mediaURL;
    let wijkenURL = body.getAttribute('data-wijken-geojson');
    let wijkenBebouwdURL = body.getAttribute('data-wijken-bebouwd-geojson');

    this.info.addTo(this.kaart);

    if (geojson) {
      let geojsonPromise = Utils.Instance.getJsonPromise(geojson);
      geojsonPromise.then((data) => {

        let featureStyle = (feature) => {
          return {
            weight: 1,
            fillColor: feature.properties.color,
            fillOpacity: 0.8,
            className: 'geojson_feature',
          };
        };

        // let onEachFeature = (feature, layer) => {
        //   layer.on({
        //     mouseover: (e) => {
        //       return this.highlightFeature(layer, e);
        //     },
        //     mouseout: (e) => {
        //       return this.resetHighlight(layer, e);
        //     },
        //   });
        // };

        let geoJsonLayer = L.Proj.geoJson(data as Proj4GeoJSONFeature, {
          style: featureStyle,
          // onEachFeature: onEachFeature
        }).addTo(this.kaart);

      });
    } else {

      let wijkenBebouwdPromise = Utils.Instance.getJsonPromise(wijkenBebouwdURL);
      let wijkGrensPromise = Utils.Instance.getJsonPromise(wijkenURL);

      Promise.all([wijkenBebouwdPromise, wijkGrensPromise])
      .then((allData) => {
        let wijkBebouwingJson = allData[0];
        let wijkGrensJson = allData[1];
        // let onEachFeature = (feature, layer) => {

        //   layer.on({
        //     mouseover: (e) => {
        //       return this.highlightFeature(layer, e);
        //     },
        //     mouseout: (e) => {
        //       return this.resetHighlight(layer, e);
        //     },
        //   });

        //   // only add click handler if not prevented
        //   let element = document.getElementById(domSelector.substr(1));
        //   if (element.getAttribute('class').indexOf('prevent-click') === -1) {
        //     layer.on({
        //       click: this.selectWijk,
        //     });
        //   }
        // };

        this.featureLayerInitialized.subscribe((ready: boolean) => {

          if (ready) {

            let bebouwingStyle = (feature) => {
              return {
                fillColor: this.getFeatureColor(feature),
                // className: 'bebouwingfeature',
                className: 'wijkfeature',
              };
            };

            let featureStyle = (feature) => {
              return {
                fillColor: this.getFeatureColor(feature),
                className: 'wijkfeature',
              };
            };

            this.wijkGrensGeoJson = L.Proj.geoJson(wijkGrensJson as Proj4GeoJSONFeature, {
              style: featureStyle,
              // onEachFeature: onEachFeature
            }).addTo(this.kaart);

            this.wijkGrensGeoJson.bindTooltip(layer => {
              return `${layer.feature.properties.NAAM}`;
            });

            this.wijkBebouwingGeoJson = L.Proj.geoJson(wijkBebouwingJson as Proj4GeoJSONFeature, {
              style: bebouwingStyle,
              // onEachFeature: onEachFeature
            }).addTo(this.kaart);

            if (this.curSelectedWijkId) {
              this.showSelectedWijk(this.curSelectedWijkId);
            }
          }

        });

      });

    }


    if (listenToWijkChanges) {
      Utils.Instance.inMemoryData$.subscribe((data) => {
        if (data.hasOwnProperty('selectedWijkId')) {
          let newSelectedWijkId: string = data['selectedWijkId'];
          if (newSelectedWijkId) {
            if (newSelectedWijkId !== this.curSelectedWijkId) {
              this.curSelectedWijkId = newSelectedWijkId;
              this.showSelectedWijk(newSelectedWijkId);
            }
          }
        }
      });
    }

    // load the passed indicator
    if (indicator) {
      this.loadIndicator(indicator);
    }
  }


  protected getWijkIndicatorMeetwaarde = (wijkId) => {
    if (this.currentIndicator && wijkId in this.wijkenMeetwaarden) {
      return this.wijkenMeetwaarden[wijkId][this.currentIndicator];
    } else {
      return 0;
    }
  }

  protected getColorScheme = () => {
    if (!this.indicatorData) {
      return null;
    }

    // find out how many color steps we have for this indicator
    let scaleNumber = this.indicatorData.categorieCnt;

    let colorScheme = null;

    if (this.indicatorData['kleurenschema'].toLowerCase().indexOf('blauw') >= 0) {
      colorScheme = [...scaleChromatic.schemeBlues[scaleNumber]];
    } else if (this._colorScheme === 'grey') {
      colorScheme = [...scaleChromatic.schemeGreys[scaleNumber]];
    } else {
      colorScheme = [...scaleChromatic.schemeRdYlGn[scaleNumber]];
    }

    return colorScheme;
  }

  // Verdeel het domein van de waarden in x klassen en ken deze een kleur toe
  // op basis van ColorBrewer
  protected getFeatureColor = (feature) => {

    if (!this.indicatorData) {
      return '#fff';
    }

    let wijkId = parseInt(feature.properties.CODE, 10);

    // I have no idea but an array is returned.. we take the first element:
    let value = this.getWijkIndicatorMeetwaarde(wijkId)[0];

    let zScore = Utils.Instance.meetwaardeToZscore(value);

    if (zScore === null) {
      return '#E9ECEF';  // white for empty values
    }

    let colorScheme = this.getColorScheme();

    // range van zscores is van -3 tot 3
    const domain: [number, number] = [-3, 3];
    const kleur: any = d3.scaleQuantize<string>().domain(domain).range(colorScheme)(zScore);
    return kleur;
  }

  protected highlightFeature = (layer, e) => {

    if (!L.Browser.ie && !L.Browser.edge) {
      layer.bringToFront();
      if (this.activeLayer) {
        this.activeLayer.bringToFront();  // redraws the selected polygon
      }
    }
    this.info.update(layer.feature.properties);
  }

  protected resetHighlight = (layer, e) => {
    if (this.wijkGrensGeoJson) {
      this.wijkGrensGeoJson.resetStyle(e.target);
    }
    if (this.activeLayer) {
      this.activeLayer.bringToFront();  // redraws the selected polygon
    }
    this.info.update(layer.feature.properties);
  }

  public loadIndicator = (indicator: number) => {
    this.currentIndicator = indicator;
    this.currentIndicatorPromise = Utils.Instance.getIndicator(indicator);
    if (this._colorScheme !== 'grey') {
      this.getMeetwaarden();
    } else {
      this.featureLayerInitialized.next(true);
    }
  }

  protected getMeetwaarden = () => {

    let wijkenMeetwaardenPromise = Utils.Instance.getWijkenMeetwaarden();

    Promise.all([this.currentIndicatorPromise, wijkenMeetwaardenPromise]).then((allData) => {

      this.indicatorData = allData[0];
      this.wijkenMeetwaarden = allData[1];

      if (this.wijkGrensGeoJson && this.wijkBebouwingGeoJson) {
        this.wijkGrensGeoJson.eachLayer((layer) => {
          layer.setStyle({fillColor: this.getFeatureColor(layer.feature)});
        });
        this.wijkBebouwingGeoJson.eachLayer((layer) => {
          layer.setStyle({fillColor: this.getFeatureColor(layer.feature)});
        });
      } else {
        // our feature data is ready, so we can now initialize the feature
        // layers:
        this.featureLayerInitialized.next(true);
      }
    });
  }

  protected selectWijk = (e) => {
    let wijkId = e.target.feature.properties.CODE;
    Utils.Instance.setMemoryData('selectedWijkId', wijkId);

    jQuery('#collapse-kompas').collapse('show');
  };

  protected showSelectedWijk(wijkId: string) {
    // add 'active' class to selected feature, remove the class from the previous active
    let activeClassName = 'active';

    if (this.activeLayer) {
      this.activeLayer.getElement().classList.remove(activeClassName);
    }

    if (this.wijkGrensGeoJson) {
      this.wijkGrensGeoJson.eachLayer((layer) => {
        if (layer.feature.properties.CODE === wijkId) {
          let element = layer.getElement();
          if (element) {
            layer.getElement().classList.add(activeClassName);
            this.activeLayer = layer;
            this.activeLayer.bringToFront();  // redraws the selected polygon
          }
        }
      });
    }

  }

}

/* Heel eenvoudig info panel waar je de wijknaam laat zien ofzo */
let InfoPanel = L.Control.extend({
  _div: null,

  onAdd: function (map) {
    this._div = L.DomUtil.create('div', 'info p-0 m-3');
    this.update();
    return this._div;
  },

  onRemove: function (map) {
    // Nothing to do here
  },

  update: function (props) {
    if (props && 'NAAM' in props) {
      this._div.innerHTML = `<small class='p-1'>${props.NAAM}</small>`;
    } else {
      this._div.innerHTML = '';
    }
  }
});

