<template>
  <div :id="id" ref="breadnav"></div>
</template>

<script>
import { IndicatorMenu } from '../../ts/indicator_menu';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KruimelpadNavigatie',
  props: {
    id: String,
    variant: Number,
    wijk: null,
  },

  data() {
    return {
      // id: '',
      title: '',
      breadnav: null,
    };
  },

  mounted() {
    this.initKruimelpadNavigatie();
  },

  computed: {
    ...mapGetters(['selectedWijk', 'selectedIndicatorId']),
  },

  methods: {
    ...mapMutations(['setSelectedIndicatorId', 'setIndicatorStore']),

    initKruimelpadNavigatie() {
      this.breadnav = new IndicatorMenu(
        `#${this.id}`,
        this.selectedWijk.id,
        this.variant,
        this.setSelectedIndicatorId,
      );
      this.breadnav.selectIndicatorId(this.selectedIndicatorId, false);
    },
  },

  watch: {
    selectedIndicatorId() {
      this.breadnav.selectIndicatorId(this.selectedIndicatorId, false);
    },
  },
};
</script>

<style lang="scss">
.breadcrumb {
  padding: 0 20px 0 0;
  line-height: 46px;
  background-color: #ededed !important;

  a,
  a:hover {
    font-weight: 700;
    text-decoration: none;
    border-bottom: 0;
  }

  .breadcrumb-item {
    transition: all 250ms ease-in-out;

    &::before {
      padding: 0 0.5rem;
    }

    padding: 0;

    a.crumb-link {
      padding: 0 20px;
    }

    &.active {
      a.crumb-link {
        background-color: #9441a5;
        color: #fff;
        border-radius: 3px;
      }
    }
  }
}
</style>
