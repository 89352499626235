import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');
import { Utils } from './utils';

export class Legend {

  protected container: Element = null;

  public constructor(container: Element) {
    this.container = container;
  }

  public update(indicator: any) {

    // find out how many color steps we have for this indicator
    let scaleNumber = indicator.categorieCnt;
    let colorScheme = null;

    if (indicator['kleurenschema'].toLowerCase().indexOf('blauw') >= 0) {
      colorScheme = [...scaleChromatic.schemeBlues[scaleNumber]];
    } else {
      colorScheme = [...scaleChromatic.schemeRdYlGn[scaleNumber]];
    }

    // empty current container
    this.container.innerHTML = "";

    // create ordered list
    const ol = document.createElement("ol");
    this.container.appendChild(ol);

    for (let i=0; i < indicator.legendaitems.length; i++) {
      const item = indicator.legendaitems[i];

      let li = document.createElement("li");
      ol.appendChild(li);

      let legendColor = document.createElement("span");
      li.appendChild(legendColor);
      legendColor.style.backgroundColor = colorScheme[i];

      let label = document.createTextNode(item.naam);
      li.appendChild(label);
    }

  }
};
