<template>
  <div
    class="block block-general-information"
    id="block-general-information"
  >
    <div class="visual">
      <hooper :settings="hooperSettings">
        <slide
          v-for="record in wijkAfbeeldingen"
          :data-thumbx="record.scaled.url"
          :key="record.afbeelding.id"
        >
          <img :src="record.afbeelding.url" />
        </slide>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </div>
    <div class="text">
      <h2>Algemene informatie {{ wijkNaam }}</h2>
      <p v-html="wijkOmschrijving" />
    </div>
  </div>
</template>

<script>
import { Utils } from '../../ts/utils';
import { mapGetters } from 'vuex';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'WijkInfo',

  data() {
    return {
      kompassenData: '',
      wijkNaam: '',
      wijkOmschrijving: '',
      wijkAfbeeldingen: [],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
      },
    };
  },
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },

  mounted() {
    Utils.Instance.getKompassenData().then(data => {
      this.kompassenData = data;
      this.init();
    });
  },
  computed: {
    ...mapGetters(['selectedWijk']),
  },
  methods: {
    init() {
      this.getWijkInfo();
    },
    getWijkInfo() {
      let kompasData = null;
      if (this.kompassenData) {
        for (let item of this.kompassenData.items) {
          if (item.wijk.id === this.selectedWijk.id) {
            kompasData = item;
          }
        }

        if (kompasData) {
          this.wijkNaam = kompasData.title || '';
          this.wijkOmschrijving = kompasData.omschrijving;
          this.wijkAfbeeldingen = kompasData.wijkkompas_images;
        }
      }

      return kompasData;
    },
  },
  watch: {
    selectedWijk() {
      this.getWijkInfo();
    },
  },
};
</script>

<style lang="scss">
section.hooper {
  padding: 0 !important;
  height: auto;
  .hooper-list {
    .hooper-track {
      // padding-bottom: 2em;
      overflow: visible;
      .hooper-slide {
        &:before {
          content: '';
        }
        img {
          height: 100%;
        }
      }
    }
    .hooper-pagination {
      bottom: 10px;
      .hooper-indicator {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: #fff;
        &.is-active {
          background-color: #9441a5;
        }
      }
    }
  }
}
</style>
