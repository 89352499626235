<template>
  <div id="linechart-container" ref="chart"></div>
</template>

<script>
import dimple from 'dimple';

export default {
  props: {
    compareYear: undefined,
    currentYear: 0,
    compareWijkScore: undefined,
    currentWijkScore: 0,
    compareAvgScore: undefined,
    currentAvgScore: 0
  },
  data() {
    return {
      chart: null,
      svg: null,
    }
  },
  mounted() {
    this.renderChart();
  },
  computed: {
    computedScores() {
      return [this.compareWijkScore, this.currentWijkScore, this.compareAvgScore, this.currentAvgScore];
    }
  },
  methods: {
    renderChart() {

      var data = [];

      if (this.compareAvgScore) {
        data.push({ "label": "gemiddeld", "jaar": this.compareYear, "score": Math.round(this.compareAvgScore) });
      }

      if (this.currentAvgScore) {
        data.push({ "label": "gemiddeld", "jaar": this.currentYear, "score": Math.round(this.currentAvgScore) });
      }

      if (this.compareWijkScore) {
        data.push({ "label": "wijk", "jaar": this.compareYear, "score": Math.round(this.compareWijkScore) });
      }

      if (this.currentWijkScore) {
        data.push({ "label": "wijk", "jaar": this.currentYear, "score": Math.round(this.currentWijkScore) })
      }

      const container = d3.select("#linechart-container");
      const format = d3.format("d");

      container.html('');

      this.svg = dimple.newSvg("#linechart-container", "100%", "100%");

      this.chart = new dimple.chart(this.svg, data);
      this.chart.setMargins("40px", "10px", "10px", "60px");

      const x = this.chart.addCategoryAxis('x', 'jaar');
      const y = this.chart.addMeasureAxis('y', 'score');

      // Explicitly set the formatting of the axes to avoid
      // floating point numbers like 5.0, 4.0, 3.0 etc.
      this.chart.axes.forEach(function (axis) {
        axis.tickFormat = format;
      });

      y.overrideMax = Math.ceil(Math.max(...this.computedScores) * 1.1); // Increase by 10%
      y.overrideMin = Math.floor(Math.min(...this.computedScores) * 0.9); // Decrease by 10%

      const series = this.chart.addSeries("label", dimple.plot.line);
      series.lineWeight = 3;
      series.lineMarkers = true
      series.lineColor = "yellow";

      var legend = this.chart.addLegend("100%", 0, 100, 50, "right");
      legend.x = 0
      legend.y = 120
      legend.horizontalAlign = "right";

      this.chart.assignColor("wijk", "#ef9f92");
      this.chart.assignColor("gemiddeld", "#a1c0d9");

      this.chart.draw();

      y.shapes.selectAll("*").style("fill", "white");
      y.gridlineShapes.selectAll("line").style("stroke", "transparent");
    },
    destroy() {
      if (this.svg) {
        this.svg.selectAll('*').remove();
      }
    },
    refresh() {
      this.destroy();
      this.renderChart();
    }
  },
  beforeDestroy() {
    this.destroy()
  },
  watch: {
    computedScores() {
      this.refresh();
    },
  },
};
</script>

<style scoped>
.dimple-custom-axis-label {
  display: none;
}
</style>
