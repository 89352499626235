<template>
  <!-- LEGEND -->
  <div class="block block-legend" ref="legend">
    <table>
      <tr>
        <td v-for="record in legend" v-bind:key="record.level"
          :class="`level-${record.level} level${indicatorType === 'blauw' ? '-profile' : ''}-${record.level}`" :title="`${record.min.toFixed(0)} - ${record.max.toFixed(0)} (${record.label
            })`
            "></td>
      </tr>
    </table>

    <!-- Arrow container-->
    <div class="legend-arrow">
      <div class="arrow" ref="arrow" v-show="waarde">
        <i class="fa fa-long-arrow-alt-up"></i>
        <span>{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Utils } from "../../ts/utils";
export default {
  name: "IndicatorScore",
  props: {
    wijkId: Number,
    indicatorId: Number,
    selectedVariant: Number
  },

  data() {
    return {
      waarde: null,
      label: "",
      kompasLegend: null,
      legend: [],
      indicatorType: 'stoplicht',
      indicatorPromise: null,
    };
  },

  mounted() {
    this.kompasLegend = Utils.getKompasLegend();
    this.indicatorPromise = Utils.Instance.getIndicator(this.indicatorId);
    this.fetchData();
    this.getLegend();
    window.addEventListener("resize", this.handleWindowResize);
  },

  updated: function () {
    this.setPositionLegendArrow();
  },

  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  watch: {
    selectedVariant() {
      this.fetchData();
    },
    indicatorId() {
      this.indicatorPromise = Utils.Instance.getIndicator(this.indicatorId);
      this.getLegend();
      this.getScoreLabel();
      this.setPositionLegendArrow();
    },
    wijkId() {
      this.getScoreLabel();
      this.setPositionLegendArrow();
    }
  },

  methods: {
    handleWindowResize(event) {
      // we need to listen to resize since the CSS changes the font + width of the container
      // and we need to recalculate the position of the arrow

      // simple debounce pattern..
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setPositionLegendArrow();
      }, 100);
    },

    fetchData() {
      Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(
        data => {
          this.wijkMeetwaarden = data;
          this.getScoreLabel();
          this.setPositionLegendArrow();
        }
      );
    },

    getLegend() {
      this.indicatorPromise.then((indicator) => {
        if (indicator.kleurenschema.toLowerCase().indexOf('blauw') >= 0) {
          // colorScheme = Utils.bluesColors;
          this.legend = this.kompasLegend.blauw;
          this.indicatorType = 'blauw';
        } else {
          // colorScheme = Utils.redGreenColors;
          this.legend = this.kompasLegend.stoplicht;
          this.indicatorType = 'stoplicht';
        }
      });
    },

    getScoreLabel() {
      let score = this.wijkMeetwaarden[this.wijkId][this.indicatorId];
      if (score) {
        score = score[0];
      }

      this.indicatorPromise.then((indicator) => {

        let legend = this.kompasLegend.stoplicht;

        if (indicator.kleurenschema.toLowerCase().indexOf('blauw') >= 0) {
          legend = this.kompasLegend.blauw;
        }

        for (let record of legend) {
          if (score >= record.min && score <= record.max) {
            // this.label = `${record.label} (${score.toFixed(1)})`;
            this.label = record.label;
            break;
          }
        }
      });
    },

    getScoreToRatio(score) {
      // Marcel's score to ratio converter
      let ratio = 0;
      if (score < 40) {
        ratio = 0;
      } else if (score < 67) {
        ratio = ((score - 40) / (67 - 40)) * 0.0495;
      } else if (score < 87) {
        ratio = 0.0495 + ((score - 67) / (87 - 67)) * (0.2578 - 0.0495);
      } else if (score < 95) {
        ratio = 0.2578 + ((score - 87) / (95 - 87)) * (0.4013 - 0.2578);
      } else if (score < 105) {
        ratio = 0.4013 + ((score - 95) / (105 - 95)) * (0.5987 - 0.4013);
      } else if (score < 113) {
        ratio = 0.5987 + ((score - 105) / (113 - 105)) * (0.7422 - 0.5987);
      } else if (score < 133) {
        ratio = 0.7422 + ((score - 113) / (133 - 113)) * (0.9505 - 0.7422);
      } else if (score <= 160) {
        ratio = 0.9505 + ((score - 133) / (160 - 133)) * (1 - 0.9505);
      } else if (score > 160) {
        ratio = 1;
      }
      return ratio;
    },

    setPositionLegendArrow() {
      // calculate the arrow in the legend for the current selected (and shown) wijk
      if (this.wijkMeetwaarden) {
        const arrowEl = this.$refs.arrow;
        const arrowWidth = arrowEl.clientWidth;

        const legendEl = this.$refs.legend;
        const legendWidth = legendEl.clientWidth;

        this.waarde = this.wijkMeetwaarden[this.wijkId][this.indicatorId][0];

        const arrowPosition =
          this.getScoreToRatio(this.waarde) * legendWidth - arrowWidth * 0.5;

        arrowEl.style.left = `${arrowPosition}px`;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
