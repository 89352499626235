import { SvgBasis } from "./SvgBasis.class";

class PieWidget extends SvgBasis {

  constructor(elementId,val) {

    super(elementId,'');

    this.topMargin=0;
    this.rightMargin=0;
    this.bottomMargin=0;
    this.leftMargin=0;

    this.innerWidth   = this.width  - this.leftMargin - this.rightMargin;
    this.innerHeight   = this.height - this.topMargin  - this.bottomMargin;

    this.value         = val;
    this.pieMargin       = 0;
    this.pieWidth      = Math.min(this.innerWidth,this.innerHeight);
    this.pieHeight      = this.pieWidth;
    this.radius       = this.pieWidth / 2 - this.pieMargin;
    this.valueSuffix    = "%";
    this.pieColor      = [this.valueColor,this.nonValueColor];
    this.animateTime    = 2000;
  }

  draw(){

    super.draw();


    let val = this.value,
      text1InterpolatorValue = 0,
      suffix = this.valueSuffix;

    // An arc function with all values bound except the endAngle. So, to compute an
    // SVG path string for a given angle, we pass an object with an endAngle
    // property to the `arc` function, and it will return the corresponding string.
    var arc = d3.arc()
      .innerRadius(this.radius/2)
      .outerRadius(this.radius)
      .startAngle(0);

    var g = this.g.append('g')
          .attr("transform", "translate(" + this.innerWidth/2 + "," + this.innerHeight/2 + ")");


    g.append("path")
      .datum({endAngle: 2*Math.PI})
      .style("fill", this.pieColor[1])
      .attr("d", arc);

    g.append("path")
      .datum({endAngle: 0})
      .style("fill", this.pieColor[0])
      .transition()
        .ease(d3.easeBounce)
        .duration(this.animateTime)
        .attrTween("d", arcTween((2 * Math.PI * val)/100));

    var text1 = g.append('text')
      .attr("fill","#178BCA")
      .attr("text-anchor", "middle")
      .style("font-size",this.innerHeight/6+"px")
      .style("font-weight","bold")
      .style("font-family","sans-serif")
      .style("pointer-events","none")
      .attr('transform', 'translate(0,'+this.innerHeight/12+')')
      .text(0+suffix);

    var prefix = this.prefix;

    text1.transition()
    .duration(3*this.animateTime/4)
    .ease(d3.easeBounce)
    .tween("text", function(d) {
      var i = d3.interpolate(0, val);
      return function(t) {
      text1.text(Math.round(i(t))+suffix);
      };
    });

    // Returns a tween for a transition’s "d" attribute, transitioning any selected
    // arcs from their current angle to the specified new angle.
    function arcTween(newAngle) {

      return function(d) {
      var interpolate = d3.interpolate(d.endAngle, newAngle);
      return function(t) {
        d.endAngle = interpolate(t);
        return arc(d);
      };
      };
    }

  }
}

export default PieWidget;
