<template>
  <div v-if="indicator.eenheid">
    <p v-if="selectedVariant === 1"><strong>Score:</strong>
      <span v-if="waarde">{{ waarde.toLocaleString('nl', { maximumFractionDigits: 2 }) }}</span>
      <span v-if="!waarde">-</span>
    </p>
    <indicator-comparison v-if="showComparison" :compareYear=compareYear :currentYear=currentYear
      :compareWijkScore="rawCompareScore" :currentWijkScore="rawScore" :compareAvgScore=rawAvgCompareScore
      :currentAvgScore=rawAvgScore>
    </indicator-comparison>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { Utils } from '../../ts/utils';
import config from '../constants/config';
import IndicatorComparison from "./IndicatorComparison.vue";

export default {
  name: 'SelectedMeetwaarde',

  props: {
    wijkId: Number,
    indicatorId: Number,
    selectedVariant: Number,
  },

  data() {
    return {
      indicator: {},
      wijkMeetwaarden: null,
      waarde: '',
      rawScore: 0,
      rawCompareScore: 0,
      rawAvgScore: 0,
      rawAvgCompareScore: 0,
      compareYear: config.measuredValueCompareYear,
      currentYear: config.measuredValueCurrentYear,
    };
  },

  components: {
    IndicatorComparison
  },
  mounted() {
    this.fetchData();
  },

  watch: {
    indicatorId() {
      this.getWaardeLabel();
    },
    wijkId() {
      this.getWaardeLabel();
    }
  },

  methods: {
    ...mapMutations(['setIndicatorStore']),

    fetchData() {
      Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
        this.wijkMeetwaarden = data;
      });
    },

    getWaardeLabel() {
      Utils.Instance.getIndicator(this.indicatorId).then(value => {
        this.indicator = value;
        this.setIndicatorStore(value);

        // categorie is eigenlijk de daadwerkelijk waarde (pff)...
        // array volgorde: [
        //   waarde,
        //   categorie,
        //   schermwaarde,
        //   vergelijk categorie,
        //   gemiddelde categorie,
        //   gemiddelde vergelijk categorie
        // ]

        const waarden = this.wijkMeetwaarden[this.wijkId][this.indicatorId]

        this.waarde = waarden[2];

        const multiplier = String(this.waarde).includes("%") ? 100 : 1;

        this.rawScore = waarden[1] * multiplier;

        if (waarden[3] > 0) {
          this.rawCompareScore = waarden[3] * multiplier;
        } else {
          this.rawCompareScore = 0;
        }

        if (waarden[4] > 0) {
          this.rawAvgScore = waarden[4] * multiplier;
        } else {
          this.rawAvgScore = 0;
        }

        if (waarden[5] > 0) {
          this.rawAvgCompareScore = waarden[5] * multiplier;
        } else {
          this.rawAvgCompareScore = 0;
        }
        // console.log(`${this.waarde} / ${this.rawScore} / ${this.rawCompareScore}`)
      });
    },
  },
  computed: {
    showComparison() {
      const bothZero = this.rawCompareScore === 0 && this.rawScore === 0;

      const show = !bothZero &&
        !isNaN(this.rawCompareScore) &&
        !isNaN(this.rawScore) &&
        (this.rawCompareScore >= 0 || this.rawScore >= 0);

      return show;
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    formatNumber(value) {
      if (value) {
        return value.toFixed(0);
      }
    },
  },
};
</script>
