<template>
  <div class="map-navigation">
    <!-- Sub 'Navigation' (woonwijken) -->
    <div class="map-navigation-neighborhoods" :class="{ active: selectedStadsdeel === stadsdeel && !selectedWijk }"
      v-for="stadsdeel in stadsdelen" v-bind:key="stadsdeel.id">
      <button type="button" class="button-map-neighborhood" v-for="wijk in stadsdeel.wijk_set" v-bind:key="wijk.id"
        @click="selectWijkAndStadsdeel(wijk.id)">
        {{ wijk.naam }}
      </button>
    </div>
    <!-- Sub-sub 'Navigation' (woonwijken metrics) -->
    <div class="map-navigation-metrics" :class="{
      active: selectedWijk && selectedWijk.id === kompasData.wijk.id,
    }" v-for="kompasData in kompassenData" v-bind:key="kompasData.id">
      <div id="map-navigation-metrics-close" class="header" :class="`${kompasData.wijk.stadsdeel.slug.replace('-', '')}`">
        <span>{{ kompasData.title }}</span>
        <button type="button" class="button button-map-metrics-close icon-only transparent"
          @click="setSelectedWijk(null)">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="body">
        <indicator-score :wijk-id="kompasData.wijk.id" :indicator-id="rootIndicator"></indicator-score>

        <!-- ADDITIONAL INFORMATION -->

        <div class="block block-description" v-html="kompasData.omschrijving"></div>
      </div>
      <!-- Legend footer -->
      <div class="footer">
        <a href="#" v-scroll-to="'#block-kompas'" role="button" class="map-button">
          Klik hier voor meer informatie en het kompas
        </a>
      </div>
    </div>
    <!-- Sub-sub 'Navigation' (search) -->
    <div id="map-navigation-search" class="map-navigation-search" :class="{ active: showPostcodeSearch }">
      <div id="map-navigation-search-close" class="header">
        <span>Postcode</span>
        <button type="button" class="button button-map-search-close icon-only transparent"
          @click="showPostcodeSearch = false">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="body">
        <!-- SEARCH FORM -->
        <div class="block block-search-form">
          <div class="subheader">
            Vind een wijk met behulp van een postcode:
          </div>
          <div class="formitem">
            <input type="text" id="tbZipCode" name="tbZipCode" class="reversed no-radius no-shadow" placeholder="Postcode"
              v-mask="'#### AA'" v-model="postcode" />
          </div>
        </div>
        <!-- SEARCH RESULTS -->
        <div id="searchResultsList" class="block block-search-results">
          <div class="subheader">
            Gevonden straten / wijken:
            <button type="button" id="btnMapSearchReset" class="button link-button" @click="resetPostcodeZoeken()">
              <i class="fa fa-redo-alt"></i>
              opnieuw zoeken
            </button>
          </div>
          <div class="formitem" v-for="pc of foundPostcodes" v-bind:key="pc.id">
            <input type="radio" :id="`selectWijk_${pc.id}`" name="cbMapSearchResult" class="rounded"
              @click="makeSelection(pc.kompaswijkcode)" />
            <label :for="`selectWijk_${pc.id}`">{{ pc.straatnaam }}, {{ getWijk(pc.kompaswijkcode).naam }}</label>
          </div>
        </div>
      </div>
      <!-- Search footer -->
      <div class="footer">
        <a href="#" v-scroll-to="'#block-kompas'" role="button" class="map-button" :class="{ disabled: !selectedWijk }">
          Klik hier voor meer informatie en het kompas
        </a>
      </div>
    </div>
    <!-- Main 'Navigation' (stadsdelen) -->
    <div class="map-navigation-buttons">
      <button type="button" class="button-map-navigation" @click="selectStadsdeel(null)">
        <span>Totaal</span>
      </button>

      <button v-for="stadsdeel in filteredStadsdelen" v-bind:key="stadsdeel.id" type="button" :class="`button-map-navigation ${stadsdeel.slug.replace('-', '')}
                ${getMapNavigationActiveClass(stadsdeel)}`
        " @click="selectStadsdeel(stadsdeel)">
        <span>{{ stadsdeel.naam }}</span>
      </button>

      <button type="button" class="button-map-navigation" @click="toggleShowZoekOpStraat()">
        <span>Zoek op postcode</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Utils } from '../../ts/utils';
import IndicatorScore from './IndicatorScore.vue';

export default {
  name: 'KaartNavigatie',
  directives: { mask },
  components: {
    IndicatorScore,
  },
  data() {
    return {
      stadsdelen: [],
      postcode: null,
      foundPostcodes: [],
      kompassenData: [],
      showPostcodeSearch: false,
      wijken: {},
      ready: false,
    };
  },
  mounted() {
    this.rootIndicator = Utils.Instance.rootIndicator;
    this.kompasLegend = Utils.getKompasLegend();
    Utils.instance.getStadsdelen().then(data => {
      this.stadsdelen = data;

      // DUMMY FOR TESTING ONLY
      // this.selectWijkAndStadsdeel(14); // de hoogte
    });
    Utils.Instance.getKompassenData().then(data => {
      this.kompassenData = data.items;
    });

    Utils.instance.getWijken().then(data => {
      this.wijken = data;
    });
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['selectedWijk', 'selectedStadsdeel']),
    filteredStadsdelen() {
      const excludedId = 0;
      return this.stadsdelen.filter(stadsdeel => stadsdeel.id !== excludedId);
    }
  },

  watch: {
    postcode() {
      this.zoekWijkenOpPostcode();
    },

    kompassenData() {
      this.emitWhenReady();
    },

    wijken() {
      this.emitWhenReady();
    }
  },

  methods: {
    ...mapMutations(['setSelectedWijk', 'setSelectedStadsdeel']),
    ...mapActions(['selectWijkAndStadsdeel']),

    emitWhenReady() {
      if (!this.ready && this.kompassenData && this.wijken) {
        this.ready = true;
        this.$emit('ready');
      }
    },

    makeSelection(kompaswijkcode) {
      this.selectWijkAndStadsdeel(kompaswijkcode);
      this.showPostcodeSearch = false;
    },
    resetPostcodeZoeken() {
      this.postcode = null;
      this.setSelectedStadsdeel(null);
      this.setSelectedWijk(null);
    },
    toggleShowZoekOpStraat() {
      this.setSelectedStadsdeel(null);
      this.setSelectedWijk(null);
      this.showPostcodeSearch = !this.showPostcodeSearch;
    },
    zoekWijkenOpPostcode() {
      this.foundPostcodes = [];
      let pc = this.postcode ? this.postcode.replace(' ', '') : null;
      if (pc && pc.length === 6) {
        // postcode is enige verplcihte veld voor zoeken
        // const museumListUrl = `${process.env.VUE_APP_API_URL}museum/`;
        let url = `/postcode-rest/postcode/?postcode=${pc}`;
        axios.get(url).then(response => {

          // waarschijnlijk meerdere resultaten gevonden, dus filter
          // op unieke wijken, want niet interessant om 2 of meer keer dezelfde te tonen
          let foundPCs = {};
          for (const pc of response.data.results) {
            if (!(pc.postcode in foundPCs)) {
              foundPCs[pc.postcode] = true;
              this.foundPostcodes.push(pc);
            }
          }
        });
      }
    },
    getWijk(wijkId) {
      for (const wijk of this.wijken) {
        if (wijkId === wijk.id) {
          return wijk;
        }
      }
    },
    selectStadsdeel(stadsdeel) {
      this.setSelectedWijk(null); // reset selected wijk
      this.showPostcodeSearch = null;
      if (stadsdeel === this.selectedStadsdeel) {
        this.setSelectedStadsdeel(null); // toggle selection off
      } else {
        this.setSelectedStadsdeel(stadsdeel);
      }
    },
    getMapNavigationActiveClass(stadsdeel) {
      if (!this.selectedStadsdeel) {
        return 'active';
      }
      return this.selectedStadsdeel === stadsdeel ? 'active' : 'inactive';
    },
  },
};
</script>

<style scoped>
.block-description {
  overflow-y: auto;
}

.map-navigation-neighborhoods {
  overflow-y: auto !important;
}

.map-navigation {
  z-index: 400;
  /* higher than leaflet */
}
</style>
