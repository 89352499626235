/* Grouped Vertical Barchart
 *
 * The CSV data should have:
 * - category (y axis label) is in 1st column
 * - series ('group by') in 2nd column
 * - data (x axis value) in the 3rd column
 */
import d3 = require('d3');
import dimple = require('dimple');

import { BaseChart } from './basechart';
import { IDataRecord } from './basechart';
import { Axis } from './basechart';
import { IKeyValueProps } from './basechart';
import { IGraphData } from './models/graphdata';
import { IGraphModel } from './models/graphmodel';
import { Utils } from './utils';

export class GroupedBarChart extends BaseChart {

  public constructor(element: Element) {
    super(element);
  }

  public drawScene(graphModel: IGraphModel, data: IGraphData) {

    super.drawScene(graphModel, data);
    let instance = this;

    let categoryName = this.fieldNames[0];
    let groupByName = this.fieldNames[1];
    let valueName = this.fieldNames[2];

    const xLabels = [];  // used for sorting

    // process the data records
    this.seriesData.forEach((d) => {
      xLabels.push(d[categoryName]);
    });

    this.xAxis = this.chart.addCategoryAxis('x', [categoryName, groupByName]);
    this.yAxis = this.chart.addMeasureAxis('y', valueName);

    this.xAxis.addGroupOrderRule(groupByName, false);
    this.xAxis.addOrderRule(xLabels, false);

    let series = this.chart.addSeries(groupByName, dimple.plot.bar);
    series.addOrderRule(groupByName, false);

    this.setupAxis(Axis.y, valueName);

    series.addEventHandler('mouseover', (e) => { instance.showPopup(e); });
    series.addEventHandler('mouseleave', (e) => { instance.removePopup(e); });

    this.chart.draw();
    this.fixAxisStyling();

    this.redrawLegend();
    this.setupClickableLegend(groupByName);
    this.resizeChart();

    return this.chart;
  }

  protected showPopup(e) {
    // Event to handle mouse enter
    //

    if (this.popup !== null) {
      return;  // popup is already showing..
    }

    // Set the size and position of the popup
    let barWidth = e.selectedShape.node().getBBox().width;
    let x = e.selectedShape.node().getBBox().x + barWidth + 5;
    let y = e.selectedShape.node().getBBox().y;

    let width = 100
    let height = 60;

    let chartX = this.chart.svg.node().getBoundingClientRect().x;
    let chartY = this.chart.svg.node().getBoundingClientRect().y;
    let chartWidth = this.chart.svg.node().getBoundingClientRect().width;
    let chartHeight = this.chart.svg.node().getBoundingClientRect().height;

    if (y + 60 > chartHeight) {
      y  = chartHeight - 60;
    }

    // Create a group for the popup objects
    this.popup = this.svg.append('g')
    .attr("pointer-events", "none");

    // Add a rectangle surrounding the text
    let rect = this.popup
    .append('rect')
    .attr('x', x + 5)
    .attr('y', y - 5)
    .attr('width', width)
    .attr('height', height)
    .attr('rx', 5)
    .attr('ry', 5)
    .style('fill', 'white')
    .style('stroke', 'black')
    .style('stroke-width', 2);

    // Add multiple lines of text
    let text = this.popup
    .append('text');

    text
    .append('tspan').attr('x', x + 10).attr('y', y + 15)
    .text(e.xValue)
    .style('font-family', 'sans-serif').style('font-size', 10)
    .append('tspan').attr('x', x + 10).attr('y', y + 30)
    .text(`${this.fieldNames[1]}: ${e.seriesValue[0]}`)
    .style('font-family', 'sans-serif').style('font-size', 10)
    .append('tspan').attr('x', x + 10).attr('y', y + 45)
    .text(`${this.fieldNames[2]}: ${e.yValue.toLocaleString()}`)
    .style('font-family', 'sans-serif').style('font-size', 10);

    // maak box breedte passend aan text
    let textBBox = text.node().getBBox();
    width = textBBox.width + 20;
    rect.attr('width', width);

    if (width + x > chartWidth) {
      x -= (width + barWidth + 8);
      x = Math.max(20, x);
    }

    rect.attr('x', x);
    text.selectAll('tspan').attr('x', x + 10);
  }

  // Event to handle mouse exit
  protected removePopup(e) {
    // Remove the popup
    if (this.popup !== null) {
      this.popup.remove();
      this.popup = null;
    }
  }
}
