import d3 = require('d3');
import { Utils } from './utils';
import { dom } from '@fortawesome/fontawesome-svg-core';

interface IIndicatorChangedCallbackType {
  (indicatorId: number): void;
}

export class IndicatorMenu {
  private indicatoren;
  private container = null;
  private root = null;
  private selectedNode = null;
  private breadcrumbsElement = null;
  private callbackIndicatorChanged: IIndicatorChangedCallbackType = x => {
    return;
  };

  public constructor(
    domSelector: string,
    wijkId: number,
    variant: number,
    callbackIndicatorChanged: IIndicatorChangedCallbackType
  ) {
    const instance = this;

    instance.callbackIndicatorChanged = callbackIndicatorChanged;

    d3.select(domSelector + ' .breadcrumbs').remove();
    this.breadcrumbsElement = d3
      .select(domSelector)
      .append('nav')
      .attr('class', 'breadcrumbs')
      .attr('aria-label', 'breadcrumb');

    const indicatorenPromise = Utils.Instance.getIndicatoren();
    const meetwaardenPromise = Utils.Instance.getMeetwaarden({ variant, wijk: wijkId });

    Promise.all([indicatorenPromise, meetwaardenPromise]).then(allData => {
      const indicatoren = allData[0];
      // const meetwaarden = allData[1];

      // middels d3.stratify maken we een hierarchy (boom structuur)
      const indicatorenTree = d3
        .stratify()
        .id(d => (d as any).id)
        .parentId(d => (d as any).parent_id)(indicatoren);

      this.root = d3.hierarchy(indicatorenTree, this.getChildren).sort((a, b) => {
        let orderA = a.data.data.sort_order || a.data.data.id;
        let orderB = b.data.data.sort_order || b.data.data.id;
        return orderA - orderB;
      });

      this.selectedNode = this.root;
      this.populateBreadcrumbs();
    });
    this.initSelectIndicator();
  }

  protected initSelectIndicator() {
    const instance = this;
    Utils.Instance.inMemoryData$.subscribe(data => {
      if (data.hasOwnProperty('selectedIndicator')) {
        const indicator = (data as any).selectedIndicator;
        // find matching node in tree
        if (instance.root) {
          instance.root.descendants().forEach((val, idx) => {
            if (val.data.data.id == indicator) {
              instance.selectedNode = val;
              instance.populateBreadcrumbs();
            }
          });
        }
      }
    });
  }

  // basic function to get children from a data node
  protected getChildren(d) {
    return d.children;
  }

  protected populateBreadcrumbs() {
    this.breadcrumbsElement.node().innerHTML = '';

    // don't show breadcrumbs if root is selected
    // if (this.selectedNode == this.root) {
    //   return;
    // }

    let ol = document.createElement('ol');
    ol.className = 'breadcrumb';
    this.breadcrumbsElement.node().appendChild(ol);

    let node = this.selectedNode;
    let instance = this;

    while (node) {
      let crumb = document.createElement('li');
      crumb.className = 'breadcrumb-item dropdown';
      ol.insertBefore(crumb, ol.firstChild);

      if (node === this.selectedNode) {
        crumb.classList.add('active');
        crumb.setAttribute('aria-current', 'step');
      }

      let a = document.createElement('a');
      a.innerHTML = node.data.data.naam.replace(/\|/g, ' ');
      a.classList.add('crumb-link');
      a.setAttribute('href', '#');
      crumb.appendChild(a);

      // find siblings: first get the parent, then it's children
      const parent = node.parent;
      if (parent) {
        a.classList.add('dropdown-toggle');
        a.setAttribute('id', `dropdown-${node.data.data.id}`);
        a.setAttribute('data-toggle', 'dropdown');
        a.setAttribute('aria-haspopup', 'true');
        a.setAttribute('aria-expanded', 'false');

        let div = document.createElement('div');
        div.classList.add('dropdown-menu');
        div.setAttribute('aria-labelledby', `dropdown-${node.data.data.id}`);
        crumb.appendChild(div);

        // move active dropdown item to top of the dropdown list
        // while keeping the rest pf the order in tact
        let children = node.parent.children.slice();
        const activeIdx = children.findIndex(item => {
          return item.data.data.id == node.data.data.id;
        });
        if (activeIdx >= 0) {
          let elem = children.splice(activeIdx, 1)[0];
          children.unshift(elem); // move to start of array
        }

        children.forEach(child => {
          let dropdownItem = document.createElement('a');
          dropdownItem.classList.add('dropdown-item');
          dropdownItem.setAttribute('href', '#');
          dropdownItem.innerHTML = child.data.data.naam.replace(/\|/g, ' ');
          div.appendChild(dropdownItem);

          d3.select(dropdownItem).on('click', function(e) {
            d3.event.preventDefault();
            instance.selectIndicator(child);
          });
        });
      } else {
        // handle click on breadcrumb for root node
        (function(node) {
          d3.select(a).on('click', function(e) {
            d3.event.preventDefault();
            instance.selectIndicator(node);
          });
        })(node);
      }

      node = node.parent;
    }

    // We willen ook children kunnen kiezen van huidige node. Dus
    // voorbij het kruimelpad om zo ook dieper te kunnen kiezen
    if (this.selectedNode.children) {
      let crumb = document.createElement('li');
      crumb.className = 'breadcrumb-item dropdown';
      ol.appendChild(crumb);
      crumb.classList.add('deeperanddown');

      let a = document.createElement('a');
      a.innerHTML = '...';
      a.classList.add('crumb-link');
      a.setAttribute('href', '#');
      crumb.appendChild(a);

      a.classList.add('dropdown-toggle');
      a.setAttribute('id', `dropdown-${this.selectedNode.data.data.id}`);
      a.setAttribute('data-toggle', 'dropdown');
      a.setAttribute('aria-haspopup', 'true');
      a.setAttribute('aria-expanded', 'false');

      let div = document.createElement('div');
      div.classList.add('dropdown-menu');
      div.setAttribute('aria-labelledby', `dropdown-${this.selectedNode.data.data.id}`);
      crumb.appendChild(div);

      this.selectedNode.children.forEach(child => {
        let dropdownItem = document.createElement('a');
        dropdownItem.classList.add('dropdown-item');
        dropdownItem.setAttribute('href', '#');
        dropdownItem.innerHTML = child.data.data.naam.replace(/\|/g, ' ');
        div.appendChild(dropdownItem);

        d3.select(dropdownItem).on('click', function(e) {
          d3.event.preventDefault();
          instance.selectIndicator(child);
        });
      });
    }
  }

  protected selectIndicatorId(id, emit = true) {
    Utils.Instance.setMemoryData('selectedIndicator', id);

    // let the caller know the indicator has changed
    if (emit) {
      this.callbackIndicatorChanged(id);
    }
  }

  protected selectIndicator(d, emit = true) {
    this.selectedNode = d;
    Utils.Instance.setMemoryData('selectedIndicator', d.data.id);

    // let the caller know the indicator has changed
    if (emit) {
      this.callbackIndicatorChanged(d.data.id);
    }
  }
}
