var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"webmap"},[_c('div',{ref:"mapcontainer",staticClass:"wijkmap map"},[_c('l-map',{ref:"map",attrs:{"zoom":_vm.zoom,"minZoom":_vm.mapConfig.minZoom,"maxZoom":_vm.mapConfig.maxZoom,"bounds":_vm.bounds,"options":{
      zoomSnap: 0.1,
      zoomDelta: 0.1,
      zoomControl: _vm.mapConfig.zoomControl,
      attributionControl: _vm.mapConfig.attributionControl,
      gestureHandling: false,
      scrollWheelZoom: false
    },"crs":_vm.mapConfig.RD},on:{"leaflet:load":_vm.initMap}},[_c('l-control-attribution',{attrs:{"position":"bottomright","prefix":"&copy; <a href='https://pythonunited.com'>Python United</a>"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }