// polyfill the global environment
// import customEventPolyfill = require('custom-event-polyfill');

import promise = require('es6-promise');

promise.polyfill();
import 'classlist-polyfill';
// deze 2 imports waren voor core-js v2
// import 'core-js/es6/array';
// import 'core-js/es7/array';
import 'core-js/stable';
//import 'core-js/es/array';
import 'custom-event-polyfill';
require('es6-object-assign/auto');
//import 'core-js/features/url-search-params';

import 'whatwg-fetch';

import 'bootstrap';  // enable all bootstrap JS
import './rxjs-operators';
import jQuery = require('jquery');

import {BasisMonitorApp} from './app';
import {GroupedBarChart} from './grouped_barchart';
import {HorizontalBarChart} from './horizontal_barchart';
import {VerticalBarChart} from './vertical_barchart';
import {IndicatorMenu} from './indicator_menu';
import {IndicatorBarChart} from './indicatorbarchart';
import {LineChart} from './linechart';
import {PieChart} from './piechart';
import {Utils} from './utils';
import {ColorWheel} from './wheel';
import {WijkContent} from './wijkcontent';
import {WijkIndicatorTekst} from './wijkindicatortekst';
import {WijkKaart} from './wijkkaart';
import {WijkSelector} from './wijkselector';
import {StadsdeelSelector} from './stadsdeelselector';
import {LegendGauge} from './legend_gauge';

import '../sass/main.scss';
import {IndicatorInfo} from "./indicatorinfo";
import bootstrapVue from '../vue';

const app = new BasisMonitorApp();

/**
 * some ugly styling fixes that could only be fixed by scripting
 */
const initStylingFixes = () => {
  /* Deze fix is voor portrait mode op android tablet. Het achtergrond plaatje
   * loopt anders door onder de banners
   */
  const setMaxHeight = () => {
    const viewportHeight = window.innerHeight;
    const elements = document.getElementsByClassName('page-intro') as HTMLCollectionOf<HTMLElement>;
    for (const element of Array.from(elements)) {
      element.style.maxHeight = `${viewportHeight}px`;
    }
  }
  setMaxHeight();
  window.addEventListener('resize', () => {
    setMaxHeight();
  });
}

const initMegaDropdowKompasLinkClicks = (selector) => {
  document.body.addEventListener('wijkSelected', (event) => {
    const wijkId = event['detail']['wijkId'];
    if (wijkId) {
      sessionStorage.setItem('selectedWijkId', wijkId);
    }
  });

  document.body.addEventListener('stadsdeelSelected', (event) => {
    const stadsdeelId = event['detail']['stadsdeelId'];
    if (stadsdeelId) {
      sessionStorage.setItem('selectedStadsdeelId', stadsdeelId);
    }
  });
  // const ul = document.getElementById("kompas-menu-list");
  // if (ul) {
  //   ul.addEventListener("click", (e) => {
  //     const stadsdeelId = (e.target as HTMLElement).dataset['stadsdeelId'];
  //     if (stadsdeelId) {
  //       sessionStorage.setItem('selectedStadsdeelId', stadsdeelId);
  //     }
  //   });
  // }

 }

const initKompasLanding = () => {
  const wijkId = sessionStorage.getItem('selectedWijkId');
  if (wijkId !== null) {
    jQuery('#collapse-kompas').collapse('show');
    Utils.Instance.setMemoryData('selectedWijkId', wijkId);
    // window.setTimeout(() => {
    //   Utils.Instance.setMemoryData('selectedWijkId', wijkId);
    //   jQuery('#collapse-kompas').collapse('show');
    // }, 0);
  }
  sessionStorage.removeItem('selectedWijkId');

  const stadsdeelId = sessionStorage.getItem('selectedStadsdeelId');
  if (stadsdeelId !== null) {
    Utils.Instance.setMemoryData('selectedStadsdeelId', stadsdeelId);
  }
  sessionStorage.removeItem('selectedStadsdeelId');
}

const initWheel = (selector) => {
  if (document.querySelector(`#${selector.id}`)) {
    const element = document.getElementById(selector.id);

    let curWijkId = null;

    Utils.Instance.inMemoryData$.subscribe((data) => {
      if (data.hasOwnProperty('selectedWijkId')) {
        const wijkId = (data as any).selectedWijkId;
        if (wijkId && wijkId !== curWijkId) {
          curWijkId = wijkId;
          const wheel = new ColorWheel(
            `#${selector.id}`,
            wijkId,
            selector.variant
          );
        }
      }
    });

  }
};

const initWheels = () => {

  const selectors = [
    // {id: 'color-wheel-test', variant: 1},
    {id: 'kompas-main-color-wheel-stand', variant: 1},
    // {id: 'color-wheel-stand-3', variant: 1},
    {id: 'kompas-main-color-wheel-ontwikkel', variant: 2},
    // {id: 'color-wheel-ontwikkeling_tov_stad', variant: 3},
  ];
  for (const selector of selectors) {
    initWheel(selector);
  }
};

/* breadcrumbs + dropdown menu */
// const initIndicatorBreadcrumbs = () => {

//   const selectorId = 'indicator_menu';
//   const variant = 1;

//   setTimeout(() => {
//     if (document.querySelector(`#${selectorId}`)) {
//       const element = document.getElementById(selectorId);


//       let curWijkId = null;

//       Utils.Instance.inMemoryData$.subscribe((data) => {
//         if (data.hasOwnProperty('selectedWijkId')) {
//           const wijkId = (data as any).selectedWijkId;
//           if (wijkId && wijkId !== curWijkId) {
//             curWijkId = wijkId;
//             const menu = new IndicatorMenu(
//               `#${selectorId}`,
//               wijkId,
//               variant,
//             );
//           }
//         }
//       });
//     }
//   },1000)
// };

const initHorizontalBarCharts = () => {
  const elements = document.getElementsByClassName('horizontal_barchart');
  for (const element of Array.from(elements)) {
    const chart = new HorizontalBarChart(element);  // tsline wants an assignment
  }
};

const initVerticalBarCharts = () => {
  const elements = document.getElementsByClassName('vertical_barchart');
  for (const element of Array.from(elements)) {
    const chart = new VerticalBarChart(element);  // tsline wants an assignment
  }
};

const initGroupedBarCharts = () => {
  const elements = document.getElementsByClassName('grouped_barchart');
  for (const element of Array.from(elements)) {
    const chart = new GroupedBarChart(element);  // tsline wants an assignment
  }
};

const initLineCharts = () => {
  const elements = document.getElementsByClassName('linechart');
  for (const element of Array.from(elements)) {
    const chart = new LineChart(element);
  }
};

const initPieCharts = () => {
  const elements = document.getElementsByClassName('piechart');
  for (const element of Array.from(elements)) {
    const chart = new PieChart(element);
  }
};

const initGraphWijkKaart = () => {

  const elements = document.getElementsByClassName('graph-wijkkaart');
  for (const element of Array.from(elements)) {
    const indicatorId = element.getAttribute('data-wijkkaart-indicator-id');
    const title = element.getAttribute('data-title');
    const geojson = element.getAttribute('data-geojson-url');
    if (indicatorId || geojson) {
      let elementSelector = `#${element.id}`;
      const wijkkaart = new WijkKaart(
        elementSelector, parseInt(indicatorId, 10), 1, null, false, geojson);
    }
  }
};

const initWijkKaart = () => {

  let initBySelector = (selector, options) => {
    if (document.querySelector(selector)) {
      let wijkkaart: WijkKaart = null;

      Utils.Instance.inMemoryData$.subscribe((data) => {
        const wijkId = (data as any).selectedWijkId;
        const indicator = (data as any).selectedIndicator;

        if (indicator) {
          if (!wijkkaart) {
            wijkkaart = new WijkKaart(selector, null, 1, options.colorScheme || '');
          }
          if (indicator !== wijkkaart.currentIndicator) {
            wijkkaart.loadIndicator(indicator);
          }
        }
      });
    }
  };

  initBySelector('#wijkkaart', {colorScheme: 'grey'});
  // initBySelector('#kompas-nav-wijkkaart', {});
  initBySelector('#kompas-main-wijkkaart', {});
};

const initWijkSelector = () => {
  const selector = '#wijkselector';
  if (document.querySelector(selector)) {

    Utils.Instance.getWijken().then((wijkenArray) => {
      const select = new WijkSelector('wijkselector', wijkenArray);
      select.render();
    });
  }
};

const initStadsdeelSelector = () => {
  const selector = '#stadsdeelselector';
  if (document.querySelector(selector)) {

    Utils.Instance.getWijken().then((wijkenArray) => {
      const select = new StadsdeelSelector('stadsdeelselector', wijkenArray);
      select.render();

      Utils.Instance.inMemoryData$.subscribe((data) => {
        if (data.hasOwnProperty('selectedStadsdeelId')) {
          let wijken = document.getElementsByClassName('nav-item-wijk');

          // hide wijken from other stadsdelen
          for (let i = 0; i < wijken.length; i++) {
            if (wijken[i].classList.contains('stadsdeel-' + data['selectedStadsdeelId'])) {
              wijken[i].classList.remove('hidden');
            } else {
              wijken[i].classList.add('hidden');
            }
          }
        }
      });
    });
  }
};

const initWijkContent = () => {
  const wijkContent = new WijkContent();
  wijkContent.initWijkContent();
};

const initWijkIndicatorTekst = () => {
  const wijkIndicatorTekst = new WijkIndicatorTekst();
  wijkIndicatorTekst.initWijkIndicatorTekst();
};

// const initWijkSelectors = () => {

//   const ids = ['selected_new_wijk', 'current_selected_wijk'];

//   for (const selectorId of ids) {

//     const domSelector = `#${selectorId}`;

//     if (document.querySelector(domSelector)) {
//       const select = document.getElementById(selectorId) as HTMLSelectElement;

//       // click -> set selectedWijk
//       select.addEventListener('change', () => {
//         const selectedWijkId = select[select.selectedIndex].value;
//         Utils.Instance.setMemoryData('selectedWijkId', selectedWijkId);
//       });

//       // listen for selectedWijk changes, and update the selected option
//       Utils.Instance.inMemoryData$.subscribe((data) => {
//         const wijkId = (data as any).selectedWijkId;

//         if (wijkId) {
//           for (const element of Array.from(select)) {
//             const selected = (element.value === wijkId);
//             element.selected = selected;
//           }
//         }
//       });
//     }
//   }
// };

const initIndicatorBarChart = () => {

  let initBySelectorId = (selectorId) => {
    let domSelector = `#${selectorId}`;
    if (document.querySelector(domSelector)) {
      const element = document.getElementById(domSelector);
      let bc: IndicatorBarChart = null;

      let wijkId = null;
      let indicator = null;

      Utils.Instance.inMemoryData$.subscribe((data) => {
        const newWijkId = (data as any).selectedWijkId;
        const newIndicator = (data as any).selectedIndicator;

        if (newWijkId && newIndicator) {
          if (newWijkId != wijkId || indicator != newIndicator) {
            wijkId = newWijkId;
            indicator = newIndicator;

            bc = new IndicatorBarChart(element);
            bc.initIndicatorBarChart(selectorId, indicator, wijkId);
          }
        }
      });
    }
  };

  initBySelectorId('kompas-main-indicatorbarchart');
  // initBySelectorId('kompas-nav-indicatorbarchart');
};

const initIndicatorInfo = () => {
  const titleSelectorId = 'indicator-title';
  const bodySelectorId = 'indicator-info';

  if (document.querySelector(`#${titleSelectorId}`)) {
    let info: IndicatorInfo = null;
    let indicator = null;

    Utils.Instance.inMemoryData$.subscribe((data) => {
      const newIndicator = (data as any).selectedIndicator;
      if (newIndicator && indicator != newIndicator) {
        indicator = newIndicator;
      }

      if (indicator) {
        info = new IndicatorInfo(titleSelectorId, bodySelectorId, indicator);
      }
    });
  }
};

const initIndicatorenMenu = () => {
  const selectorId = 'indicatoren-menu';
  const domSelector = `#${selectorId}`;

  if (document.querySelector(domSelector)) {
    const element = document.getElementById(selectorId);
    element.addEventListener('click', (e: Event) => {
      e.preventDefault();
      const tgt = e.target as HTMLAnchorElement;
      const indicatorId = tgt.getAttribute('data-indicator-id');
      if (indicatorId) {
        Utils.Instance.setMemoryData('selectedIndicator', indicatorId);
      }
    });

  }
};

const initDashboardSelections = () => {
  Utils.Instance.setMemoryData('selectedIndicator', String(Utils.Instance.rootIndicator));
  // don't set initial wijk, however we need at least some value
  // for some initialization to happen..
  Utils.Instance.setMemoryData('selectedWijkId', null);
  Utils.Instance.setMemoryData('selectedStadsdeelId', '1');
};

const initCharts = () => {
  /* init all charts e.g. after beleidscluster page has been loaded */
  app.ready(initHorizontalBarCharts);
  app.ready(initVerticalBarCharts);
  app.ready(initGroupedBarCharts);
  app.ready(initLineCharts);
  app.ready(initPieCharts);
};

const initResizeFixes = () => {
  // bootstrap emits a jquery event after a collapsable is shown
  // we detect this event here using jQuery since a custom jQuery event
  // is not compatible with the normal browser events for some reason.
  // for now we just throw a 'resize' event on which all of our graphs
  // (that might be present on a collapsed container) might subscribe and
  // redraw themselves.
  jQuery(document).on('shown.bs.collapse', () => {
    window.dispatchEvent(new CustomEvent('resize'));
  });
};


const initExternalLinks = () => {
  var anchors = document.querySelectorAll('a');
  for (let i = 0; i < anchors.length; i++) {
    if (anchors[i].href.search(/http(s?)\:\/\//) !== -1) {
      if (anchors[i].hostname && anchors[i].hostname !== window.location.hostname) {
        anchors[i].setAttribute('target', '_blank');

        let el = document.createElement('span');
        el.classList.add('sr-only');  // screen reader only
        el.innerHTML = '(externe website)';
        anchors[i].appendChild(el);

        // el = document.createElement('i');
        // el.classList.add('icon--external-link', 'fas', 'fa-external-link-alt');
        // anchors[i].appendChild(el);
      }
    }
  }
};

const initKompasNav = () => {

  let clickMasks = document.querySelectorAll('.kompas-nav-container .click-mask');
  let kompasMainItems = document.querySelectorAll('.kompas-main');

  for (let i = 0; i < clickMasks.length; i++) {

    clickMasks[i].addEventListener('click', function (event) {

      event.preventDefault();

      // remove all 'clicked' classes
      for (let z = 0; z < clickMasks.length; z++) {
        clickMasks[z].parentElement.classList.remove('clicked');
      }

      // find out clicked target
      let target =  document.querySelector('#' + clickMasks[i].getAttribute('data-click-target'));

      // hide all main items
      for (let y = 0; y < kompasMainItems.length; y++) {
        kompasMainItems[y].classList.add('hidden');
      }

      clickMasks[i].parentElement.classList.add('clicked');

      // show clicked target
      target.classList.remove('hidden');

      // force resize event
      window.dispatchEvent(new CustomEvent('resize'));
    })
  }
};

const initLegendGauge = () => {
  let gauge: LegendGauge = null;
  let gaugeContainer = document.getElementById('kompas-gauge');
  let legendContainer = document.getElementById('kompas-legenda');
  if (gaugeContainer && legendContainer) {
    gauge = new LegendGauge(gaugeContainer, legendContainer);
  }
}

const addBodyClassIE = () => {
  // add IE class to body if Internet Explorer
  const isIE = (window.navigator.userAgent.match(/MSIE|Trident/) !== null);
  if (isIE) {
    document.getElementById("IE-warning").classList.remove('hidden');
  }
}

app.ready(initMegaDropdowKompasLinkClicks);
app.ready(initStylingFixes);
app.ready(initLegendGauge);
app.ready(initWheels);
// app.ready(initIndicatorBreadcrumbs);
app.ready(initCharts);
app.ready(initWijkKaart);
app.ready(initGraphWijkKaart);
app.ready(initWijkSelector);
app.ready(initWijkContent);
app.ready(initStadsdeelSelector);
app.ready(initIndicatorBarChart);
app.ready(initIndicatorInfo);
app.ready(initIndicatorenMenu);
app.ready(initResizeFixes);
app.ready(initExternalLinks);
app.ready(initKompasNav);
app.ready(initDashboardSelections);
app.ready(initKompasLanding);
app.ready(initWijkIndicatorTekst);
app.ready(addBodyClassIE);
app.ready(bootstrapVue);
