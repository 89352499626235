var deviceInfo;
var hasLocalStorage;

//  Document.ready event handler:
$(document).ready(function () {
    //  Onload - Get device info and add css classes to body element:
    deviceInfo = DeviceDetector.parse();
    $('body').addClass('is_' + deviceInfo.type.toLowerCase());
    //  Onload - Check browser HTML5 LocalStorage support:
    //hasLocalStorage = (typeof (Storage) !== 'undefined' && window.localStorage !== 'undefined');
    hasLocalStorage = (typeof (localStorage) !== undefined);
    //  Onload - If referrer has hash, disable 'Jump to anchor':
    if (location.hash) {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 1);
    }

    //  SCROLLER-RELATED:
    //  Scroller - Visual Text:
    initVisualTextScroller();
    //  Scroller - Page Content:
    initContentScroller();

    //  NAVIGATION-RELATED:
    //  Navigation - Main:
    initMainNavigation();
    //  [TOUCH-ONLY] - Navigation - Main - SubSections:
    initMainNavigationSubSections();
    //  Navigation - Sidebar level 1:
    initSidebarNavigation();
    //  Navigation - Sidebar level 2:
    initSidebarSubNavigation();
    //  Navigation - Quick navigation:
    initQuickNavigation();
    //  Navigation - 'Go to top':
    initNavigateToTop();

    //  SELECTR-RELATED:
    //  Selectr - Main Navigation dropdowns:
    initMainNavigationSelectrDropdowns();

    //  VISUAL-RELATED:
    //  "Toggle Page visual"-button:
    initTogglePageVisual();
});

//  Window.resize event handler:
$(window).resize(function () {
    //  Re-initialize Side SubNavigation (in case the 800px 'breakpoint' [scrolling to content or opening it in overlay] is crossed):
    initSidebarSubNavigation();
});

//  SCROLLER-RELATED:
//  Initialize Visual scroller:
function initVisualTextScroller() {
    $('#block-visual-text-scroller').mCustomScrollbar({
        axis: 'y',
        scrollButtons: {
            enable: false
        },
        advanced: {
            updateOnContentResize: false
        },
        theme: 'visual-scroller'
    });
}

//  Initialize Content scroller:
var contentElementsInView = [];
function initContentScroller() {
    //  Check if page has Content scroller:
    if (document.getElementById('page-content-scroller')) {
        // Scroller theming:
        var themeName;
        var currentPage = window.location.pathname;
        if (currentPage.indexOf('styleguide') > -1) {
            themeName = '';
        }
        else if (currentPage.indexOf('kompas_van_groningen_') > -1) {
            themeName = 'primary';
        }
        else if (currentPage.indexOf('stand_van_groningen_') > -1) {
            themeName = 'secondary';
        }
        else {
            themeName = 'tertiary';
        }
        //  Init. scroller:
        var throttleCounter = 0;
        var throttleIterationsToSkip = 25;
        var contentScrollerTop = 0;
        var contentScrollingDown = false;
        //
        $('#page-content-scroller').mCustomScrollbar({
            axis: 'y',
            mouseWheel: {
                deltaFactor: 30
            },
            scrollButtons: {
                enable: false
            },
            theme: themeName + '-content-scroller',
            callbacks: {
                onInit: function() {
                  // HUUB: DIT HEEFT NOG WAT AANDACHT NODIG
                  // mCustomScrollbar of updateSideNavigationOnLoad werkt nogal matig qua timing
                  setTimeout(function () {
                    updateSideNavigationOnLoad();
                  }, 1000);
                },
                whileScrolling: function () {
                    //  Is scrolling 'manual' (ie. triggered by direct user input, or by click on item in sidebar navigation)?
                    navigationScroll = $(this).data("mCS").trigger === 'external';
                    //  Only if scrolling is manual do we want to update SideNavigation buttons/submenu's:
                    if (!navigationScroll) {
                        //  Update Throttle counter:
                        throttleCounter++;
                        //  Only allow code execution for every {throttleIterationsToSkip}th 'whileScrolling' event:
                        if (throttleCounter > 1) {
                            if (throttleCounter === throttleIterationsToSkip) {
                                throttleCounter = 0;
                            }
                            return;
                        }
                        //  Determine scroll direction:
                        contentScrollingDown = (contentScrollerTop > this.mcs.top);
                        contentScrollerTop = this.mcs.top;
                        // Track ContentSection elements, to determine when what element comes into view (so we can update SideNavigation buttons/submenu's dynamically depending on the scroll position):
                        $('.content-section').each(function () {
                            var contentElementId = $(this).attr('id');
                            var inView = checkInView($(this), true);
                            var contentElementsInViewArray = jQuery.inArray(contentElementId, contentElementsInView) > -1;
                            var doUpdate = false;

                            if (inView) {
                                var contentElementPosition = document.getElementById(contentElementId).getBoundingClientRect().top;
                                if (contentScrollingDown) {
                                    //console.log('[DOWN] ID=' + $(this).attr('id') + ' - ' + contentElementPosition + ' (' + $('#page-content-scroller').height() + ')');
                                    if (contentElementPosition < $('#page-content-scroller').height()) {
                                        doUpdate = true;
                                    }
                                }
                                else {
                                    //console.log('[UP] ID=' + $(this).attr('id') + ' - ' + contentElementPosition + ' (' + $('#page-content-scroller').height() * 0.875 + ')');
                                    if (contentElementPosition < $('#page-content-scroller').height() * 0.5) {
                                        doUpdate = true;
                                    }
                                }
                                //
                                if (!contentElementsInViewArray) {
                                    contentElementsInView.splice(0, 0, contentElementId);
                                }

                            }
                            else {
                                if (contentElementsInViewArray) {
                                    var index = -1;
                                    for (var i = 0; i < contentElementsInView.length; ++i) {
                                        if (contentElementsInView[i] === contentElementId) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    contentElementsInView.splice(index, 1);
                                }
                            }
                            //  Update SideNavigation active item:
                            if (doUpdate) {
                                if (!$('nav').find('[data-target=' + contentElementId + ']').hasClass('active')) {
                                    updateSideNavigationFromContentScroller(contentElementId);
                                }
                            }
                        });
                    }
                }
            }
        });
        //  Update SideNavigation onLoad:
        // updateSideNavigationOnLoad();
    }
}

function checkInView(elem, partial) {
    var container = $('#page-content-scroller');
    var contHeight = container.height();

    var elemTop = $(elem).offset().top - container.offset().top;
    var elemBottom = elemTop + $(elem).height();

    var isTotal = (elemTop >= 0 && elemBottom <= contHeight);
    var isPart = ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= container.height())) && partial;

    return isTotal || isPart;
}

//  Update SideNavigation onLoad:
function updateSideNavigationOnLoad() {
    //  Set SideNavigation (sidebar) active items:
    setTimeout(function () {
        //  Check if url contains valid hash, section-{int}:
        var hasValidHash = (location.hash);
        if (hasValidHash) {
            var target = location.hash.substr(1, (location.hash.length - 1));
            hasValidHash = target.includes('section-');
        }
        if (hasValidHash) {
            //  Url has valid hash => Scroll to pre-selected ContentSection:
            console.log('[ONLOAD] - Scroll to Content Anchor ' + target);
            $('#page-content-scroller').mCustomScrollbar('scrollTo', '#' + target);
            //  Update active menu item:
            var affectedNavigationItem = $('nav').find('[data-target=' + target + ']');
            var navButton = affectedNavigationItem.parent().parent().parent().find('button.sub-navigation');
            affectedNavigationItem.addClass('active');
            navButton.addClass('active');
        }
        else {
            //  Url has no valid hash => Set first menu item to active:
            console.log('[ONLOAD] - Show first content element.');
            var firstMenuItem = $('nav ul.level-1 li:first-of-type');
            var firstSubNavigation = firstMenuItem.find('ul.level-2');
            var firstNavigationItem = firstMenuItem.find('.button.sub-navigation');
            firstNavigationItem.addClass('active');
            firstSubNavigation.addClass('active');
            firstSubNavigation.find('li:first-of-type .sub-navigation-level2').addClass('active');
        }

        //  Get content Elements that are visible onLoad:
        $('.content-section').each(function () {
            var id = $(this).attr('id');
            var inView = checkInView($(this), true);
            if (inView) {
                contentElementsInView.splice(0, 0, id);
            }
        });

    }, 500);
}

//  Update SideNavigation from Content scroller:
function updateSideNavigationFromContentScroller(contentSectionId) {
    var navElement = $('nav');
    var affectedNavigationItem = navElement.find('[data-target=' + contentSectionId + ']');
    if (!affectedNavigationItem.hasClass('active')) {
        navElement.find('.sub-navigation-level2').removeClass('active');
        navElement.find('[data-target=' + contentSectionId + ']').addClass('active');
        //  Update active SideNavigation buttons/submenu's, if necessary:
        var navSubMenu = affectedNavigationItem.parent().parent();
        var navButton = affectedNavigationItem.parent().parent().parent().find('button.sub-navigation');
        if (!navSubMenu.hasClass('active')) {
            $('ul.level-2.active').removeClass('active');
            $('button.sub-navigation.active').removeClass('active');
            affectedNavigationItem.addClass('active');
            navButton.addClass('active');
        }
    }
}


//  NAVIGATION-RELATED:
//  'Click' handler for Main Navigation toggle:
function initMainNavigation() {
    $('#main-navigation-toggle').on('click', function () {
        var trigger = $(this);
        var navigation = $('#main-navigation-container');
        //
        if (navigation.hasClass('visible')) {
            navigation.removeClass('visible');
            //  Unlock body, so user can scroll page content:
            $('body').removeClass('locked');
            //  Destroy MainNavigation scrollbar:
            $('#main-navigation-scroller').mCustomScrollbar('destroy');
            //  Update trigger button icon (slightly delayed to allow coincide with slideUp animation):
            setTimeout(function () {
                trigger.find('.fa').removeClass('fa-times').addClass('fa-bars');
            }, 250);
        }
        else {
            navigation.addClass('visible');
            //  Lock body, so user cannot scroll underlying page content when navigation is opened:
            $('body').addClass('locked');
            //  Init. MainNavigation scrollbar:
            $('#main-navigation-scroller').mCustomScrollbar({
                axis: 'y',
                scrollButtons: {
                    enable: false,
                    scrollInertia: 0,
                    mouseWheel: { preventDefault: true }
                },
                theme: 'navigation-scroller'
            });
            //  Update trigger button icon (slightly delayed to allow coincide with slideUp animation):
            setTimeout(function () {
                trigger.find('.fa').removeClass('fa-bars').addClass('fa-times');
            }, 250);
        }
    });
}

//  [TOUCH-ONLY] - 'Click' handler for Main Navigation SubSection toggles:
function initMainNavigationSubSections() {
    $('[id^=main-navigation-button-]').on('click', function () {
        var button = $(this);
        var sub_menu = button.parent().find('ul');
        //
        button.toggleClass('active');
        sub_menu.toggleClass('visible');
    });
}

//  'Click' handler for SideNavigation (sidebar) primary items (level 1):
function initSidebarNavigation() {
    $('nav .sub-navigation').on('click', function () {
        var submenu = $(this).parent().find('ul');
        var submenu_isvisible = submenu.hasClass('active');
        if (submenu_isvisible) {
            //  Clicked menu-item is active:
            //  Remove 'active' state from clicked menu-item:
            $(this).removeClass('active');
            //  Remove 'active' state from clicked menu-item's sub-menu:
            submenu.removeClass('active');
        }
        else {
            //  Clicked menu-item is not active:
            //  Remove 'active' state of all active sub-menu(s) and menu-item(s):
            $('.level-2.active').toggleClass('active');
            $('.sub-navigation.active').toggleClass('active');
            //
            //  Assign 'active' state to clicked menu-item:
            $(this).toggleClass('active');
            //  Assign 'active' state to clicked menu-item's sub-menu:
            submenu.toggleClass('active');
        }
    });
}

//  'Click/TouchEnd' handler for SideNavigation (sidebar) secondary items (level 2):
function initSidebarSubNavigation() {
    var windowWidth = $(window).width();
    //console.log('window width: ' + windowWidth);
    if (windowWidth < 800) {
        //  Screen width < 800px => use Content Overlay:
        $('.sub-navigation-level2').off().on('click', function (e) {
            //  Prevent shared touchend/click event from firing twice:
            e.stopPropagation(); e.preventDefault();
            //  Execute requested action:
            showContentInOverlay($(this));
        });
    }
    else {
        //  Screen width >= 800px => use Content Scroller:
        $('.sub-navigation-level2').off().on('click', function (e) {
            //  Prevent shared touchend/click event from firing twice:
            e.stopPropagation(); e.preventDefault();
            //  Execute requested action:
            showContentInScroller($(this));
        });
    }

    //  In case using click event alone is not enough, and we need to use a shared touchend/click event:
    //  Alt. approach 1:
    //  Use 'stopPropagation' and 'preventDefault' to prevent shared touchend/click event from firing twice:
    //$('.sub-navigation-level2').on('touchend click', function (e) {
    //    e.stopPropagation(); e.preventDefault();
    //    //do something ...
    //});

    //  Alt. approach 2:
    //  Use debouncing to prevent shared touchend/click event from firing twice:
    //$('.sub-navigation-level2').on('touchend click', $.debounce(100, function (event) {
    //    //do something ...
    //}));
}

//  Show the requested content in a Content Overlay (for any device with screen with < 800px):
function showContentInOverlay(trigger) {
    var target = trigger.data('target');
    console.log('[SCREEN WIDTH < 800px] - Open content at Content Anchor ' + target + ' in overlay');
    //  Create Overlay (div element) on the fly, copy target ContentSection html, and append to body:
    var $overlay = $('<div id="overlay" class="overlay">' +
        '<button id="overlay-close" type="button" class="button icon-only transparent"><i class="fa fa-times"></i></button>' +
        '<div id="overlay-inner" class="overlay-inner">' +
        $('#' + target).html() +
        '</div > ');
    $overlay.appendTo('body');
    //  Apply css class 'active' to Overlay to make it visible, and lock document body, so user cannot scroll page content under overlay:
    setTimeout(function () {
        $overlay.addClass('visible');
        $('body').addClass('locked');
    }, 125);

    //  Bind 'Close overlay'-button:
    setTimeout(function () {
        $('#overlay-close').on('click', function (e) {
            //  Prevent shared touchend/click event from firing twice:
            e.stopPropagation(); e.preventDefault();
            //  Execute requested action:
            $overlay.removeClass('visible');
            setTimeout(function () {
                //  Unlock body, so user can scroll page content:
                $('body').removeClass('locked');
                //  Destroy overlay element:
                $overlay.remove();
                trigger.removeClass('visible');
            }, 500);
        });
    }, 750);
}

//  Show the requested content in the Content Scroller (for any device with screen with >= 800px):
function showContentInScroller(trigger) {
    //  Trigger button target:
    //trigger.addClass('visible');
    var target = trigger.data('target');
    console.log('[SCREEN WIDTH >= 800px] - Scroll to Content Anchor ' + target);
    $('#page-content-scroller').mCustomScrollbar('scrollTo', '#' + target);
    //  Update active menu item:
    trigger.parent().parent().find('.sub-navigation-level2').removeClass('active');
    trigger.addClass('active');
}




//  'Click' handler for QuickNavigation dropdown:
function initQuickNavigation() {
    //  QuickNavigation Toggle click handler:
    $('#quick-navigation-trigger').on('click', function () {
        //var dropdown = $(this).parent().find('.custom-select-selectable-items');
        $('#quick-navigation-items').toggleClass('active');
    });
    //  QuickNavigation Item click handler:
    $('#quick-navigation-items a').on('click', function () {
        var link = $(this);
        //  Added below to prevent anchor href from being executed (we want to close the QuickNavigation first):
        //  (html anchors are used as fallback)
        //  Hide QuickNavigation:
        $('#quick-navigation-items').removeClass('active');
        //  Redirect to target (delayed so 'QuickNavigation' animation can complete):
        setTimeout(function () {
            document.location = link.attr('href');
        }, 275);
        return false;
    });
    //  QuickNavigation 'Close'-button click handler:
    $('#quick-navigation-close').on('click', function () {
        //var dropdown = $(this).parent().find('.custom-select-selectable-items');
        $('#quick-navigation-items').toggleClass('active');
    });
}

//  'Click' handler for 'Go to top'-elements:
function initNavigateToTop() {
    $('.to-top-trigger').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    });
}


//  SELECTR-RELATED:
//  Initialize Main Navigation Selectr dropdowns:
function initMainNavigationSelectrDropdowns() {
    var mobileDevice = false;
    //  Custom code required to override default 'nativeDropdown = true' behavior on mobile (we want the same functionality as on non-mobile devices):
    Object.defineProperty(Selectr.prototype, 'mobileDevice', {
        get: function () { return mobileDevice; },
        set: function (val) { mobileDevice = val; },
        enumerable: true,
        configurable: true
    });

    //  Init. dropdown 'Kompas van Groningen' => 'Direct naar een wijk':
    var districtsDropdown = new Selectr('#selectr-main-navigation-stadsdelen', {
      defaultSelected: false,
      nativeDropdown: false,
      placeholder: 'Direct naar stadsdeel',
      searchable: true,
      messages: {
          noResults: 'Geen resultaten',
          searchPlaceholder: 'Zoek op trefwoord...'
      }
    });
    districtsDropdown.on('selectr.open', function () {
        //  Scroll to ensure 'Dictricts' dropdown is fully in view:
        var districtsOffset = document.getElementById('scroll-anchor-neighborhoods').getBoundingClientRect().top - 60;
        $('#main-navigation-scroller').mCustomScrollbar('scrollTo', '-=' + districtsOffset);
        //  Disable the custom scrollbar, so scrolling the 'Dictricts' dropdown items won't propagate to content scroller.
        //  [delayed to allow scroll animation to complete]
        setTimeout(function () {
            $('#main-navigation-scroller').mCustomScrollbar('disable');
        }, 500);
    });
    districtsDropdown.on('selectr.select', function (option) {
      // dispatch Event to rest of the world
      var event = new CustomEvent('stadsdeelSelected', {
        'detail': {
          'stadsdeelId': $(option).data('stadsdeel-id') ,
          'stadsdeelName': $(option).html(),
        }
      });

      localStorage.navSelectedStadsdeel = JSON.stringify({
        'detail': {
          'stadsdeelId': $(option).data('stadsdeel-id') ,
          'stadsdeelName': $(option).html(),
        }
      });

      window.dispatchEvent(event);
      $('#main-navigation-toggle').trigger('click');
      mainNavigationSelectrDropdownSelected(option.value);
    });
    districtsDropdown.on('selectr.close', function () {
      $('#main-navigation-scroller').mCustomScrollbar('update');
    });

    //  Init. dropdown 'Kompas van Groningen' => 'Direct naar een wijk':
    var neighborhoodsDropdown = new Selectr('#selectr-main-navigation-kompas-neighborhoods', {
        defaultSelected: false,
        nativeDropdown: false,
        placeholder: 'Direct naar een wijk',
        searchable: true,
        messages: {
            noResults: 'Geen resultaten',
            searchPlaceholder: 'Zoek op trefwoord...'
        }
    });
    neighborhoodsDropdown.on('selectr.open', function () {
        //  Scroll to ensure 'Neighborhoods' dropdown is fully in view:
        var neighborhoodsOffset = document.getElementById('scroll-anchor-neighborhoods').getBoundingClientRect().top - 60;
        $('#main-navigation-scroller').mCustomScrollbar('scrollTo', '-=' + neighborhoodsOffset);
        //  Disable the custom scrollbar, so scrolling the 'Neighborhoods' dropdown items won't propagate to content scroller.
        //  [delayed to allow scroll animation to complete]
        setTimeout(function () {
            $('#main-navigation-scroller').mCustomScrollbar('disable');
        }, 500);
    });
    neighborhoodsDropdown.on('selectr.select', function (option) {
      // dispatch Event to rest of the world
      var event = new CustomEvent('wijkSelected', { 'detail': { 'wijkId': $(option).data('wijk-id') } });
      window.dispatchEvent(event);
      $('#main-navigation-toggle').trigger('click');
      mainNavigationSelectrDropdownSelected(option.value);
    });
    neighborhoodsDropdown.on('selectr.close', function () {
      $('#main-navigation-scroller').mCustomScrollbar('update');
    });

    //  Init. dropdown 'Stand van Groningen' => 'Direct naar een thema':
    $('.selectr-menu-themes').each((idx, elem) => {
       var themesDropdown = new Selectr('#' + elem.id, {
        defaultSelected: false,
        nativeDropdown: false,
        // placeholder: 'Direct naar een thema',
        placeholder: elem.getAttribute('placeholder'),
        searchable: true,
        messages: {
            noResults: 'Geen resultaten',
            searchPlaceholder: 'Zoek trefwoord...'
        }
    });
    themesDropdown.on('selectr.open', function () {
        //  Scroll to ensure 'Themes' dropdown is fully in view:
        var themesPos = $(elem).first('.scroll-anchor-themes')[0].getBoundingClientRect().top - 60;
        $('#main-navigation-scroller').mCustomScrollbar('scrollTo', '-=' + themesPos);
        //  Disable the custom scrollbar, so scrolling the 'Themes' dropdown items won't propagate to content scroller.
        //  [delayed to allow scroll animation to complete]
        setTimeout(function () {
            $('#main-navigation-scroller').mCustomScrollbar('disable');
        }, 500);
    });
    themesDropdown.on('selectr.close', function () {
        $('#main-navigation-scroller').mCustomScrollbar('update');
    });
    themesDropdown.on('selectr.select', function (option) {
        mainNavigationSelectrDropdownSelected(option.value);
    });
  })
}

//  Main Navigation Selectr dropdown onChange redirect handler:
function mainNavigationSelectrDropdownSelected(ref) {
    //  Close Main Navigation:
    $('#main-navigation-container').removeClass('visible');
    $('body').removeClass('locked');
    $('#main-navigation-scroller').mCustomScrollbar('destroy');

    //  Redirect (after Main Navigation is hidden):
    if( ref && document.location.pathname != ref.split("?")[0]) {
      setTimeout(function () {
          document.location.href = ref;
      }, 550);
    }
}


//  VISUAL-RELATED:
//  Initialize "Toggle Page visual"-button:
function initTogglePageVisual() {
    //  Show/collapse page visual on PageLoad (based on LocalStorage value):
    var toggleButton = $('#button-toggle-visual');
    var visual = $('#page-visual-container');
    var pageContent = $('#page-content-scroller');

    //  LocalStorage supported:
    if (hasLocalStorage) {
        var showVisual = localStorage.getItem('showPageVisual');
        if (showVisual === 'false') {
            //  Hide visual onLoad:
            toggleButton.addClass('collapsed');
            visual.addClass('hidden');
            pageContent.addClass('full');
        }
    }

    //  Toggle button click handler:
    toggleButton.on('click', function () {
        var trigger = $(this);
        //var visual = $('#page-visual-container');
        var visualHidden = visual.hasClass('hidden');
        //var pageContent = $('#page-content-scroller');

        if (visualHidden) {
            //  Visual is hidden => show:
            trigger.removeClass('collapsed');
            visual.removeClass('hidden');
            pageContent.removeClass('full');
            //  Update LocalStorage:
            if (hasLocalStorage) {
                localStorage.setItem('showPageVisual', true);
            }
        }
        else {
            //  Visual is not hidden => hide:
            trigger.addClass('collapsed');
            visual.addClass('hidden');
            pageContent.addClass('full');
            //  Update LocalStorage:
            if (hasLocalStorage) {
                localStorage.setItem('showPageVisual', false);
            }
        }
        setTimeout(function () {
            $('#page-content-scroller').mCustomScrollbar('update');
        }, 500);
    });
}
