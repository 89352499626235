import d3 = require('d3');
import dimple = require('dimple');
import { IGraphData } from './models/graphdata';
import { IGraphModel } from './models/graphmodel';
import { Utils } from './utils';

export interface IKeyValueProps {
  key: string;
  value: string;
}

export interface IDataRecord {
  category: string;
  serie: string;
  value: number;
}

export enum Axis {
    x = 'x',
    y = 'y',
}

export abstract class BaseChart {

  protected element: Element = null;
  protected popup: any = null;
  protected svg: any = null;
  protected chart: any = null;
  protected xAxis: any = null;
  protected yAxis: any = null;
  protected legend: any = null;
  protected fieldNames: string[] = null;
  protected seriesData: IDataRecord[] = null;
  protected serieName = 'serie';
  protected aspect = 1.0;
  protected minWidthSvgDrawing = 350;

  // the dimensions and margins of the graph
  protected props = {
    'aspect-ratio': '1.3333',
    'date-parse-format': null,
    'legend-align': 'left',
    'legend-x': '50',
    'legend-y': '1',
    'margin-bottom': '20',
    'margin-left': '50',
    'margin-right': '20',
    'margin-top': '40',
    'pie-chart-inner-radius': '20%',
    'x-axis-format': ',',
    'x-axis-overrideMax': null,
    'x-axis-overrideMin': null,
    'x-axis-rotate-labels': null,
    'x-axis-ticks': null,
    'x-axis-time-interval': null,
    'x-axis-time-period': null,
    'x-axis-title-margin': null,
    'y-axis-format': ',',
    'y-axis-overrideMax': null,
    'y-axis-overrideMin': null,
    'y-axis-ticks': null,
    'y-axis-title-margin': null,
  };

  public constructor(element: Element) {

    this.element = element;

    const graphId = parseInt(element.getAttribute('data-graph-id'), 10);
    const dataUrl = element.getAttribute('data-url');
    if (!dataUrl) {
      throw new Error('data-url attribute missing on HTML element');
    }

    const graphPromise = this.getGraphModel(graphId, element);
    const dataPromise = this.getGraphData(dataUrl, element);

    Promise.all([graphPromise, dataPromise]).then((allData) => {

      const graphModel = allData[0];
      const data = allData[1];

      const properties = new Array<IKeyValueProps>();
      if (graphModel.hasOwnProperty('properties')) {
        for (const graphProp of graphModel.properties) {
          if (graphProp.hasOwnProperty('value')) {
            properties.push(graphProp.value);
          }
        }
      }
      this.loadProperties(properties);
      this.getFieldNames(data);
      this.getSeriesData(data);
      this.drawScene(graphModel, data);
    });
  }

  protected setupClickableLegend(serieName = 'serie') {

    if (!this.legend) {
      return;
    }

    const instance = this;

    instance.serieName = serieName;

    this.chart.legends = [];  // we need to orphan the legends

    // Get a unique list of Owner values to use when filtering
    let filterValues = dimple.getUniqueValues(this.seriesData, serieName);

    // Get all the rectangles from our now orphaned legend
    // filter bij klikken op de legenda
    this.legend.shapes.selectAll('rect')
    .on('click', function(e) {

      // This indicates whether the item is already visible or not
      let hide = false;
      const newFilters = [];
      // If the filters contain the clicked shape hide it
      filterValues.forEach((f) => {
        if (f === e.aggField.slice(-1)[0]) {
          hide = true;
        } else {
          newFilters.push(f);
        }
      });
      // Hide the shape or show it
      if (hide) {
        d3.select(this).style('opacity', 0.2);
      } else {
        newFilters.push(e.aggField.slice(-1)[0]);
        d3.select(this).style('opacity', 0.8);
      }
      // Update the filters
      filterValues = newFilters;
      // Filter the data
      instance.chart.data = dimple.filterData(instance.seriesData, serieName, filterValues);

      // NOTE: there is a known bug in dimple which results in an error here.
      // https://github.com/PMSI-AlignAlytics/dimple/issues/265
      // instance.drawChart();
      instance.fixAxisStyling();
      instance.resizeChart();
    });
  }

  protected loadProperties(props: IKeyValueProps[]) {
    // update the properties from the graph properties
    for (const property of props) {
      const k = property.key;
      const v = property.value;
      if (this.props.hasOwnProperty(k)) {
        this.props[k] = v;
      }
    }
  }

  protected drawScene(graphModel: IGraphModel, graphData: IGraphData) {
    this.setupSvg();
    this.setupChart();
    this.setupLegend();
    window.setTimeout(() => {
      this.resizeChart();
    }, 0);
    // you need to implement the rest in the derived class
  }

  protected setupSvg() {
    const x = 100.0 * parseFloat(this.props['aspect-ratio']);
    const y = 100.0;
    this.svg = dimple.newSvg(this.element, x, y);
    this.responsivefy();
    return this.svg;
  }


  protected resizeChart() {

    const container = d3.select(this.svg.node().parentNode);
    const targetWidth = parseInt(container.style('width'), 10);
    
    if (!isNaN(targetWidth)) {

      if (targetWidth < this.minWidthSvgDrawing) {
        const w = this.minWidthSvgDrawing;
        const h = Math.round(w / this.aspect);
        this.svg.attr('viewBox', `0 0 ${w} ${h}`)
        .attr('perserveAspectRatio', 'xMidYMid');

        this.svg.attr('width', w);
        this.svg.attr('height', h);

        if (this.chart) {
          this.chart.draw(0, false);
          this.fixAxisStyling();
        }
        this.svg.attr('width', targetWidth);
        this.svg.attr('height', Math.round(targetWidth / this.aspect));

      } else {
        this.svg.attr('viewBox', null)
        .attr('perserveAspectRatio', null);

        this.svg.attr('width', targetWidth);
        this.svg.attr('height', Math.round(targetWidth / this.aspect));

        if (this.chart) {
          this.chart.draw(0, false);
          this.fixAxisStyling();
        }
      }
    }
  }

  public responsivefy() {

    if (!this.svg.node()) {
      return;
    }

    // get container + svg aspect ratio
    const container = d3.select(this.svg.node().parentNode);
    const width = parseInt(this.svg.style('width'), 10);
    const height = parseInt(this.svg.style('height'), 10);
    this.aspect = width / height;

    // call resize so that this.svg resizes on inital page load
    this.resizeChart();

    // to register multiple listeners for same event type,
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you call invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on

    let resizeId: any;
    window.addEventListener('resize', function() {
        clearTimeout(resizeId);
        resizeId = setTimeout(doneResizing, 500);
    });
    
    let doneResizing = () => {
        this.resizeChart();
        this.redrawLegend();
        // this.setupClickableLegend(this.serieName);
    }
  }

  protected cleanAxis(axis, oneInEvery) {
    // This should have been called after draw, otherwise do nothing
    if (axis.shapes) {
      // Leave the first label
      let del = 0;
      // If there is an interval set
      if (oneInEvery > 1) {
        // Operate on all the axis text
        axis.shapes.selectAll('text').each(function (d) {
          d3.select(this).attr('opacity', 1);
          // Remove all but the nth label
          if (del % oneInEvery !== 0) {
            d3.select(this).attr('opacity', 0);
          }
          del += 1;
        });
      }
    }
  }

  protected setupChart() {
    this.chart = new dimple.chart(this.svg, this.seriesData.slice());

    this.chart.setMargins(
      this.props['margin-left'],
      this.props['margin-top'],
      this.props['margin-right'],
      this.props['margin-bottom'],
    );
    return this.chart;
  }

  protected redrawLegend() {

    if (!this.legend) {
      return;
    }

    let width = parseInt(this.svg.style('width'), 10);
    let height = parseInt(this.svg.style('height'), 10);

    if (width < this.minWidthSvgDrawing) {
      width = this.minWidthSvgDrawing;
      height = Math.round(width / this.aspect);
    }

    // de legenda
    let legendX = parseFloat(this.props['legend-x']);
    let legendY = parseFloat(this.props['legend-y']);
    const legendAlign = this.props['legend-align'];

    if (String(this.props['legend-x']).indexOf('%') !== -1) {
      legendX = (legendX / 100) * width;
    }
    if (String(this.props['legend-y']).indexOf('%') !== -1) {
      legendY = (legendY / 100) * height;
    }

    this.legend.x = Math.max(legendX, 1);
    this.legend.y = Math.max(legendY, 1);
    this.legend.width = width - Math.abs(legendX);
    this.legend.height = height - Math.abs(legendY);

    this.legend._draw();
  }

  protected setupLegend() {

    const container = d3.select(this.svg.node().parentNode);
    const width = parseInt(this.svg.style('width'), 10);
    const height = parseInt(this.svg.style('height'), 10);

    const legendAlign = this.props['legend-align'];
    this.legend = this.chart.addLegend(1, 1, width, height, legendAlign);
    this.legend.fontSize = '10px';

    this.redrawLegend();
    return this.legend;
  }

  protected getGraphModel(graphId: number, element: Element): Promise<IGraphModel> {
    return Utils.Instance.getGraphModel(graphId);
  }

  protected getGraphData(dataUrl: string, element: Element): Promise<IGraphData> {
    return Utils.Instance.getJsonPromise(dataUrl);
  }

  protected getFieldNames(data: IGraphData): string[] {
    let fieldNames = [];
    if (data.hasOwnProperty('fieldnames')) {
      fieldNames = data.fieldnames;
    }
    this.fieldNames = fieldNames;
    return this.fieldNames;
  }

  protected getSeriesData(data: IGraphData): IDataRecord[] {
    let records = [];
    if (data.hasOwnProperty('records')) {
      // records = data.records.slice();
      records = data.records;
    }
    this.seriesData = records;
    return this.seriesData;
  }

  protected getAxisFormat(axis: Axis) {
    const format = this.props[`${axis}-axis-format`];
    return format;
  }

  protected setupAxis(axis: Axis, title: string) {

    let axisObj = this.xAxis;

    if (axis === Axis.y) {
      axisObj = this.yAxis;
    }

    axisObj.title = title;
    axisObj.fontSize = '10px';

    if (this.props[`${axis}-axis-overrideMin`]) {
      axisObj.overrideMin = this.props[`${axis}-axis-overrideMin`];
    }
    if (this.props[`${axis}-axis-overrideMax`]) {
      axisObj.overrideMax = this.props[`${axis}-axis-overrideMax`];
    }

    if (axis === Axis.x && this.props['date-parse-format']) {
      axisObj.dateParseFormat = this.props['date-parse-format'];
    }

    const formatting = this.getAxisFormat(axis);

    if (formatting !== null) {
      let formatter;
      if (axisObj.timeField) {  // is this a time axis?
        formatter = d3.timeFormat(formatting);
      } else {
        formatter = d3.format(formatting);
      }
      axisObj.tickFormat = formatter;
    }

    if (this.props[`${axis}-axis-time-interval`]) {
      // workaround: timeInterval werkt niet zo lekker. slaat 1e tick over
      // handmatig cleanen van de tussenliggende ticks werkt beter..
      // axisObj.timeInterval = this.props[`${axis}-axis-time-interval`];
      for (const s of this.chart.series) {
        s.afterDraw = () => {
          this.cleanAxis(axisObj, this.props[`${axis}-axis-time-interval`]);
        };
      }
    }

    if (this.props[`${axis}-axis-ticks`]) {
      axisObj.ticks = this.props[`${axis}-axis-ticks`];
    }

    if (this.props[`${axis}-axis-time-period`]) {
      axisObj.timePeriod = parseInt(this.props[`${axis}-axis-time-period`], 10);
    }

  }

  protected fixAxisStyling() {

    const instance = this;

    if (this.xAxis) {

      if (this.props['x-axis-title-margin']) {
        this.xAxis.titleShape.attr(
          'transform', `translate(0, ${this.props['x-axis-title-margin']})`);
      }

      this.xAxis.shapes.selectAll('text').each(function(d, i) {
        if (d instanceof String) {
          if (d.indexOf('__') === 0) {
            this.innerHTML = '';
          }
        }
      });

      // x-as schuine text
      if (this.props['x-axis-rotate-labels']) {
        this.xAxis.shapes.selectAll('text')
        .style('text-anchor', 'start')
        .attr('transform', function() {
          const x = this.getAttribute('x') || 0;
          const y = this.getAttribute('y') || 0;
          const angle = instance.props['x-axis-rotate-labels'];
          return `rotate(${angle}, ${x}, ${y})`;
        });
      }
    }

    if (this.yAxis) {
      if (this.props['y-axis-title-margin']) {
        this.yAxis.titleShape.attr(
          'transform', `translate(-${this.props['y-axis-title-margin']}, 0)`);
      }
    }
  }
}
