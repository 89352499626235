export class SvgBasis {

  constructor(elementId,dataFile) {

    this.Id = elementId;
    this.dataFile = dataFile;

    this.width   = document.getElementById(elementId).offsetWidth;
    this.height = document.getElementById(elementId).offsetHeight;

    this.topMargin=20;
    this.rightMargin=20;
    this.bottomMargin=20;
    this.leftMargin=20;

    this.innerWidth   = this.width  - this.leftMargin - this.rightMargin;
    this.innerHeight   = this.height - this.topMargin  - this.bottomMargin;

    this.squareSize=10;
    this.cols=10;
    this.rows=10;
    this.gap=1;
    this.minValue= 0;
    this.maxValue= 100;
    this.startValue= 0;

    this.valuePrefix    = "";
    this.valueSuffix    = "";

    this.valueColor= "#178BCA";
    this.nonValueColor= "#BBBBBB";
    this.animateTime= 750;
    this.fontFamily= "sans-serif";
    this.fontSize="24px";
    this.pointerEvents= "none";
    this.fontWeight= "bold";

  }

  draw(){

    this.placeG();

  }

  placeG(){
    this.svg        = d3.select("#"+this.Id).append('svg').attr('width',this.width).attr('height',this.height);
    this.innerWidth     = this.width - this.leftMargin - this.rightMargin;
    this.InnerHeight     = this.height - this.topMargin - this.bottomMargin;
    this.g           = this.svg.append("g").attr("transform", "translate(" + this.leftMargin + "," + this.topMargin + ")");
  }

}
