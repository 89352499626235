import {Utils} from './utils';

let jQuery = require('jquery');

export class StadsdeelSelector {

  _selector;
  _wijken;
  _stadsdelen;
  _selected;

  _selectedWijkId;

  public constructor(domSelector: string, wijken: any) {
    this._selector = domSelector;
    this._wijken = wijken;
    this._stadsdelen = {};

    // fetch stadsdelen from wijken
    for( let i = 0; i < this._wijken.length; i++) {
      this._stadsdelen[this._wijken[i].stadsdeel.id] = this._wijken[i].stadsdeel.naam;
    }
  }

  public render() {

    // create the container for stadsdelen

    let section = document.getElementById(this._selector) as HTMLElement;
    let div = document.createElement('div') as HTMLDivElement;
    let ul = document.createElement('ul') as HTMLUListElement;

    div.setAttribute('class', 'container p-0');
    ul.setAttribute('class', 'nav nav-pills d-flex justify-content-between');

    div.appendChild(ul);
    section.appendChild(div);

    for (let key in this._stadsdelen) {
      let li = document.createElement('li') as HTMLLIElement;

      li.setAttribute('class', 'nav-item');
      ul.appendChild(li);

      let a = document.createElement('a') as HTMLAnchorElement;

      a.setAttribute('data-stadsdeelid', key);
      a.setAttribute('class', 'nav-link');
      a.setAttribute('href', '#');

      let h3 = document.createElement('h3') as HTMLHeadingElement;

      a.appendChild(h3);

      h3.innerText = this._stadsdelen[key];
      // a.innerText = this._stadsdelen[key];

      a.addEventListener('click', (e: Event) => {
        e.preventDefault();

        this._selectItem(a);

        let selectedStadsdeelId = a.getAttribute('data-stadsdeelid');

        Utils.Instance.setMemoryData('selectedStadsdeelId', selectedStadsdeelId);

        // Collapse accordion
        jQuery('#collapse-wijkkaart').collapse('show');
      });

      li.appendChild(a);
    }

    Utils.Instance.inMemoryData$.subscribe((data) => {

      let newSelectedWijkId = data['selectedWijkId'];
      let newSelectedStadsdeelId = data['selectedStadsdeelId'];

      // check if new wijk was selected. And if so, update the selected
      // stadsdeel dropdown
      if (newSelectedWijkId && newSelectedWijkId != this._selectedWijkId) {
        this._selectedWijkId = newSelectedWijkId;
        // find stadsdeel for selected Wijk
        for (let wijk of this._wijken) {
          if ((wijk as any).id == newSelectedWijkId) {
            let stadsdeelId = (wijk as any).stadsdeel.id;
            Utils.Instance.setMemoryData('selectedStadsdeelId', stadsdeelId);
          }
        }
      }

      let selected = document.querySelectorAll(`[data-stadsdeelid='${data['selectedStadsdeelId']}']`);
      if (selected) {
        let current = selected[0] as HTMLAnchorElement;
        this._selectItem(current);
        this._selected = selected[0];
      }
    });

  }

  private _selectItem(current: HTMLAnchorElement) {
    if (this._selected) {
      this._selected.classList.remove('active');
    }
    if (current) {
      current.classList.add('active');
      this._selected = current;
    }
  }
}
