import { SvgBasis } from "./SvgBasis.class";

class Waffle extends SvgBasis {

	constructor(elementId,val) {

		super(elementId,'');

		this.val 	= val;
		this.width 	= this.cols * ( this.squareSize + this.gap ) - this.gap + this.leftMargin + this.rightMargin;
		this.height = this.rows *  (this.squareSize + this.gap ) - this.gap + this.bottomMargin + this.topMargin;

	}

	draw(){

		super.draw();

		let val = this.val,
			 fillNum = this.valueColor,
			 fillNon = this.nonValueColor,
			 gap = this.gap,
			 squareSize = this.squareSize,
			 cols = this.cols,
			 animateTime = this.animateTime,
			 count=this.cols * this.rows,
			 blinkId = count;

		this.g.selectAll('squareGroup')
			.data(d3.range( count))
			.enter().append("g")
					.attr('class','squareGroup')
					.attr("id",function(d) {return "squareGroupId"+d;})
					.append("rect")
						.attr("width", this.squareSize)
						.attr("height", this.squareSize)
						.attr("fill",function(d){return (d >= count-val) ? fillNum : fillNon;})
						.attr("x",function(d){return ( d % cols )*(squareSize + gap);})
						.attr("y",function(d){return Math.floor( d /cols )*(squareSize + gap);})
						.attr("id",function(d) {return "square"+d;})
						.style("opacity","0.0001")
						.transition()
							.delay(function(){return animateTime*Math.random();})
							.duration(function(){return animateTime*Math.random();})
							.style("opacity","1");

		this.g.transition()
			//.delay(2*animateTime)
			.ease(d3.easeLinear)
			.on("start", function repeat() {

				var sqId =  "#square"+(count-val+Math.floor(Math.random() * val));

				d3.select(sqId)

					.transition()
						//.delay(function(){return Math.floor(Math.random() * 1000);})
						.duration(animateTime)
						.styleTween("opacity",function() {
							return d3.interpolateNumber(1, 0.75);
						})

					.transition()
						//.delay(function(){return Math.floor(Math.random() * 300);})
						.duration(animateTime)
						.styleTween("opacity",function() {
							return d3.interpolateNumber(0.75, 1);
						})

					.transition()
						//.delay(animateTime)
						.on("start", repeat);

			});

	}

}

export default Waffle;
