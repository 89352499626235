import Vue from 'vue';
import store from './store';

import { MediaQueries } from 'vue-media-queries';
import KompasApp from './components/KompasApp.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faFacebook, faWhatsapp, faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

/* add icons to the library */
library.add(faXTwitter, faFacebook, faEnvelope, faWhatsapp, faLinkedin);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default function bootstrapVue() {
  // NB: als je vue laadt werkt dimple niet meer, dus voor nu specifiek op kompas pagina
  const containerSelector = '.kompas main';
  const VueScrollTo = require('vue-scrollto');
  const SocialSharing = require('vue-social-sharing');
  const mediaQueries = new MediaQueries();

  if (document.querySelectorAll(containerSelector).length > 0) {
    Vue.use(mediaQueries);

    // global scroll-to settings
    Vue.use(VueScrollTo, {
      offset: -280,
      duration: 500,
      easing: 'ease',
    });

    Vue.use(SocialSharing);

    // https://stackoverflow.com/a/42389266
    Vue.directive('click-outside', {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    });

    const app = new Vue({
      store,
      components: {
        KompasApp,
      },
      mediaQueries: mediaQueries,
      beforeCreate() {
        this.$store.dispatch('initialiseStore');
      },
    });

    app.$mount('.kompas main');
  }
}
