import d3 = require('d3');
import L = require('leaflet');
L.Proj = require('proj4leaflet');

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { IGraphModel } from './models/graphmodel';

import proj4leaflet = require('proj4leaflet');

export class Utils {
  public static centerOfWorld = new L.LatLng(53.219231, 6.57537); // pronkjewail

  // oude gemeente Groningen
  public static stadBounds = L.latLngBounds(
    L.latLng(53.270095463639024, 6.674895670499025),
    L.latLng(53.17565783465344, 6.452106467983061)
  );

  // Gemeente inclusief Ten Boer en Haren
  public static gemeenteBounds = L.latLngBounds(
    L.latLng(53.31296374313122, 6.7725268661368005),
    L.latLng(53.106198741185324, 6.46274562898164)
  );

  public static getRDProjection() {
    // coördinatensysteem van de Rijksdriehoeksmeting
    // deze zijn nodig voor bijvoorbeeld de PDOK (kadaster) kaarten

    return new L.Proj.CRS(
      'EPSG:28992',
      `+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 \
       +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m \
       +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,
       -1.8703473836068,4.0812 +no_defs`,
      {
        resolutions: [
          3440.64,
          1720.32,
          860.16,
          430.08,
          215.04,
          107.52,
          53.76,
          26.88,
          13.44,
          6.72,
          3.36,
          1.68,
          0.84,
          0.42,
          0.21,
        ],
        bounds: L.bounds([-285401.92, 22598.08], [595401.9199999999, 903401.9199999999]),
        origin: [-285401.92, 22598.08],
      }
    );
  }

  public static QUARTERS = {
    '991': 1,
    '992': 2,
    '993': 3,
    '994': 4,
  };

  public static redGreenColors = [
    // "#EEEEEE",
    '#d73027',
    '#fc8d59',
    '#fee08b',
    '#ffffbf',
    '#d9ef8b',
    '#91cf60',
    '#1a9850',
  ];
  public static bluesColors = [
    // "#EEEEEE",
    // marcel's colors
    // "#e6eeff",
    // "#b3ccff",
    // "#80aaff",
    // "#3377ff",
    // "#0055ff",
    // "#0044cc",
    // "#003399",
    // wild sea's colors
    '#EFF3FF',
    '#C5DBEF',
    '#9EC9E0',
    '#6BAED6',
    '#4292C5',
    '#3471B5',
    '#244594',
  ];

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  // http://www.w3.org/WAI/ER/WD-AERT/#color-contrast
  public static brightness(rgb) {
    return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114;
  }

  public static responsivefy(svg, maxWidth = 650) {
    if (!svg.node()) {
      return;
    }

    // get container + svg aspect ratio
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.style('width'), 10);
    const height = parseInt(svg.style('height'), 10);
    const aspect = width / height;

    // add viewBox and preserveAspectRatio properties,
    // and call resize so that svg resizes on inital page load
    svg
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMidYMid')
      .call(resize);

    // to register multiple listeners for same event type,
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you call invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on
    d3.select(window).on('resize.' + container.attr('id'), () => {
      window.setTimeout(() => {
        // DIKKE HACK: gebruikt in wheel de in eerste instantie op
        // hidden staat zodat we geen flash van een enorm groot wheel
        // krijgen.. zou mooier kunnen maar voor nu staat het hier
        svg.style('visibility', null);
        // einde hack
        resize();
      }, 0);
    });

    // get width of container and resize svg to fit it
    function resize() {
      const targetWidth = Math.min(maxWidth, parseInt(container.style('width'), 10));
      if (!isNaN(targetWidth)) {
        svg.attr('width', targetWidth);
        svg.attr('height', Math.round(targetWidth / aspect));

        // TODO: kan denk ik weg:
        svg.attr('viewBox', '0 0 ' + width + ' ' + height).attr('perserveAspectRatio', 'xMidYMid');

        // sort of a hack to scale the text inside the SVG
        // let scale = width / svg.node().getBoundingClientRect().width;
        // svg.attr('font-size', `${scale}rem`);
      }
    }
  }

  public static guid() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    // return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    //  s4() + '-' + s4() + s4() + s4();
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
  }

  public static getKompasLegend() {
    // <= 67,2: zeer ongunstig (donkerste rood);
    // > 67,2 en <= 86,6; ongunstig (rood);
    // > 86,6 en <= 95; beperkt ongunstig (licht rood);
    // > 95 en < 105; neutraal (geel);
    // => 105 en < 113,4; beperkt gunstig (licht groen);
    // => 113,4 en < 132,8; gunstig (groen);
    // => 132,8; zeer gunstig (donker groen).
    return {
      stoplicht: [
        { min: 0, max: 67.2, level: 1, label: 'zeer ongunstig' },
        { min: 67.2, max: 86.6, level: 2, label: 'ongunstig' },
        { min: 86.6, max: 95, level: 3, label: 'beperkt ongunstig' },
        { min: 95.0, max: 105, level: 4, label: 'neutraal' },
        { min: 105, max: 113.4, level: 5, label: 'beperkt gunstig' },
        { min: 113.4, max: 132.8, level: 6, label: 'gunstig' },
        { min: 132.8, max: 200, level: 7, label: 'zeer gunstig' },
      ],
      blauw: [
        { min: 0, max: 67.2, level: 1, label: 'beduidend lager' },
        { min: 67.2, max: 86.6, level: 2, label: 'lager' },
        { min: 86.6, max: 95, level: 3, label: 'beperkt lager' },
        { min: 95.0, max: 105, level: 4, label: 'neutraal' },
        { min: 105, max: 113.4, level: 5, label: 'beperkt hoger' },
        { min: 113.4, max: 132.8, level: 6, label: 'hoger' },
        { min: 132.8, max: 200, level: 7, label: 'beduidend hoger' },
      ],
    };
  }

  private static instance: Utils; // singleton pattern

  public inMemoryData$: Observable<{}>;

  public mediaURL: string = null;
  public rootIndicator: number = null;

  protected inMemoryData: BehaviorSubject<{}> = new BehaviorSubject({});

  protected meetwaardenCache = {};
  protected indicatorCache = {};

  private indicatorenPromise;
  private wijkenPromise;
  private stadsdelenPromise;
  private kompassenDataPromise;
  private wijkIndicatorMeetwaardePromise = {};
  private wijkIndicatorTekstDataPromise: Promise<{}> = null;
  private cachedPromises = {};

  private constructor() {
    this.inMemoryData$ = this.inMemoryData.asObservable();
    const body = document.getElementsByTagName('BODY')[0];
    this.mediaURL = body.getAttribute('data-media-url');
    this.rootIndicator = parseInt(body.getAttribute('data-root-indicator'), 10);
  }

  // The maximum is exclusive and the minimum is inclusive
  public getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public range(count: number, start = 0) {
    return Array.apply(0, Array(count)).map((element, index) => {
      return index + start;
    });
  }

  public setMemoryData(key: string, value: any, emit = true) {
    if (this.inMemoryData[key] !== value) {
      this.inMemoryData[key] = value;
      if (emit) {
        this.inMemoryData.next(this.inMemoryData);
      }
    }
  }

  public setRandomData() {
    this.setMemoryData('selectedIndicator', String(this.getRandomInt(1, 53)));
  }

  public getJsonPromise(url: string): Promise<{}> {
    if (url in this.cachedPromises) {
      return this.cachedPromises[url];
    }

    const promise = new Promise((resolve, reject) => {
      fetch(url)
        .then(data => {
          resolve(data.json());
        })
        .catch(error => reject(error));
    });

    this.cachedPromises[url] = promise;
    return promise;
  }

  public getWijken() {
    if (!this.wijkenPromise) {
      this.wijkenPromise = this.getJsonPromise('/api/rest/wijken/');
    }
    return this.wijkenPromise;
  }

  public getStadsdelen() {
    if (!this.stadsdelenPromise) {
      this.stadsdelenPromise = this.getJsonPromise('/api/rest/stadsdelen/');
    }
    return this.stadsdelenPromise;
  }

  public getGraphModel(id: number): Promise<IGraphModel> {
    return this.getJsonPromise(`/api/v2/pages/${id}`);
  }

  public getCluster(url: string): Promise<DocumentFragment> {
    return new Promise((resolve, reject) => {
      d3.html(url)
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }

  public getKompassenData() {
    if (!this.kompassenDataPromise) {
      this.kompassenDataPromise = this.getJsonPromise('/api/v2/wijkkompassen/?limit=100');
    }
    return this.kompassenDataPromise;
  }

  public getWijkIndicatorTekstData(): Promise<{}> {
    /* FOR NOW: just get all records. We might need to query this only per wijk */
    if (!this.wijkIndicatorTekstDataPromise) {
      this.wijkIndicatorTekstDataPromise = this.getJsonPromise('/api/v2/wijkindicatorteksten/?limit=1000');
    }
    return this.wijkIndicatorTekstDataPromise;
  }

  public getIndicatoren() {
    if (!this.indicatorenPromise) {
      this.indicatorenPromise = this.getJsonPromise('/api/rest/indicatoren/');
    }
    return this.indicatorenPromise;
  }

  public getIndicator(indicatorId: number) {
    if (indicatorId in this.indicatorCache) {
      return this.indicatorCache[indicatorId];
    }
    const promise = this.getJsonPromise(`/api/rest/indicatoren/${indicatorId}/`);
    this.indicatorCache[indicatorId] = promise;
    return this.indicatorCache[indicatorId];
  }

  public getMeetwaarden(params) {
    /*
     * retrieve the meetwaarden from the rest api
     * note the the result is cached based on the params
     * @returns a cached Promise with the meetwaarden array
     */

    const qs = Object.keys(params)
      .map(k => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
      })
      .join('&');

    if (qs in this.meetwaardenCache) {
      return this.meetwaardenCache[qs];
    }

    const promise = this.getJsonPromise(`/api/rest/meetwaarden/?${qs}`);
    this.meetwaardenCache[qs] = promise;
    return this.meetwaardenCache[qs];
  }

  public getWijkenMeetwaarden(variant = 1, jaar = 2020) {
    if (variant in this.wijkIndicatorMeetwaardePromise) {
      return this.wijkIndicatorMeetwaardePromise[variant];
    }

    let cacheKey = `wijkIndicatorMeetwaardePromise-${variant}`;

    this.wijkIndicatorMeetwaardePromise[variant] = new Promise((resolve, reject) => {
      let cached = sessionStorage.getItem(cacheKey);
      if (cached) {
        let deserialized = JSON.parse(cached);
        resolve(deserialized);
      }

      const wijkenMeetwaardeLookup = {};

      let wijkenPromise = this.getWijken();

      let meetwaardenPromise = this.getMeetwaarden({
        variant,
        indicator: Utils.Instance.rootIndicator,
      });

      let meetwaardenComparisonPromise = [];
      if (jaar) {
        meetwaardenComparisonPromise = this.getMeetwaarden({
          variant,
          indicator: Utils.Instance.rootIndicator,
          jaar: jaar,
        });
      }

      let indicatorenPromise = this.getIndicatoren();

      Promise.all([wijkenPromise, meetwaardenPromise, meetwaardenComparisonPromise, indicatorenPromise]).then(
        allData => {
          let wijkenArray = allData[0];
          let meetwaarden = allData[1];
          let meetwaardenComparison = allData[2];
          let indicatorArray = allData[3];

          // HUIDIGE MEETWAARDEN, MOMENTEEL 2022
          const wijkenMetingenMeetwaarde = {};
          for (const meting of meetwaarden) {
            if (!wijkenMetingenMeetwaarde.hasOwnProperty(meting.wijk)) {
              wijkenMetingenMeetwaarde[meting.wijk] = {};
            }
            wijkenMetingenMeetwaarde[meting.wijk][meting.indicator] = [
              meting.waarde,
              meting.categorie,
              meting.schermwaarde,
            ];
          }

          // console.log(wijkenMetingenMeetwaarde);

          // VERGELIJKINGSMEETWAARDEN, MOMENTEEL 2020
          const wijkenMetingenComparisonMeetwaarde = {};
          for (const meting of meetwaardenComparison) {
            if (!wijkenMetingenComparisonMeetwaarde.hasOwnProperty(meting.wijk)) {
              wijkenMetingenComparisonMeetwaarde[meting.wijk] = {};
            }
            wijkenMetingenComparisonMeetwaarde[meting.wijk][meting.indicator] = [meting.categorie];
          }

          // console.log(wijkenMetingenComparisonMeetwaarde);

          /* middels d3.stratify maken we een hierarchy (boom structuur) */
          const indicatorenTree = d3
            .stratify()
            .id(d => (d as any).id)
            .parentId(d => (d as any).parent_id)(indicatorArray);

          for (const wijk of wijkenArray) {
            wijkenMeetwaardeLookup[wijk.id] = {};

            for (const node of indicatorenTree.descendants()) {
              const meetwaarde = this.getWijkIndicatorMeetwaarde(wijkenMetingenMeetwaarde, wijk.id, node);
              const meetwaardeComparison = this.getWijkIndicatorMeetwaarde(
                wijkenMetingenComparisonMeetwaarde,
                wijk.id,
                node
              );

              const meetwaardeAvg = this.getWijkIndicatorMeetwaarde(wijkenMetingenMeetwaarde, 0, node)[1];
              const meetwaardeAvgComparison = this.getWijkIndicatorMeetwaarde(
                wijkenMetingenComparisonMeetwaarde,
                0,
                node
              );

              // if (wijk.id == 1 && node.id == 99213101) {
              //   console.log('a ' + meetwaarde);
              //   console.log('b ' + meetwaardeComparison);
              //   console.log('c ' + meetwaardeAvg);
              //   console.log('d ' + meetwaardeAvgComparison);
              // }

              wijkenMeetwaardeLookup[wijk.id][node.id] = meetwaarde
                .concat(meetwaardeComparison)
                .concat(meetwaardeAvg)
                .concat(meetwaardeAvgComparison);

              // if (wijk.id == 1 && node.id == 99213101) {
              //   console.log(wijkenMeetwaardeLookup[wijk.id][node.id]);
              // }
            }
          }

          let serialized = JSON.stringify(wijkenMeetwaardeLookup);
          sessionStorage.setItem(cacheKey, serialized);
          resolve(wijkenMeetwaardeLookup);
        }
      );
    });

    return this.wijkIndicatorMeetwaardePromise[variant];
  }

  public meetwaardeToZscore = (value: number): number => {
    // 01/9/2018 uit email van Marcel Seubring
    //
    // Zeer ongunstig / zeer laag: <= -32,8;
    // Ongunstig / laag: > -32,8 <= -13,4;
    // Beperkt ongunstig / beperkt laag: > -13,4 <= -5;
    // Neutraal: > -5 < 5;
    // Beperkt gunstig / beperkt hoog: >= 5 < 13,4;
    // Gunstig / hoog: >= 13,4 < 32,8;
    // Zeer gunstig / zeer hoog: >= 32,8.
    //
    // let zScore = null;
    // if (value !== 0) {
    //   if (value <= -32.8) {
    //     zScore = -3;
    //   } else if (-32.8 < value && value <= -13.4) {
    //     zScore = -2;
    //   } else if (-13.4 < value && value <= -5.0) {
    //     zScore = -1;
    //   } else if (-5.0 < value && value < 5.0) {
    //     zScore = 0;
    //   } else if (5.0 <= value && value < 13.4) {
    //     zScore = 1;
    //   } else if (13.4 <= value && value < 32.8) {
    //     zScore = 2;
    //   } else if (32.8 <= value) {
    //     zScore = 3;
    //   }
    // }

    // Huub: 15/09/2018 toch maar weer terug van 0 - 200
    // <= 67,2: zeer ongunstig (donkerste rood);
    // > 67,2 en <= 86,6; ongunstig (rood);
    // > 86,6 en <= 95; beperkt ongunstig (licht rood);
    // > 95 en < 105; neutraal (geel);
    // => 105 en < 113,4; beperkt gunstig (licht groen);
    // => 113,4 en < 132,8; gunstig (groen);
    // => 132,8; zeer gunstig (donker groen).

    let zScore = null;
    if (value !== 0) {
      if (value <= 67.2) {
        zScore = -3;
      } else if (67.2 < value && value <= 86.6) {
        zScore = -2;
      } else if (86.6 < value && value <= 95.0) {
        zScore = -1;
      } else if (95.0 < value && value < 105.0) {
        zScore = 0;
      } else if (105.0 <= value && value < 113.4) {
        zScore = 1;
      } else if (113.4 <= value && value < 132.8) {
        zScore = 2;
      } else if (132.8 <= value) {
        zScore = 3;
      }
    }
    return zScore;
  };

  protected getWijkIndicatorMeetwaarde = (lookupTable, wijkId, node) => {
    let meetwaarde = [0];
    if (node.children) {
      let numRelevantChildren = 0;
      for (const childNode of node.children) {
        const waarde = this.getWijkIndicatorMeetwaarde(lookupTable, wijkId, childNode);
        if (waarde[0] !== 0 && node.data.kleurenschema === childNode.data.kleurenschema) {
          meetwaarde[0] += waarde[0];
          numRelevantChildren += 1;
        }
      }
      if (numRelevantChildren) {
        meetwaarde[0] /= numRelevantChildren;
      }
    } else {
      if (lookupTable[wijkId] && lookupTable[wijkId].hasOwnProperty(node.id)) {
        meetwaarde = lookupTable[wijkId][node.id];
      }
    }
    // if (wijkId == 1 && node.id == 99213101) {
    //   console.log(`meetwaarde=${meetwaarde}`);
    // }
    return meetwaarde;
  };
}
