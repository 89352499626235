import d3 = require('d3');
import scaleChromatic = require('d3-scale-chromatic');
import { Utils } from './utils';

export class Gauge {

  protected config = {
    size: 710,
    clipWidth: 200,
    clipHeight: 110,
    ringInset: 20,
    ringWidth: 20,

    pointerWidth: 10,
    pointerTailLength: 5,
    pointerHeadLengthPercent: 0.9,

    minValue: 0,
    maxValue: 10,

    minAngle: -90,
    maxAngle: 90,

    transitionMs: 750,

    majorTicks: 5,
    labelFormat: d3.format('d'),
    labelInset: 10,

    arcColorFn: [...scaleChromatic.schemeRdYlGn[5]]
  };

  protected container: Element = null;
  protected range = null;
  protected r = null;
  protected pointerHeadLength = null;
  protected value = 0;

  protected svg = null;
  protected arc = null;
  protected scale = null;
  protected ticks = null;
  protected tickData = null;
  protected pointer = null;

  protected donut = d3.pie();

  // zScore markers (in range van 0 - 200)
  protected markers = [0, 67.2, 86.6, 95, 105, 113.4, 132.8, 200];


  public constructor(container: Element, configuration: any) {
    this.container = container;
    this.configure(configuration);
  }

  public deg2rad(deg) {
    return deg * Math.PI / 180;
  }

  public newAngle(d) {
    let ratio = this.scale(d);
    let newAngle = this.config.minAngle + (ratio * this.range);
    return newAngle;
  }

  public configure(configuration) {
    let prop = null;
    for ( prop in configuration ) {
      this.config[prop] = configuration[prop];
    }

    this.range = this.config.maxAngle - this.config.minAngle;
    this.r = this.config.size / 2;
    this.pointerHeadLength = Math.round(this.r * this.config.pointerHeadLengthPercent);

    // a linear scale that maps domain values to a percent from 0..1
    let linearScale = d3.scaleLinear()
    .range([0, 1])
    .domain([this.config.minValue, this.config.maxValue]);

    this.scale = (val) => {
      let idx = this.markers.length;
      for (let i = this.markers.length - 1; i >= 0; i--) {
        idx = i;
        if (val >= this.markers[i]) {
          break;
        }
      }

      let minVal = this.markers[idx];
      let maxVal = this.markers[this.markers.length - 1];

      if (val < this.markers[this.markers.length - 1]) {
        maxVal = this.markers[idx + 1];
      }
      return d3.scaleLinear()
      .range([idx * (1.0 / this.config.majorTicks), (idx + 1) * (1.0 / this.config.majorTicks)])
      .domain([minVal, maxVal])(val);
    };

    this.ticks = linearScale.ticks(this.config.majorTicks);
    this.tickData = d3.range(this.config.majorTicks).map(() => {
      return 1 / this.config.majorTicks;
    });

    this.arc = d3.arc()
    .innerRadius(this.r - this.config.ringWidth - this.config.ringInset)
    .outerRadius(this.r - this.config.ringInset)
    .startAngle((d: any, i) => {
      let ratio = d * i;
      return this.deg2rad(this.config.minAngle + (ratio * this.range));
    })
    .endAngle((d: any, i) => {
      let ratio = d * (i + 1);
      return this.deg2rad(this.config.minAngle + (ratio * this.range));
    });
  }

  public centerTranslation() {
    return `translate(${this.r},${this.r})`;
  }

  public isRendered() {
    return (this.svg !== null);
  }

  public render(newValue) {
    this.svg = d3.select(this.container)
    .insert('svg:svg', ":first-child") // prepend
    .attr('class', 'gauge')
    .attr('width', this.config.clipWidth)
    .attr('height', this.config.clipHeight)
    .call(Utils.responsivefy, 300);

    let centerTx = this.centerTranslation();

    let arcs = this.svg.append('g')
    .attr('class', 'arc')
    .attr('transform', centerTx);

    arcs.selectAll('path')
    .data(this.tickData)
    .enter().append('path')
    .attr('class', 'section')
    .attr('fill', (d, i) => {
      return this.config.arcColorFn[i];
    })
    .attr('d', this.arc);

    let lg = this.svg.append('g')
    .attr('class', 'label')
    .attr('transform', centerTx);
    lg.selectAll('text')
    .data(this.markers)
    .enter().append('text')
    .attr('transform', (d, i) => {
      let ratio = i / this.config.majorTicks;
      let newAngle = this.config.minAngle + (ratio * this.range);
      return `rotate(${newAngle}) translate(0,${this.config.labelInset - this.r})`;
    })
    .text(this.config.labelFormat);

    let displayVal = this.svg.append("g")
    .attr('class', 'displayLabel')
    .attr('transform', centerTx);
    displayVal.append('text')
    .text(this.config.labelFormat(newValue))
    .attr("text-anchor","middle")
    .attr("y",25);

    let lineData = [ [this.config.pointerWidth / 2, 0],
      [0, -this.pointerHeadLength],
    [-(this.config.pointerWidth / 2), 0],
    [0, this.config.pointerTailLength],
    [this.config.pointerWidth / 2, 0] ];

    let pointerLine = d3.line().curve(d3.curveLinear);

    let pg = this.svg.append('g').data([lineData])
    .attr('class', 'pointer')
    .attr('transform', centerTx);

    this.pointer = pg.append('path')
    .attr('d', pointerLine)
    .attr('transform', `rotate(${this.config.minAngle})`);

    this.update(newValue === null ? 0 : newValue);
  }

  public update(newValue, newConfiguration = null) {

    if ( newConfiguration !== null) {
      this.configure(newConfiguration);
    }

    // update color
    this.svg.selectAll('.section')
    .attr('fill', (d, i) => {
      return this.config.arcColorFn[i];
    });

    // update displayValue text
    this.svg.selectAll('.displayLabel text')
    .text(this.config.labelFormat(newValue));

    // update pointer
    let ratio = this.scale(newValue);
    let newAngle = this.config.minAngle + (ratio * this.range);
    this.pointer.transition()
    .duration(this.config.transitionMs)
    .ease(d3.easeElastic)
    .attr('transform', `rotate(${newAngle})`);

    /* op verzoek van Mayan (12/9/2019) geen wijzer tonen als geen waarde */
    if (newValue == 0) {
      this.pointer.attr('class', 'hidden');
      this.svg.selectAll('.displayLabel text').attr('class', 'hidden');
    } else {
      this.pointer.attr('class', 'visible');
      this.svg.selectAll('.displayLabel text').attr('class', 'visible');
    }
  }
};
