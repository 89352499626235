<template>
  <h1 class="margin-top" v-if="(selectedWijk || selectedStadsdeel)">
    {{ (selectedStadsdeel || {}).naam }}
    <template v-if="selectedWijk">/ {{ (selectedWijk || {}).naam }}</template>
    <template v-if="!selectedStadsdeel">&nbsp;</template>
    <!--span class="h2"-->
    <template v-if="selectedVariant === 1"> / Stand</template>
    <template v-if="selectedVariant === 2"> / Ontwikkeling</template>
    <!--/span-->
  </h1>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'WijkTitle',
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['selectedWijk', 'selectedStadsdeel', 'selectedVariant']),
  },
};
</script>
