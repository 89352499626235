import d3 = require('d3');
import dimple = require('dimple');

import { BaseChart } from './basechart';
import { IDataRecord } from './basechart';
import { Axis } from './basechart';
import { IKeyValueProps } from './basechart';
import { IGraphData } from './models/graphdata';
import { IGraphModel } from './models/graphmodel';
import { Utils } from './utils';

export class LineChart extends BaseChart {

  public constructor(element: Element) {
    super(element);
  }

  public drawScene(graphModel: IGraphModel, data: IGraphData) {

    super.drawScene(graphModel, data);

    this.xAxis = this.chart.addTimeAxis('x', 'category');
    this.yAxis = this.chart.addMeasureAxis('y', 'value');

    const series = this.chart.addSeries('serie', dimple.plot.line);

    this.setupAxis(Axis.x, '');
    this.setupAxis(Axis.y, this.fieldNames[0]);

    this.xAxis.addOrderRule(this.fieldNames, false);

    const serieNames = dimple.getUniqueValues(this.seriesData, 'serie');
    series.addOrderRule(serieNames, false);

    series.lineMarkers = true;
    series.interpolation = d3.curveCatmullRom;

    this.setupTooltip(series);
    // this.drawChart();
    this.chart.draw(0);
    this.fixAxisStyling();
    this.setupClickableLegend();

    return this.chart;
  }

  protected loadProperties(props: IKeyValueProps[]) {
    // override the default x axis formatting (year only)
    // note that this default setting can still be overridden in the
    // loadProperties method.
    this.props['date-parse-format'] = '%Y';
    this.props['x-axis-format'] = '%Y';
    super.loadProperties(props);
  }

  protected getSeriesData(data: IGraphData): IDataRecord[] {
    let records = [];
    const seriesData = [];

    if (data.hasOwnProperty('records')) {
      records = data.records;
    }

    // format the data
    records.forEach((d) => {
      // skip first fieldname (it's the Y-axis name)
      for (let idx = 1; idx < data.fieldnames.length; idx++) {
        const key = data.fieldnames[idx];
        if (d.hasOwnProperty(key)) {
          const rec = {} as IDataRecord;
          rec.category = key;
          rec.serie = d[data.fieldnames[0]];
          rec.value = d[key];
          const empty = d[key] === '' || d[key] == null;
          if (!empty) {
            seriesData.push(rec);
          }
        }
      }
    });

    this.seriesData = seriesData;
    return this.seriesData;
  }

  protected setupTooltip(series) {

    const instance = this;

    series.getTooltipText = (e) => {
      const tooltip: string[] = [];
      tooltip.push(`${e.aggField[0]}`);

      if (e.x instanceof String) {
        if (e.x.indexOf('__') !== 0) {
          const formatter = d3.format(',');
          tooltip.push(formatter(e.x));
        }
      }

      if (e.x instanceof Date) {
        const xFormat = instance.props['x-axis-format'];
        if (xFormat) {
          const formatter = d3.timeFormat(xFormat);
          tooltip.push(formatter(e.x));
        }
      }

      const yFormat = instance.props['y-axis-format'];
      if (e.y instanceof Date) {
        if (yFormat) {
          const formatter = d3.timeFormat(yFormat);
          tooltip.push(formatter(e.y));
        }
      } else {
          // const formatter = d3.format(yFormat);
          const formatter = d3.format(',');
          tooltip.push(formatter(e.y));
      }

      return tooltip;
    };
  }
}
